import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "./Stringify";
import {toODB} from "./Chat/Chat";


function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [res, setRes] = useState({})
    let [hash, setHash] = useState('')
    let [_id, setId] = useState('')

    useEffect(() => {
        let url = window.location.pathname.split('/').filter(it => it).at(-1);
        setHash(url)
        setId(url.split('@').at(0))
    }, [window.location.pathname])

    useEffect(() => {

        hash && global.http.get('/load-full-details', {hash}).then(r => {
            setRes({
                dialog: r[0],
                interviews: r[1],
                offers: r[2],
                messages: r[3],
                systemMessages: r[4]
            })
        })
    }, [hash])

    useEffect(() => {
        setTimeout(() => {
            let messagesEndRef = document.querySelector('#scrollBot')
            console.log('messagesEndRef', messagesEndRef)
            messagesEndRef.scrollIntoView({behavior: 'smooth'});
            messagesEndRef.scrollTo({
                top: 1000000,
            });
        })
    }, [res.messages])

    let {dialog} = res;
    dialog = dialog || {}

    let activeJobMsg = res?.messages?.find(it => (it.msgType == 'system') && (it.msgSubType == 'apply'))
    let activeJob = activeJobMsg?.info?.parentInfo || {};
    console.log("qqqqq activeJobMsg", activeJobMsg);

    function pubDate(d) {
        let date = new Date(d)
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        let pub = (v) => v < 10 ? '0' + v : v
        return ` ${hours}:${minutes}  ${pub(day)}/${pub(date.getMonth() + 1)}`;
    }

    return <div className={'mainCont mt-10'} style={{marginTop: '15px'}}>
        {hash} {_id}
        <hr/>
        {dialog.msgError && <>
            {dialog.msgError}
        </>}
        {!dialog.msgError && <>
            Описание вакансии:
            <div style={{maxHeight: '300px', overflow: 'auto'}}>
                <div dangerouslySetInnerHTML={{__html: activeJob?.desc}}></div>
            </div>
            <hr/>
            Интервью:
            {(res.interviews || []).map((it, ind) => {
                return (<div key={ind}>
                    <div className="label label-info">{it.type}</div>
                    <span data-item-status={it.resStatus || 'waiting'}></span>{it.name} {toODB(it.date)} {it.time}
                </div>)
            })}
            <hr/>
            Oфферы:
            {(res.offers || []).map((it, ind) => {
                return (<div key={ind}>
                    <span data-item-status={it.status || 'waiting'}></span>
                    {toODB(it.offerDate) || '---'}
                </div>)
            })}
            <hr/>
            Контекст:
            <div style={{maxHeight: '500px', overflow: 'auto'}} id={'scrollBot'}>
                {(res.messages || []).map((it, ind) => {
                    let {channel} = it;
                    let msgId = it.msgId;
                    let isMy = it.isMy || (it.channel === 'email');
                    let isSpecial = it.msgType === 'system' || it.msgType === 'action'
                    let systemInfo = it?.info?.parentInfo || {}

                    if (it?.err) {
                        return <div key={ind}>
                            <div className="fa fa-warning"></div>
                            {it?.err}</div>
                    }
                    return (<div key={ind}
                                 data-msg-id={it._id}
                                 data-status={it.status}

                                 className={'msg-item ' + (isMy ? 'my-msg ' : '') + (/wait/gi.test(it.status) ? 'o5' : '')}>
                        {/*<small style={{marginRight: '5px'}}>{it.status}</small>*/}
                        <small>{!isMy && <div className="label label-success">Client</div>}
                            {isMy &&
                                <div
                                    className="label label-warning">MY</div>}{pubDate(it.msgDate || it.cdMsgDate || it.date)}
                        </small>

                        {it.file && <div>
                            <div className={'label label-success'}>FILE: {it.fileName || it.file}</div>
                        </div>}
                        {isSpecial && <>
                            <div>
                                <div className="label label-info">{it.msgType.toUpperCase()}</div>
                                <div className="label label-info">{it.msgSubType}</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div><small className="ellipse ib">{it.msg}</small></div>
                                    <div style={{display: 'block'}}><a href={systemInfo.postUrl}
                                                                       className={'ellipse'}>{systemInfo.postUrl}</a>
                                    </div>
                                </div>
                            </div>
                        </>}
                        {!isSpecial && <>
                            {it.msgTitle && <div className={'row'}>
                                <div className="col-xs-12 text-right">
                                    <strong className="ib text-right">
                                    <span className="pull-right">
                                    {it.msgTitle}
                                    </span>
                                    </strong>
                                </div>
                            </div>}
                            <div></div>

                            {it.msg && <div>{it.msg}</div>}

                            {!it.msg && !it.msgTitle && <div>
                                #{ind + 1} {it.channel} {it.actionType}
                            </div>}
                        </>}
                        {it?.info?.oldDialog?.hash && <div><a target={"_blank"} href={`/dialog-hash/${it?.info?.oldDialog?.hash}`}>Old dialog</a></div>}
                        {it?.info?.newDialog?.hash && <div><a target={"_blank"} href={`/dialog-hash/${it?.info?.newDialog?.hash}`}>New dialog</a></div>}

                    </div>)
                })}


            </div>
            <hr/>


            <div></div>
        </>}
    </div>
}

export default Layout2
