import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../libs/Smart";
import {getter} from "../Fetcher";
import Button from "../../libs/Button";
import Stringify from "../Stringify";

let getTimeHours = () => {
    let arr = ['-']
    for (let i = 7; i <= 22; i++) {

        _.each(['00', 30], (minutes, ind) => {
            arr.push(i + ':' + minutes)
        })
    }

    return arr;
}
let timeHours = getTimeHours();
export const interviewFields = [{
    name: 'Name', key: 'name'
},{
    name: 'Type', key: 'type', type: 'select', items: ['', 'hr', 'tech', 'owner']
}, {
    name: 'Date', key: 'date', type: 'date'
}, {
    name: 'Time', key: 'time', type: 'select', items: timeHours
}, {
    name: 'Ping', key: 'ping.date', type: 'date'
}, {
    name: 'feedbackDate', key: 'feedbackDate', type: 'date'
},  {
    name: 'status', key: 'status', items: ['', 'waiting', 'finished', 'removed'], type: 'select',
}, {
    name: 'resStatus', key: 'resStatus', type: 'select', items: ['', 'offer', 'nextStage',
        'decline', 'waiting', 'timeout', 'nointerview']
}, {
    name: 'feedback', key: 'feedback.msg', type: 'textarea'
}, {
    name: 'sourceChannel', key: 'sourceChannel', type: 'select', items: ['', 'hh', 'tg', 'email', 'custom']
}, {
    name: 'dialog', key: 'dialog'
}, {
    name: 'tuser', key: 'tuser'
}, ]

let interviewHttp = getter('/interview')

export let setPingDate = (date, it) => {
    if (!date) {
        return;
    }
    it.ping = it.ping || {}
    it.ping.date = new Date(date).getTime() + 7 * 24 * 3600 * 1000;
}
function Layout2(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [itemObj, setItemObj] = useState({})
    let {item} = props;
    let {type = 'hr'} = item;
    useEffect(() => {
        setItemObj(item)
    }, [JSON.stringify(item)])


    !itemObj?.ping?.date && itemObj?.date && setPingDate(itemObj.date, itemObj)

    return <div>
        <CreateUpdateButton item={itemObj} http={interviewHttp} onChange={props.onChange}></CreateUpdateButton>
        Tech interview {type}
        <Smart
            items={interviewFields}
            obj={itemObj}
            defSize={4}
            onChange={(v, value, key) => {
                if (key === 'date') {
                    setPingDate(value, v)
                }
                console.log('vvvvvvvvvvv', v)
                setItemObj({...v})
            }}
        ></Smart>
    </div>
}

export function CreateUpdateButton (props) {
    let {item, onChange, http} = props;
    let isNew = !item?._id;

    return <div>
        <Button onClick={(scb) => {
            http[isNew ? 'create' : 'update'](item, (r) => {
                console.log("updateeeeeeeeee", r)
                onChange && onChange(r)
                scb && scb();
            })

        }}>{isNew ? "Создать" : 'Обновить'}</Button>
        </div>
}

export default Layout2
