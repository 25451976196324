import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {getter} from "./Fetcher";
import Smart from "../libs/Smart";
import Button from "../libs/Button";
import Stringify from "./Stringify";
import Input from "../libs/Input";
import MyModal from "../libs/MyModal";


let ht = getter('/t-creds')

function Layout2(props) {
    props = props?.props || props;
    let {field, localItem} = props
    let {channel} = field


    // let [attemps, setAttemps] = useState(0)
    let [validateInfo, setValidateInfo] = useState(null)
    let [cvItems, setCvItems] = useState([])
    let [isCvItems, setIsCvItem] = useState(false)

    let [verifyRes, setVerifyRes] = useState(null)
    let [info, setInfo] = useState({})
    let [isCode, setIsCode] = useState(false)
    let [code, setCode] = useState('')
    let [isDetails, setIsDetails] = useState(false)
    let [isDeatilsValidate, setIsDeatilsValidate] = useState(false)
    let [me, setMe] = useState(null)
    let tuser = localItem?._id;

    useEffect(() => {
        ht.createUpdate({channel, tuser}).then(setInfo)
        getCV()
    }, [])

    function getCV(scb) {
        if (channel !== 'hh') {
            return;
        }
        global.http.get("/c-v", {filter: {TUser: tuser}}).then(r => {
            setCvItems(r.items)
        })
    }

    let saveInfo = (v) => {
        info.info = v;
        setInfo(info)
        ht.update(info).then(setInfo)
    }
    let saveCreds = (v) => {
        info.creds = v;
        setInfo(info)
        ht.update(info).then(setInfo)
    }
    let saveAll = (v) => {
        setInfo(v)
        ht.update(v).then(setInfo)
    }
    let reinitOne = (v, scb, ecb) => {
        setInfo(v)
        setIsCode(false)
        global.http.get('/reinit-one', {item: info}).then(r => {
            scb && scb()
            setMe(r)
            console.log("qqqqq rrrrrrrrrrrrr", r);
            if (r.err && r.code) {
                return
            }
            setInfo(r)
        }).catch(e => {
            setMe(e)
            ecb && ecb()
        })
    }

    console.log('*........ ## ROOT RENDER', field, props);

    let fieldsObj = {
        hh: ['access_token', 'refresh_token'],
        tg: ['session'],
        email: ['access_token', 'refresh_token'],
    }
    let fieldsObj1 = {
        hh: [],
        tg: ['phone'],
        email: [],
    }
    let fields = fieldsObj[channel]
    let fields1 = fieldsObj1[channel]
    let getMe = (scb, ecb) => {
        setMe({status: 'loading'})
        global.http.get(`/proxy/${info.channel}/me`, {user: tuser}).then(r => {
            console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r);
            scb && scb();
            setMe(r)

        })

    }

    let tokenEmail = (scb, ecb) => {
        setMe({status: 'loading'})

        global.http.get(`/proxy/${channel}/check-token`, {access_token: info?.creds?.access_token}).then(r => {
            setMe(r)
            scb && scb()
        }).catch(e => {
            setMe(e)
            ecb && ecb()
        })
    }

    let phone = info?.creds?.phone;

    function getCodeRequest(cb) {
        setIsCode(true)
        cb && cb();
        global.http.get('/init-tg-login-flow', {phone, userId: tuser}).then(r => {
            console.log("qqqqq get code requestssssssssssssss", r);
        })
        // global.http.get('/api')
        // setTimeout(() => {
        //     cb && cb()
        //     setIsCode(true)
        // }, 500)
    }

    function verifyCode(cb, ecb) {
        setVerifyRes(null)
        global.http.get('/verify-tg-code', {phone, code, userId: tuser})
            .then(r => {
                console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r);
                if (r.err || !r.session) {
                    setVerifyRes(r)
                    info.status = 'error'
                    saveAll(info)
                    ecb && ecb()
                    setIsCode(false)
                } else {
                    cb && cb()
                    info.creds.session = r.session
                    reinitOne(info, () => {
                        getMe()
                    })
                    setVerifyRes(null)
                }
            })
        // setIsCode(false)
        // setPhoneNumber()
    }

    function validateLink(scb, ecb) {
        global.http.get("/validate-link", {channel, tuser: tuser, link: info.creds.validationLink}).then(r => {
            let {proxyRes} = r || {};
            if (proxyRes.access_token) {
                info.creds = proxyRes;
                reinitOne(info, () => {
                    getMe()
                })
                // setValidateInfo(proxyRes)
            } else {
                setMe(r)
                // setValidateInfo(r)
            }
            scb && scb()
        }).catch(e => {
            setMe(e)
            // setValidateInfo(e)
            ecb && ecb()
        })
    }

    let loadCvs = (scb, ecb) => {
        setIsCvItem('loading')

        global.http.get('/sync-cvs', {user: tuser}).then(r => {
            console.log("qqqqq rrrrrr", r);
            scb && scb()
            setCvItems(r.items)
            setIsCvItem('success')
        }).catch(() => {
            setIsCvItem('error')

            ecb && ecb();
        })
    }


    let linkgg = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//mail.google.com/&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A//localhost&client_id=547514586297-lu0f39l8bf20js8kd8bbh96ve2tsfot4.apps.googleusercontent.com`
    let linkhh = `https://hh.ru/oauth/authorize?state=test_state&response_type=code&client_id=P1A4M15UK0RQNEEA0F83HQ7O68OP820CQJ6GRPKS2DO9EC7PSNEN3730O04O5H0T&redirect_uri=https://itrum.ru/contacts`

    return <div>
        <ErrMsgs channel={channel} tuser={tuser}></ErrMsgs>
        <MyModal
            isOpen={me}
            onClose={() => setMe(false)}
        >
            <Stringify item={me}></Stringify>

        </MyModal>
        <MyModal
            isOpen={isCvItems}
            onClose={() => setIsCvItem(false)}
        >
            {isCvItems === 'loading' && <>Подгрузка CV</>}
            {isCvItems === 'success' && <>Успешно синхронизированы ( CV )</>}
            {isCvItems === 'error' && <>Ошибка синхронизации CV</>}
            {(cvItems || []).map((item, ind) => {
                let it = item?.data;
                return (<div key={ind} title={item?.hhId}>
                    <strong style={{
                        fontSize: '16px',
                        padding: '8px 0 0 0',
                        display: 'block'
                    }}>{it?.title || it?.name}</strong>
                    <div>
                        {(it?.skill_set || []).map((it, ind) => {
                            return (<small key={ind} style={{padding: '5px', marginRight: '1px', marginTop: '1px'}}
                                           className={'label label-info ib'}>
                                {it?.name || it}
                            </small>)
                        })}
                    </div>
                </div>)
            })}

        </MyModal>
        <Smart
            obj={info}
            defSize={12}
            items={[{key: 'status', type: 'select', items: ['', 'active', 'error', 'unactive']}]}
            onChange={saveAll}
        >
        </Smart>
        <small>
            <a onClick={() => {
                setIsDetails(!isDetails)
            }}>Детали</a>
        </small>
        <div></div>
        {!!fields1?.length && <Smart
            obj={info}
            defSize={12}
            items={[{
                key: 'creds', childs: [...fields1.map(it => {
                    return {
                        key: it,
                        name: it
                    }
                })]
            }]}
            onChange={saveAll}
        >
        </Smart>}
        {!!isDetails && <Smart
            obj={info}
            defSize={12}
            items={[{
                key: 'creds', childs: [...fields.map(it => {
                    return {
                        key: it,
                        name: it
                    }
                })]
            }]}
            onChange={saveAll}
        >
        </Smart>}
        {verifyRes && <Stringify item={verifyRes}></Stringify>}
        {validateInfo && <Stringify item={validateInfo}></Stringify>}
        <Button size={'xs'} color={4} onClick={getMe}>Информация</Button>
        <Button size={'xs'} color={4} onClick={(scb, ecb) => reinitOne(info, scb, ecb)}>Инит</Button>
        {channel == 'tg' && <>
            <Button size={'xs'} color={4} onClick={getCodeRequest}>Получить код</Button>
        </>}
        {channel == 'hh' && <>
            <Button size={'xs'} color={4} onClick={tokenEmail}>Чек токена</Button>
            <Button size={'xs'} color={4} onClick={loadCvs}>Подгрузка CV ({cvItems?.length})</Button>
        </>}
        {channel == 'email' && <>
            <Button size={'xs'} color={4} onClick={tokenEmail}>Чек токена</Button>
        </>}


        {isCode && <div>
            <Input value={code} onChange={setCode}></Input>
            <Button onClick={verifyCode} color={0} size={'xs'}>Подтведить код</Button>
        </div>}
        <hr/>
        <div>ShortInfo:</div>
        <Stringify item={info.shortInfo}></Stringify>
        <hr/>

        {channel == 'hh' && <small>
            <div>1. Перейдите по ссылке в нужном браузере (где залогинен email пользователь)
                <div></div>
                <a target="_blank" className={'ib'} style={{wordBreak: 'break-all'}} href={linkhh}>{linkhh}</a></div>
            <div>2. Скопируйте ссылку (страница контактов итрум)</div>
            <Input value={info?.creds?.validationLink} onChange={v => {
                info.creds = info.creds || {}
                info.creds.validationLink = v;
                saveAll(info)
            }}></Input>
            <Button size={'xs'} color={4} onClick={validateLink}>Подтвердить ссылку</Button>
        </small>}
        {channel == 'email' && <small>
            <div>1. Перейдите по ссылке в нужном браузере (где залогинен email пользователь)
                <div></div>
                <a target="_blank" className={'ib'} style={{wordBreak: 'break-all'}} href={linkgg}>{linkgg}</a></div>
            <div>2. Скопируйте урл</div>
            <Input value={info?.creds?.validationLink} onChange={v => {
                info.creds ??= {};
                info.creds.validationLink = v;

                console.log("qqqqq vvvvvvv", v);
                saveAll({...info})
                //
            }}></Input>
            <Button size={'xs'} color={4} onClick={validateLink}>Подтвердить ссылку</Button>
        </small>}
    </div>
}

function ErrMsgs(props) {
    let {channel, tuser} = props;

    let [items, setItems] = useState([])
    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(true)

    let resend = (_ids, scb, ecb) => {
        _.each(_ids, (item, ind) => {

            global.http.get('/resend/' + item).then(r => {
                console.log("qqqqq rrrrrrrrrrrrrrr", r);
                // onChange && onChange(r)
                setItems(items => {
                    return items.map(it => {
                        if (it._id == r._id) {
                            return r;
                        } else {
                            return it;
                        }
                    })
                })
                scb && scb()
            }).catch(e => {
                ecb && ecb()
            })
        })

    }
    useEffect(() => {
        global.http.get('/message', {
            filter: {status: 'error', tuser, msgType: 'msg', msgSubType: 'apply', channel},
            per_page: 1000
        }).then(r => {
            console.log("qqqqq rrrr", r);
            setLoading(false)
            setItems(r.items)
            // setItems(r.items.map(it => {
            //     return {...it, serverRes: {}}
            // }))
        })
    }, [])
    return <div className={loading ? 'o5' : ''}>
        <MyModal
            isOpen={isOpen}
            size={'full'}
            onClose={() => setIsOpen(false)}
        >
            {!items?.length && <>Нет не отправленных джобов</>}
            {(items || []).map((item, ind) => {
                let link = '';
                if (channel === 'hh') {
                    link = `https://hh.ru/vacancy/${item.to}`
                }
                return (<div key={ind} className={'row'}>
                    <div className="col-xs-12">
                        <div className="pull-left mr-5">
                            <Button onClick={(scb, ecb) => resend([item?._id], scb, ecb)}>Отправить</Button>
                        </div>
                        {link && <>
                            <a href={link} target={'_blank'} className={'btn btn-default'}>
                                <span className="fa fa-link"></span>
                            </a>
                        </>}
                        {item.msg}

                    </div>
                    <div className="col-xs-12">
                        <Stringify item={item.serverRes}></Stringify>
                    </div>
                </div>)
            })}

        </MyModal>
        <small onClick={() => {
            setIsOpen(true)
        }}><a>Открыть ошибки ({items.length}) {channel}</a></small>

    </div>

}


export default Layout2
