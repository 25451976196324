import React, {useState, useRef, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import Textarea from "../../libs/Textarea";
import Input from "../../libs/Input";
import {getActiveClass} from "./Apply";
import Stringify from "../Stringify";
import {Problematics} from "./UserJobDetails";
import NewDialogModal from "./NewDialogModal";

export function loadTgHistory(fromNum, to, dialogId, cb) {
    global.http.get('/tg/load-history', {fromNum: fromNum, to, dialogId}).then(r => {
        console.log("qqqqq rrrrrr", r);
        cb && cb({items: r.items || []})
    })
}

export function loadAnyHistory(channel, fromNum, to, dialogId, cb) {
    console.log("load history!!!!!!!!!!!!!!!")
    global.http.get(`/${channel}/load-history`, {fromNum: fromNum, to, dialogId}).then(r => {
        console.log("qqqqq rrrrrr", r);
        cb && cb({items: r.items || []})
    })
}

export function loadDialogHistory(dialog, cb) {
    console.log("load history!!!!!!!!!!!!!!!")
    global.http.get(`/by-dialog/load-history`, {dialog}).then(r => {
        console.log("qqqqq rrrrrr", r);
        cb && cb({items: r.items || []})
    })
}

export function loadHhHistory(fromNum, vacancyId, dialogId, cb) {
    global.http.get('/hh/load-history', {fromNum: fromNum, to: vacancyId, dialogId}).then(r => {
        console.log("qqqqq rrrrrr", r);
        cb && cb({items: r.items || []})
    })
}


export let getMsg = (v, {channel, historyByUserChannel, job0}) => {
    let customData = job0?.customData || {};

    return (v || '').replace(/\{\{(.+?)\}\}/gi, (...args) => {
        let clientName = historyByUserChannel?.name || (customData || {})[channel + 'Name']
        if (!clientName) {
            return ''
        }
        if (/cv|cv1|cv2|cv3/gi.test(args[1])) {
            return `{{${args[1]}}}`
        }
        return (args[1] || '').replace(/client/gi, clientName)
    })
}

let btnIds = 0

let cacheHistory = {};

function Layout2(props) {
    let [isOpen, setIsOpen] = useState(props.isDetailsOpen);
    let [isApplyOpen, setIsApplyOpen] = useState(false);
    let [selectedTags, setSelectedTags] = useState({});
    let [opens, setOpens] = useState({})
    let [msg, setMsg] = useState('4444124 ')
    let [msgTitle, setMsgTitle] = useState('')
    let [file, setFile] = useState('/file/1000/1713508960535.pdf')
    let [fileName, setFileName] = useState('MyResume.pdf')
    let [resumeId, setResumeId] = useState('')
    let [delay, setDelay] = useState(0)
    let [loading, setLoading] = useState(false)
    let {channel, history, from, to, onChangeHistory} = props;
    let [items, setItems] = useState([])
    let [cvs, setCvs] = useState([])
    let [subscribeId] = useState(window.WS.getID())
    let loadingIndRef = useRef(0)

    const messagesEndRef = useRef(null);
    const btn = useRef(null);
    const btnId = useRef('btnIds' + ++btnIds);
    const btnApplyId = useRef('btnApplyIds' + ++btnIds);
    let {
        isFinalActive, fromNum, selectedSkills, parentType, parentInfo,
        historyByUserChannel, setHistoryByUserChannel, isWoApply
    } = props || {};
    historyByUserChannel = historyByUserChannel || {}
    setHistoryByUserChannel = setHistoryByUserChannel || function () {
        console.log("set hisotry messgsg")
        alert('set history is not defined')
    }
    let job0 = props?.info || {};
    let user = props?.user || {};
    selectedSkills ??= {}

    useEffect(() => {
        props.onLoadHistory && props.onLoadHistory(items)
    }, [items])
    useEffect(() => {
        // console.log("qqqqq SEL USERSSSSSSSSSSSSSSSSSSSSSSSSSSSS MSGS", props);
    }, [])

    function sendMsg() {
        window.$(`#${btnId.current}`).click()
    }

    function apply() {
        window.$(`#${btnApplyId.current}`).click()
    }

    useEffect(() => {
        window.WS.subscribe('message', subscribeId, (data) => {
            console.log("qqqqq message33333 is changed", subscribeId, data, props);

            if (data.channel != props.channel || (data.from != props.fromNum && data.fromNum != props.fromNum)
                || ((data.to != props.to) && (data.toNum != props.to))) {
                return;
            }

            console.log("qqqqq message33333 is changed FOUNDDDDDDDDDDDDDDDDDDDD", subscribeId, data, props);

            setItems((items) => {
                console.log("qqqqq itemssssssssss", items);
                let isFound;
                _.each(items, (item, ind) => {
                    if (item._id && data._id) {

                    }
                    if (item._id == data._id) {
                        isFound = true
                        console.log("qqqqq founddddddddddddddddd 333333333",);
                        items[ind] = {...item, ...data}
                    }
                })

                if (!isFound) {
                    items = items || []
                    items.push(data)
                    scrollToBottom()
                }
                return [...items]
            })
            // items.push(data)
            // setItems([...items])

        })
        return () => {
            window.WS.unsubscribe('message', subscribeId)
        }
    }, [from, to, channel, fromNum])

    useEffect(() => {
        setCvs(sortCvs(cvs))
    }, [Object.keys(selectedSkills).filter(it => selectedSkills[it]?.status == 'ok').join('_')])

    useEffect(() => {
        props.onChangeInput && props.onChangeInput({msg, delay, file, resumeId, sendMsg, apply})
    }, [msg, delay, file, resumeId])

    // console.log("qqqqq props.itemsHistory", props.itemsHistory);

    useEffect(() => {
        if (/tg|hh|email/gi.test(channel) && (!from || !to)) {
            setItems([])
            return;
        }

        console.log("qqqqq reload chat ", from, to, props.itemsHistory?.items);
        if (props.itemsHistory?.items) {
            setTimeout(() => {
                onChangeHistory && onChangeHistory(props.itemsHistory)
            }, 100)
            return setItems(props.itemsHistory.items)
        }

        syncChat(null, true);

    }, [from, to, props?.activeDialog?._id])

    // useEffect(() =>{ })
    useEffect(() => {
        channel === 'hh' && loadCvs()
    }, [channel, from])

    function loadCvs() {
        global.http.get('/c-v', {filter: {TUser: from}}).then(r => {
            let items = sortCvs(r.items);
            // console.log("qqqqq itemssssssssssssssssssss", copy(r.items[0].skills));

            setCvs(items)
            setResumeId((items[0] || {}).data?.id)
        })
    }

    function sortCvs(items = cvs, selTags = selectedTags) {
        let toObj = (acc, it) => {
            return {...acc, [it]: true}
        };
        let cd = new Date().getTime()
        let days = 24 * 3600 * 1000;
        let week1 = cd - 7 * days
        let week2 = cd - 14 * days
        _.each(items, (item, ind) => {
            let skill_set = item?.data?.skill_set || [];
            let resumeId = item?.resumeId;
            let skillsHistoryObj = item?.skillsObj || {};
            let jobSkillsObj = selectedSkills;//Object.keys(selectedSkills).reduce(toObj, {})
            let job_skill_set = Object.keys(selectedSkills);
            let cvSkillsObj = skill_set.reduce(toObj, {})

            let isLogs = item?.data?.id == '617f61adff0cb427ce0039ed1f78386e38735a';
            isLogs && console.log("qqqqq skill_set", selTags, resumeId,
                jobSkillsObj, skillsHistoryObj, cvSkillsObj, open_vacancies);

            let goodCount = 0;
            let curCount = 0;


            items[ind].fullSkills = _.sortBy(_.uniq([...job_skill_set || [], ...skill_set || []].map(it => {
                let score = 0;
                let jobScore = jobSkillsObj[it] ? open_vacancies > 20 ? 10 : 1 : 0;
                let historyScore = 0;

                let historyItem = skillsHistoryObj[it] || []
                // isLogs && console.log("qqqqq skill_set history item2222", historyItem, skillsHistoryObj);

                _.each(historyItem || [], (item, ind) => {
                    let open_vacancies = item.open_vacancies || 0;
                    let isGoodVacancies = open_vacancies > 20;
                    let time = isGoodVacancies ? week2 : week1
                    isLogs && console.log("qqqqq skill_set history item2222", item.cd, time, item.cd > time, job0?.data?.id, item.jobId);

                    if (item.jobId == job0?.data?.id) {
                        return;
                    }

                    if (item.cd > time) {
                        historyScore += 1 * isGoodVacancies ? 3 : 1
                    }
                })


                return {
                    name: it,
                    status: selectedSkills[it]?.status == 'ok' ? 'ok' : 'not_active',
                    score: jobScore + historyScore,
                    jobScore,
                    historyScore,
                }
            }), it => it.name), (it) => (-1) * it.score);

            _.each(items[ind].fullSkills, (item, ind) => {
                if (cvSkillsObj[item?.name]) {
                    curCount++
                }
                if (ind > 30) {
                    // item.limitStatus = 'err'
                    return;
                }

                // item.limitStatus = 'ok'
                // item.jobStatus = it.score > 1 ? 'good' : 'ok'
                if (jobSkillsObj[item.name]) {
                    goodCount++;
                }

            })

            let it = items[ind]

            items[ind].curCount = curCount;
            items[ind].goodCount = goodCount;
            items[ind].totalCount = skill_set.length;
            items[ind].jobTotalCount = job_skill_set.length;
            items[ind].perc = Math.round(((it.goodCount || 0) / (it.jobTotalCount || 1)) * 100);

            let leng = it.fullSkills.length;
            items[ind].finalScore = 1 * ((it.perc) - (leng / 100) + (Math.max(0, leng - 30) / 1000))

            console.log("qqqqq final scrore", it.finalScore, it);
        })

        items = _.sortBy(items, it => it.finalScore);

        return items;

    }


    function getPostMsg(opts) {
        return {
            fromNum: props.fromNum,
            tuser: from,
            to,
            open_vacancies,
            // msg,
            // msgTitle,
            channel,
            isMy: true,

            info: {
                resume: resumeId, parentInfo,
                newTags: [],
                selectedTags: selectedTags[resumeId]
            },
            msgDate: new Date().getTime(),
            // file,
            // fileName,
            //
            // // parentType,
            //
            // delay,
            ...opts || {},
            ...historyByUserChannel || {}
        }
    }


    function onSend(cb, opts, params) {
        let data = getPostMsg(opts)
        // onChangeJob({})
        // console.log("qqqqq dataaaaaaaaa", data);

        console.log("qqqqq on send msgs", data, params);

        global.http.post('/send-msg', data).then(r => {
            console.log("qqqqq on send msgs items4444444444444444444444", r);
            cb && cb()

            // setTimeout(() => {
            let _items = [...items || [], ...r?.items?.filter(it => it) || []];
            setItems(_items)
            // cacheHistory[getKey()] = items;
            let cv = r.resume;

            console.log("qqqqq on send msgs items444444444444444", cv);

            if (cv) {
                setCvs((cvs) => {
                    _.each(cvs, (item, ind) => {
                        if (item.resumeId == cv.resumeId) {
                            cvs[ind] = cv;
                        }
                    })
                    return [...cvs]
                })
            }
            syncChat(() => {
                cb && cb();
            })
            // }, 2000)
            console.log("qqqqq on send msgs4444", params, params?.toClear);

            if (params?.toClear) {
                setHistoryByUserChannel && setHistoryByUserChannel({toClear: true})
            }
        })

    }

    useEffect(() => {
        scrollToBottom()
    }, [items?.length])

    function sendApply(cb) {

        setHistoryByUserChannel({applyCd: new Date().getTime()})
        props.onChangeJob && props.onChangeJob({_id: job0._id, status: 'applied', applyDate: new Date().getTime()})
        onSend(cb, {msgSubType: 'apply'});

    }

    function sendAction(cb) {
        onSend(cb, {msgType: 'action', msgSubType: 'ping'});
    }

    function incLoadingRef() {
        return ++loadingIndRef.current;
    }


    function getKey() {
        return `${channel}_${fromNum}_${to}`
    }


    function syncChat(cb, isCache) {

        if (isCache) {
            // let key = getKey();
            // if (cacheHistory[key]) {
            //     setItems(cacheHistory[key])
            //     return;
            // }
        }
        let loadingInd = incLoadingRef();
        if (props?.itemsHistory?.length) {
        }
        setLoading(true)


        let dialogId = props?.activeDialog?._id;
        if (channel == 'hh') {
            loadHhHistory(fromNum, to, dialogId,(r) => {
                setLoadingCb(loadingInd, r, cb, true)
            })
        } else if (channel == 'tg') {
            loadTgHistory(fromNum, to, dialogId,(r) => {
                setLoadingCb(loadingInd, r, cb, true)
            })
        } else if (channel == 'email') {
            loadAnyHistory(channel, fromNum, to, dialogId, (r) => {
                setLoadingCb(loadingInd, r, cb, true)
            })
            // setLoading(false)
            // cb && cb()
        } else {
            loadDialogHistory(dialogId, (r) => {
                setLoadingCb(loadingInd, r, cb, true)
            })
        }
    }

    useEffect(() => {
        trySetMsg(null, true)
    }, [props.rerenderMsgs])
    useEffect(() => {
        trySetMsg(null, true)
    }, [job0?.customData?.lng])

    function trySetMsg(msgs, isForceSet) {

        msgs = msgs || items;
        if (!isForceSet && historyByUserChannel?.msg) {
            return;
        }
        if (!isForceSet && msgs && msgs.length) {
            return;
        }

        let customData = job0?.customData || {};
        let user = props?.user || {}

        let lng = customData?.lng;
        let applies = (user?.applies || {})[props?.channel]


        let activeApplies = (applies || []).map((it, ind) => {
            return {
                fileName: it['file_name_' + lng],
                title: it['title_' + lng],
                name: it['text_' + (lng)], file: it['file_' + (lng)], ind
            }
        }).filter(it => it.name)
        console.log("qqqqq historyByUserChannelhistoryByUserChannel3333333333", applies, activeApplies);


        let it = _.shuffle(activeApplies)[0] || {};

        let msg = getMsg(it?.name, {job0, historyByUserChannel, channel})

        setHistoryByUserChannel({
            msg: msg,
            msgTitle: it.title,
            file: it.file, fileName: it.fileName, templateId: it.ind
        })

        console.log("qqqqq NEXT MOVE historyByUserChannelhistoryByUserChannel", it, applies, job0);
    }


    function setLoadingCb(loadingInd, r, cb, isEmptyResend, isManualReload) {
        if (loadingInd != loadingIndRef.current) {
            return;
        }

        setLoading(false)
        onChangeHistory && onChangeHistory(r)
        let msgs = isManualReload ? r.items : r.items;
        setItems(msgs);
        // cacheHistory[getKey()] = msgs;
        cb && cb()

        if (r?.err) {
            // global.notify.error(r?.err)
            setItems([{err: 'ERROR.' + r?.err}])
            // trySetMsg([])
            return;
        }
        if (channel != 'custom' && isFinalActive && isEmptyResend && !r?.items?.length) {
            syncChat2(cb)
        } else {
            setLoading(false)
            trySetMsg(msgs)
        }

    }


    function syncChat2(cb) {
        setLoading(true)

        // global.http.get(`/proxy/${channel}/messages`, {user: from, to: to}).then(r => {
        let loadingInd = incLoadingRef()
        if (!to) {
            return setLoadingCb(loadingInd, {err: 'Выберите ИД пользователя'}, cb, false, true)
        }
        global.http.get(`/manual-reload`, {
            channel,
            fromNum: props.fromNum,
            negotiationId: props.negotiationId || 0,
            user: from, to: to, dialogId: props.dialogId || ''
        }).then(r => {
            console.log("qqqqq rrrrrr manual reaload", r);
            setLoadingCb(loadingInd, r, cb, false, true)
            scrollToBottom();
        }).catch(e => {
            alert(e.toString())
        })
    }

    function setTags(cb, perc = 100) {
        let curResume = cvs.find(it => it.resumeId == resumeId);

        let curSkills = (curResume?.fullSkills || []).map(it => it.name).filter((it, ind) => ind < 30)
        // _.each(curResume?.skills || [], (item, ind) => {
        //     curSkills.push('ind' + ind)
        // })

        console.log("qqqqq curSkills", curResume, curSkills, cvs);
        let skills = Object.keys(selectedSkills).map(it => (selectedSkills[it] || {}).status == 'ok' ? it : null).filter(it => it)
        console.log("qqqqq set Tagssssss", resumeId, selectedSkills, skills);
        global.http.post('/set-hh-tags', {
            perc,
            resume: resumeId,
            // forceSkills: curSkills,
            forceSkills: _.shuffle(curSkills),
            skills,
            resumeSkills: curSkills,
            user: from
        }).then(r => {
            console.log("qqqqq force set tags", r);
            if (!r.getCV) {
                return alert('PRoblem to set tags')
            }
            _.each(cvs, (item, ind) => {
                if (item.resumeId == resumeId) {
                    cvs[ind] = {...cvs[ind], data: r.getCV}
                }
            })
            setCvs(sortCvs([...cvs]))

            console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr curSkills", r);
        })
        cb && cb();
    }


    function sendReaction(reaction, message) {
        global.http.get('/send-reaction', {user: from, to, message, channel, reaction}).then(r => {

        })
    }


    function markChat(cb, ecb) {
        global.http.get('/mark-chat', {user: from, to, channel})
            .then(r => {
                cb && cb()
            })
            .catch(e => {
                ecb && ecb()
            })
    }

    function markChatUnread(cb, ecb) {
        global.http.get('/mark-chat', {user: from, to, channel, isUnread: true})
            .then(r => {
                cb && cb()
            })
            .catch(e => {
                ecb && ecb()
            })
    }

    function editMessage(opts) {
        global.http.get('/edit-message', {user: from, to, ...opts}).then(r => {

        })
    }

    function pubDate(d) {
        let date = new Date(d)
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        let pub = (v) => v < 10 ? '0' + v : v
        return ` ${hours}:${minutes}  ${pub(day)}/${pub(date.getMonth() + 1)}`;
    }

    const toggleTag = (name, resumeId, type) => {
        console.log("qqqqq toggle tag", name, resumeId, type);
        selectedTags[resumeId] ??= {}
        let status = (selectedTags[resumeId][name] || {}).status;
        selectedTags[resumeId][name] = {status: status == 'active' ? 'unactive' : 'active'}
        setSelectedTags({...selectedTags})
        setCvs(sortCvs(cvs, selectedTags))
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
        messagesEndRef.current?.scrollTo({
            top: 1000000,
            // behavior: 'smooth'  // Optional: for smooth scrolling
        });
    };


    let isApplyActive = user?.sortJobObj?.finalStatus == 'active';
    let {userCreds} = props;
    let REACTIONS = ['🔥', '👍', '❤', '🙏', '👏'];

    let open_vacancies = +props?.open_vacancies;
    let activeDialog = props?.activeDialog
    return <div className={loading ? 'loading-msgs' : ''}>
        <div>
            DIALOG_ID: {props?.dialogId}
            <div></div>
            <NewDialogModal activeJob={props?.activeJob}
                            item={{channel: 'tg', tuser: activeDialog?.tuser}}
                            activeDialog={props?.activeDialog}
                            systemMessages={items}
            ></NewDialogModal>
            <hr/>
        </div>


        {/*<small>Messages</small>*/}
        {/*<div>{JSON.stringify({channel, from, to})}</div>*/}
        {/*<div>*/}
        {/*    <hr/>*/}
        {/*</div>*/}
        {/*<Stringify item={userCreds}></Stringify>*/}
        {/*<a data-set-text={'active'} onClick={(cb) => {*/}
        {/*    trySetMsg([], true);*/}
        {/*    cb()*/}
        {/*}}>Установить Текст</a>*/}
        <div className={'msgs-wrap '} ref={messagesEndRef}>
            {(items || []).map((it, ind) => {
                let {channel} = it;
                let msgId = it.msgId;
                let isMy = it.isMy || (it.channel === 'email');
                let isSpecial = it.msgType === 'system' || it.msgType === 'action'
                let systemInfo = it?.info?.parentInfo || {}
                // if (isSpecial) {
                //     console.log("qqqqq dataaaaaaaaaaaaaaaaaaaaaa", it);
                // }


                if (it?.err) {
                    return <div key={ind}>
                        <div className="fa fa-warning"></div>
                        {it?.err}</div>
                }
                return (<div key={ind}
                             data-msg-id={it._id}
                             data-status={it.status}
                             className={'msg-item ' + (isMy ? 'my-msg ' : '') + (/wait/gi.test(it.status) ? 'o5' : '')}>
                    <small>{!isMy && <div className="label label-success">Client</div>}
                        {isMy &&
                            <div
                                className="label label-warning">MY</div>}{pubDate(it.msgDate || it.cdMsgDate || it.date)} #{it._id}
                        <Link to={'/msg/' + it._id}>{it.status}</Link></small>

                    {it.file && <div>
                        <div className={'label label-success'}>FILE: {it.fileName || it.file}</div>
                    </div>}
                    {it?.info?.oldDialog?.hash && <div><a target={"_blank"} href={`/dialog-hash/${it?.info?.oldDialog?.hash}`}>Old dialog</a></div>}
                    {it?.info?.newDialog?.hash && <div><a target={"_blank"} href={`/dialog-hash/${it?.info?.newDialog?.hash}`}>New dialog</a></div>}
                    {isSpecial && <>
                        <div>
                            <div className="label label-info">{it.msgType.toUpperCase()}</div>
                            <div className="label label-info">{it.msgSubType}</div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <div><small className="ellipse ib">{it.msg}</small></div>
                                <div style={{display: 'block'}}><a href={systemInfo.postUrl}
                                                                   className={'ellipse'}>{systemInfo.postUrl}</a></div>
                            </div>
                        </div>
                    </>}
                    {!isSpecial && <>
                        {it.msgTitle && <div className={'row'}>
                            <div className="col-xs-12 text-right">
                                <strong className="ib text-right">
                                    <span className="pull-right">
                                    {it.msgTitle}
                                    </span>
                                </strong>
                            </div>
                        </div>}
                        <div></div>

                        {it.msg && <div>{it.msg}</div>}

                        {!it.msg && !it.msgTitle && <div>
                            #{ind + 1} {it.channel} {it.actionType}
                        </div>}
                        {(it.reactions || []).map((reaction, ind) => {
                            let userId = it?.peerId?.userId;

                            return (<div key={ind} className={'ib react-item'}>
                                {reaction?.reaction?.emoticon} {userId}
                            </div>)
                        })}

                        {it?.msgId && channel == 'tg' &&
                            <div style={{zoom: 1, padding: '3px'}} className={'react-wrap'}>
                                {(REACTIONS || []).map((reaction, ind) => {
                                    return (<div key={ind} className={'ib'} onClick={() => {
                                        sendReaction(reaction, msgId)
                                    }}>
                                        {reaction}
                                    </div>)
                                })}
                                <small className={'ib'} onClick={() => {
                                    sendReaction('', msgId)
                                }}>Rem Reaction
                                </small>
                                <small className="ib" onClick={() => {
                                    editMessage({msg: 'edited__' + new Date().getTime(), msgId: msgId})
                                }}><span className="fa fa-pencil"></span>
                                </small>
                            </div>}
                    </>}
                </div>)
            })}
        </div>

        <div className="row">
            <div className="col-xs-4">
                <Input value={historyByUserChannel.msgTitle} onChange={msgTitle => setHistoryByUserChannel({msgTitle})}
                       placeholder={'msgTitle'}></Input>
            </div>
            <div className="col-xs-4">
                <Input value={historyByUserChannel.file} onChange={file => setHistoryByUserChannel({file})}
                       placeholder={'file'}></Input>
            </div>
            <div className="col-xs-4">
                <Input value={historyByUserChannel.fileName} onChange={fileName => setHistoryByUserChannel({fileName})}
                       placeholder={'fileName'}></Input>
            </div>
            <div className="col-xs-12">
                <Textarea value={historyByUserChannel.msg} onChange={msg => setHistoryByUserChannel({msg})}></Textarea>
            </div>

            {/*<div className="col-xs-12">*/}
            {/*    <Input value={historyByUserChannel.templateId}*/}
            {/*           onChange={templateId => setHistoryByUserChannel({templateId})}*/}
            {/*           placeholder={'templateId'}></Input>*/}
            {/*</div>*/}
            {/*<div className="col-xs-12">*/}
            {/*    <Input value={historyByUserChannel.resumeId} onChange={resumeId => setHistoryByUserChannel({resumeId})}*/}
            {/*           placeholder={'resumeId'}></Input>*/}
            {/*</div>*/}

        </div>
        {!isWoApply && <Button onClick={sendApply} id={btnApplyId.current}
                               data-apply={isApplyActive ? 'active' : 'unactive'}>Аплай</Button>}
        <div className="ib">
            <Input
                type={'number'}
                value={historyByUserChannel.delay} onChange={delay => setHistoryByUserChannel({delay})}
                placeholder={'Секунд'}
                woTitle={true} woLabel={true}></Input>
            {
                <small>{new Date(new Date().getTime() + (+historyByUserChannel.delay || 0) * 1000).toString().substring(4, 25)}</small>}
        </div>
        {!isWoApply && <div className={'ib'}>
            {channel == 'hh' && <Button data-set-tags={isApplyActive ? 'active' : 'unactive'}
                                        onClick={(cb) => setTags(cb, historyByUserChannel?.tagsPerc)}>Подстройка HH
                тегов</Button>}

        </div>}
        <Button onClick={syncChat2} data-reload={'active'}>Перезагрузка чата</Button>


        <div className="ib"><a className={'btn btn-default'} onClick={() => setIsOpen(!isOpen)}>Детали</a></div>

        {isOpen && <div>
            <Button onClick={(cb) => onSend(cb, null, {toClear: true})} id={btnId.current} ref={btn}>Отправить</Button>
            <Button onClick={markChat}>Марк чата прочтенным</Button>
            <Button onClick={markChatUnread}>Марк чата не прочтенным</Button>
        </div>}
        {isWoApply && <>
            <a onClick={() => {
                setIsApplyOpen(!isApplyOpen)
            }}><small>
                Open Apply Details</small></a>
            <div>

                {isApplyOpen && <div>
                    <div className={'ib'}>
                        {channel == 'hh' && <Button data-set-tags={isApplyActive ? 'active' : 'unactive'}
                                                    onClick={(cb) => setTags(cb, historyByUserChannel?.tagsPerc)}>Подстройка
                            HH
                            тегов</Button>}



                    </div>
                    <Button onClick={sendApply} id={btnApplyId.current}
                            data-apply={isApplyActive ? 'active' : 'unactive'}>Аплай</Button></div>}
            </div>
        </>}


        {/*<div className="ib">*/}
        {/*    <div className="col-xs-12">*/}
        {/*        <Input value={historyByUserChannel.tagsPerc} onChange={tagsPerc => setHistoryByUserChannel({tagsPerc})}*/}
        {/*               placeholder={'tagsPerc'}></Input>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {(!isWoApply || (isWoApply && isApplyOpen)) && (channel == 'hh') && <div className={'cvsWrap'}>
            {(cvs || []).map((it, ind) => {
                let cv = it;
                let data = it.data;
                let cvData = it.skillsObj || {};
                let jobSkills = job0?.data?.key_skills || []
                let itSkillsObj = (it?.skills || []).reduce((acc, it) => {
                    return {...acc, [it.name]: {count: (cvData[it.name] || []).length}}
                }, {});
                let _resumeId = data?.id;

                console.log("qqqqq ittttttttttttt cvbs@@@", it, cvData);
                return (<div key={ind} className={getActiveClass(data?.id, resumeId)} onClick={() => {
                    setResumeId(data.id)
                }}>
                    <strong><a onClick={() => {
                        setOpens({...opens, [_resumeId]: !opens[_resumeId]})
                    }}>[ {it.perc}% ]</a> {data.title}</strong>

                    {opens[_resumeId] && <div>

                        <div>
                            FinalScore: {it.finalScore} - {it.goodCount} - {it.totalCount}
                        </div>
                        Good: {it.goodCount}
                        curCount: {it.curCount}
                        Total: {it.totalCount}
                        JobTotal: {jobSkills.length}
                        <div></div>
                        {(it?.fullSkills || []).map((it, ind) => {
                            let skillCount = (cvData[it.name] || []).length;
                            return (<div key={ind}
                                         title={`[ x${it.score} ] - ${it.jobScore} - ${it.historyScore}`}
                                         data-ind-30={ind < 31 ? 1 : 0}
                                         data-score={it.score > 1 ? 'great' : it.score > 0 ? 'mid' : 'norm'}
                                         className={'skill-item' + getActiveClass(it.status, 'ok')}>
                                {it.name}
                            </div>)
                        })}
                        {/*<Stringify item={it?.skillsObj || {}}></Stringify>*/}
                    </div>}

                    {/*<div>*/}
                    {/*    <div style={{width: '100%', margin: '5px 0', background: 'lightgrey', height: '1px'}}></div>*/}
                    {/*    /!*<Stringify item={selectedTags[_resumeId]}></Stringify>*!/*/}
                    {/*    /!*{_resumeId}*!/*/}
                    {/*    {(jobSkills || []).map((it, ind) => {*/}
                    {/*        let skillCount = (cvData[it.name] || []).length;*/}
                    {/*        // let resumeId =*/}

                    {/*        let resumeStatus = ((selectedTags[_resumeId] || {})[it?.name] || {}).status*/}
                    {/*        if (itSkillsObj[it?.name]) {*/}
                    {/*            return null;*/}
                    {/*        }*/}
                    {/*        return (*/}
                    {/*            <div key={ind} className={'skill-item ' + (resumeStatus == 'active' ? 'selected' : '')}*/}
                    {/*                 onClick={() => toggleTag(it?.name, _resumeId, 'special')}>*/}
                    {/*                {it?.name} {!!skillCount && <>[ x{skillCount} ]</>}*/}
                    {/*            </div>)*/}
                    {/*    })}*/}


                    {/*</div>*/}
                </div>)
            })}

        </div>}

    </div>
}

export let copy = (v) => {
    return JSON.parse(JSON.stringify(v))
}
export default Layout2
