import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import moment from 'moment';
import {
    Link, Outlet
} from "react-router-dom";
import ChatDetails, {pubDate} from "./ChatDetails";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Input from "../../libs/Input";
import Select from "../../libs/Select";
import Smart from "../../libs/Smart";
import {getActiveClass} from "./Apply";
import Msgs from "./Msgs";
import Stringify from "../Stringify";
import {getHash, setHashObj} from "../Jobs";
import GroupButton from "../../libs/GroupButton/GroupButton";
import {getter} from "../methods/Fetcher";
import Datepicker from "../../libs/Datepicker/Datepicker";
import Textarea from "../../libs/Textarea";
import TechInterview from "./TechInterview";
import OfferInterview from "./OfferInterview";
import ChatDialogDetails from "./ChatDialogDetails";
import NewDialogModal from "./NewDialogModal";

function parseHash(hash) {
    const obj = {};
    // Remove the leading '#'
    const pairs = hash.slice(1).split('&');

    for (let i = 0; i < pairs.length; i++) {
        const [key, value] = pairs[i].split('=');
        obj[decodeURIComponent(key)] = decodeURIComponent(value);
    }

    return obj;
}

let dialogHttp = getter('/dialog')
let chatCd = new Date().getTime();

function Layout2(props) {

    let [newChat, setNewChat] = useState({tuser: 1000, username: 'teterevlyov'})
    let [filter, setFilter] = useState(getHash() || {})
    // let [selUser, setSelUser] = useState(0)
    let [selDialog, setSelDialog] = useState(0)
    let [localHistItems, setLocalHistItems] = useState([])
    let [applyDetails, setApplyDetails] = useState({})
    let [hist, setHist] = useState({})
    // let [activeDialog, setActiveDialog] = useState(null)
    let [open, setOpen] = useState(false)
    let [tusers, setTusers] = useState([])
    let [tusersObj, setTusersObj] = useState({})
    let [query, setQuery] = useState({})
    let [dialogs, setDialogs] = useState([])
    let [search, setSearch] = useState('')
    let [subscribeId] = useState(window.WS.getID())
    let [selected, setSelected] = useState(getDefault())


    let activeDialog = dialogs.find(it => it._id == filter.activeDialogId) //|| dialogs[0];
    let selUser = filter?.selUser;
    console.log("qqqqq active Dialogs", activeDialog, filter.activeDialogId);

    useEffect(() => {
        chatCd = new Date().getTime()
    }, [])
    useEffect(() => {
        if (new Date().getTime() - chatCd > 1000) {
            console.log('qqqqq SFF 555555 ', filter, getHash())
            setHashObj(filter)
        }
    }, [JSON.stringify(filter)])
    useEffect(() => {
        // loadUsers();
        loadAllDialogsAndUsers();

        window.WS.subscribe('dialog', subscribeId, (data) => {
            setDialogs((dialogs) => {
                console.log("qqqqq dialog is changed", subscribeId, data, dialogs);
                _.each(dialogs, (item, ind) => {
                    if (item._id == data._id) {
                        dialogs[ind] = {...dialogs[ind], ...data}
                    }
                })
                console.log("qqqqq dialogs dialogs", dialogs);
                return [...dialogs]
            })

        })

        return () => {
            window.WS.unsubscribe('dialog', subscribeId)
        }
    }, [])
    useEffect(() => {
        // loadDialogs();
    }, [query])

    function loadAllDialogsAndUsers() {
        global.http.get('/load-all-dialogs').then((r) => {
            console.log("qqqqq dialogs loaded", r);
            setTusers([{name: 'All', fakeId: -1}, ...r.users || []]);
            setTusersObj(r.users.reduce((acc, it) => {
                return {...acc, [it._id]: it}
            }, {}))
            setDialogs(r.dialogs)
        })
    }


    function getDefault() {
        let hash = parseHash(window.location.hash);
        console.log("qqqqq hashhhh", hash);
        return {tuser: +hash.tuser || 0, dialog: +hash.dialog || 0}
    }

    let onSetQuery = (query) => {
        _.each(query, (item, ind) => {
            if (!item || item == '0') {
                delete query[ind]
            }
        })

        console.log("qqqqq on set query", query);
        setQuery(query)
    }


    let onSetSelected = (it) => {
        setSelected(it)
        window.location.hash = `dialog=${it.dialog}`
    }

    // let loadDialogs = () => {
    //     setDialogs([{name: '124124124'}, {name: '2222222'}, {name: 'aaaaaaaaaaaaaa'}])
    // }
    console.log("qqqqq selected", selected);

    function getDialogs() {
        let searchReg = search ? new RegExp(search, 'gi') : null;


        let channel = filter.channel;
        console.log("qqqqq channel33333333333333", channel, searchReg);
        let userId = (tusers[selUser] || {})._id;

        let _dialogs = dialogs.filter(it => {
            let isChannel = (!channel) || (channel === 'any') || (it.channel == channel)
            let isUser = !userId || it.tuser == userId
            if (!searchReg) {
                return isChannel && isUser;
            }

            let isSearch = false

            _.each(['title', 'to'], (key, ind) => {
                let v = searchReg.test(it[key])
                isSearch = isSearch || v;
            })

            console.log("qqqqq aaaaaaaaaaaaaaaaaa", isChannel, isSearch, isUser, searchReg, it.title, it);
            return isChannel && isUser && isSearch;
        })

        return _dialogs
    }


    let _dialogs = getDialogs();//dialogs;
    // let activeDialog = _dialogs[selDialog]
    activeDialog = activeDialog || _dialogs[0];
    let selTUser = tusers.find(it => it._id == activeDialog?.tuser)
    let selId = selTUser;
    let channel = activeDialog?.channel;
    console.log("qqqqq activeDialog", activeDialog, selUser, tusers);

    let job0 = {}
    let history = {};

    let PARAMS = {
        from: selId,
        user: selTUser,
        isDetailsOpen: true,
        dialogId: activeDialog?._id,
        parentInfo: job0,
        activeDialog,
        parentType: 'apply',
        rerenderMsgs: props.rerenderMsgs,
        channel: activeDialog?.channel,
        info: job0,
        selectedSkills: [],
        historyByUserChannel: hist,
        open_vacancies: job0?.customData?.hhInfo?.item?.open_vacancies,
        // itemsHistory: (itemsHistoryObj[channel] || {})[selId],
        setHistoryByUserChannel: (r) => {
            console.log("set history by user channel", r)
            if (r?.toClear) {
                setHist({})
            } else {
                setHist({...hist || {}, ...r || {}})
            }
        },
        history: (history[selId] || {})[channel]?.items,
        userCreds: {},
        onChangeJob: props.onChangeJob,
        onLoadHistory: (items) => {
            console.log("qqqqq on load history", items);
            setLocalHistItems(items)
            setApplyDetails(items.find(item => {
                return item.msgSubType === 'apply' && item.msgType == 'system'
            }))
        },
        onChangeInput: (v) => {
            console.log("change input")
        },
        onChangeHistory: (r) => {
            console.log("set history by user channel 222222222222", r);
            console.log("change input history")
        }
    }

    let from = (channel === 'hh' ? selTUser?.info_hh?.id : selTUser?.info_tg?.id) || activeDialog?.fromNum;
    let to = activeDialog?.info?.vacancyId || activeDialog?.to || activeDialog?.toNum
    let negotiationId = activeDialog?.negotiationId;

    let link = channel === 'hh' ? `https://hh.ru/vacancy/${to}` : `https://web.telegram.org/k/#${to == +to ? -to : `@${to}`.replace(/\@\@/gi, '@')}`

    console.log('totototototo', to, activeDialog)
    // filter.selUser = selUser;
    // filter.activeDialogId = activeDialog?._id;

    let updateActiveDialog = (v) => {
        activeDialog = Object.assign(activeDialog, v)
        setDialogs([...dialogs])
        dialogHttp.update(activeDialog)
    }
    let onChangeSpecialInfo = (v) => {
        activeDialog.specialInfo = {...specialInfo || {}, ...v}
        updateActiveDialog(activeDialog)
    }
    let specialInfo = activeDialog?.specialInfo || {}
    let activeJob = applyDetails?.info?.parentInfo || {}

    let scrollToMsg = (msgId) => {
        const target = document.querySelector(`[data-msg-id="${msgId}"]`);
        target.scrollIntoView({behavior: 'smooth', block: 'center'});
    }

    let vvChatDialog = {
        activeDialog,
        filter,
        negotiationId,
        specialInfo,
        updateActiveDialog, link, from, to, applyDetails, activeJob, onChangeSpecialInfo, dialogs, setFilter, PARAMS
    }
    console.log("qqqqq activeJobactiveJob", activeJob);
    return <div className={'row forChats'}>


        {/*<Stringify item={activeDialog}></Stringify>*/}
        <div className="col-xs-12">
            <Smart
                obj={filter}
                onChange={(v) => {
                    setFilter({...filter, ...v || {}})
                    console.log("qqqqq SFF1", v);
                }}
                items={[{
                    size: 2,
                    type: 'group',
                    key: 'channel',
                    list: ['any', 'hh', 'tg', 'email', 'custom']
                }, {
                    size: 2,
                    type: 'group',
                    key: 'type',
                    list: ['unread', 'unread*', 'any']

                },
                    {
                        size: 2,
                        type: 'group',
                        key: 'msgType',
                        list: ['system', 'chat', 'any']

                    }, {
                        size: 2,
                        type: 'group',
                        key: 'status',
                        list: ['active', 'archived', '1-1', 'any']

                    },
                ]}
            ></Smart>
            <hr/>
        </div>
        <div className="col-xs-1">
            {(tusers || []).map((it, ind) => {
                return (<div key={ind}
                             onClick={() => {
                                 setFilter({...filter, selUser: ind})
                                 console.log("qqqqq qqqqq SFF 2",);
                                 // setSelUser(ind)
                             }}
                             className={'tuser' + getActiveClass(ind, selUser)}>
                    {it.name}
                </div>)
            })}


        </div>
        <div className="col-xs-3">
            <Input type="text" value={search} onChange={setSearch}/>

            {(_dialogs || []).map((it, ind) => {
                let isUnread = (it.lastOpenDate || '') < it.lastMsgDate
                return (<div key={ind} onClick={() => {
                    // setSelDialog(ind)
                    setFilter({...filter, activeDialogId: it._id})
                    console.log("qqqqq qqqqq SFF 3",);
                }}
                             title={it.lastMsgText || '-'}
                             className={'selDialog' + getActiveClass(activeDialog?._id, it?._id)}
                >

                    <small>
                        {isUnread && <span className="label label-danger">UnRead</span>}
                        <span className={"label label-default"}>{it.channel}</span> <span
                        title={it._id}>{(tusersObj[it.tuser] || {}).name}</span>
                    </small>
                    <div>{it.title}</div>
                    <div>
                        <small
                            className={'ellipseText'}>x{it.total > 999 ? 999 : it.total || 0} {it.lastMsgText || '-'}</small>
                    </div>
                    <div>{moment(it.lastMsgDate).fromNow()}</div>
                    {/*<div>{it.lastOpenDate}</div>*/}
                </div>)
            })}
        </div>
        <ChatDialogDetails {...vvChatDialog}></ChatDialogDetails>

    </div>
}

export function UnreadDialogs(props) {
    let {items, activeDialogId, onClick} = props
    let _items = items.filter(it => {
        let isUnread = (it.lastOpenDate || '') < it.lastMsgDate;
        return isUnread;
    })
    return <>
        {(_items || []).map((it, ind) => {

            return (<div key={ind} onClick={() => {
                onClick && onClick(it)
            }} className={getActiveClass(activeDialogId, it?._id)}>
                <div className="label label-success">{it.channel}</div>
                {it.from || it.fromNum} -> {it.to || it.toNum}
                <small className={'ellipseText'}>{it.lastMsgText || '-'}</small>

            </div>)
        })}


    </>
}


function WaitDetails({item}) {
    if (!/w\_/gi.test(item.status))
        return null
    if (!item.wait || !item.wait.date) {
        return '-'
    }
    let _wait = item.wait || {};
    let wait = new Date(_wait.date)
    wait.setHours(_wait.hours || 0);
    wait.setMinutes(_wait.minutes || 0);
    console.log("qqqqq waittttttttttttt", _wait, wait);
    return pubDate(wait);
}

export const toObjFromItems = (items) => {
    return items.reduce((acc, it) => {
        return {...acc, [it._id]: it}
    }, {})
}
let techHttp = getter('/interview')
let offerHttp = getter('/offer')

export function OfferInterviewsWrap(props) {
    let {dialog, sourceChannel} = props;
    let [offers, setOffers] = useState([])
    let [interviews, setInterviews] = useState([])
    let [selectedInterview, setSelectedInterview] = useState(null)
    let [selectedOffer, setSelectedOffer] = useState(null)
    let [interviewsObj, setInterviewsObj] = useState({})
    let {_id, tuser} = dialog || {}

    useEffect(() => {
        _id && techHttp.get({filter: {dialog: _id}, per_page: 1000}, items => {
            console.log('xxxrrrrrrrrrr', items)
            setInterviews(items)
            setInterviewsObj(toObjFromItems(items))
        })
        _id && offerHttp.get({filter: {dialog: _id}, per_page: 1000}, items => {
            setOffers(items)
        })
    }, [_id])

    let getStartOffer = (v) => {
        return {dialog: _id, offerDate: new Date(), sourceChannel, tuser, status: 'waiting', ...v || {}}
    }

    let getStartTech = (v) => {
        return {
            name: '',
            date: new Date(),
            dialog: _id,
            tuser,
            sourceChannel,
            resStatus: 'waiting',
            status: 'waiting',
            type: 'hr', ...v
        }
    }

    let grouppedInterviews = _.groupBy(interviews, it => it.type || 'hr')
    return <>
        {/*{_id} {tuser}*/}
        <hr/>
        {(['hr', 'tech', 'owner'] || []).map((it, ind) => {
            return (<div key={ind}>
                <Button size={'xs'} color={4} onClick={(cb) => {
                    cb && cb()
                    let v = getStartTech({type: it})
                    console.log('starttttttttttttt', v)
                    setSelectedInterview(v)
                }}>+ {it}</Button>
                {(grouppedInterviews[it] || []).map((it, ind) => {
                    return (<a key={ind} onClick={'ib ml5'} style={{marginLeft: '5px'}} onClick={() => {
                        setSelectedInterview(it)
                    }}>
                        <span data-item-status={it.resStatus || 'waiting'}></span>
                        {toODB(it.date) || '---'}
                    </a>)
                })}


            </div>)
        })}
        <Button size={'xs'} color={4} onClick={(cb) => {
            cb && cb()
            let v = getStartOffer()
            console.log('starttttttttttttt', v)
            setSelectedOffer(v)
        }}>+ offer</Button>
        {(offers || []).map((it, ind) => {
            return (<a key={ind} onClick={() => {
                setSelectedOffer(it)
            }}>
                <span data-item-status={it.status || 'waiting'}></span>
                {toODB(it.offerDate) || '---'}
            </a>)
        })}
        <MyModal
            isOpen={selectedInterview}
            onClose={() => {
                setSelectedInterview(false)
            }}
        >
            <TechInterview item={selectedInterview}
                           sourceChannel={sourceChannel}
                           onChange={v => {
                               let isFound = false;
                               interviews.forEach((it, ind) => {
                                   if (it._id == v._id) {
                                       isFound = true;
                                       interviews[ind] = it;
                                   }
                               })
                               if (!isFound) {
                                   interviews.push(v)
                               }
                               console.log("on change step 22222222222", v, interviews)
                               setInterviews([...interviews])
                               setSelectedInterview(false)
                           }}></TechInterview>
        </MyModal>
        <MyModal
            isOpen={selectedOffer}
            onClose={() => {
                setSelectedInterview(false)
            }}
        >
            <OfferInterview item={selectedOffer}
                            sourceChannel={sourceChannel}
                            onChange={v => {

                                let isFound = false;
                                offers.forEach((it, ind) => {
                                    if (it._id == v._id) {
                                        isFound = true;
                                        offers[ind] = it;
                                    }
                                })
                                if (!isFound) {
                                    offers.push(v)
                                }
                                console.log("on change step offer 22222222222", v, offers)
                                setOffers([...offers])
                                setSelectedOffer(false)
                            }}></OfferInterview>
        </MyModal>

        <DialogStats tuser={tuser}></DialogStats>

    </>
}

export function DialogStats(props) {
    let [stats, setStats] = useState({})
    let {tuser} = props;
    useEffect(() => {
        tuser && global.http.get('/dialog-stats', {query: {tuser}}).then(r => {
            let st = {};
            let allKey = 'All';

            let incrSt = (curKey, channel, count) => {
                channel = channel || 'custom'
                st[curKey] = st[curKey] || {}
                st[curKey][allKey] = (st[curKey][allKey] || 0) + count
                st[curKey][channel] = (st[curKey][channel] || 0) + count

            }
            _.each(r.applies, (item, ind) => {
                let {count, channel} = item || {};
                incrSt('applies', channel, count)
            })
            _.each(r.interviews, (item, ind) => {
                let {count, sourceChannel, type} = item || {};
                incrSt(type, sourceChannel, count)
            })
            _.each(r.offers, (item, ind) => {
                let {count, sourceChannel, type} = item || {};
                incrSt('offer', sourceChannel, count)
            })

            setStats(st)

        })
    }, [tuser]);
    return <div style={{maxHeight: '500px', overflow: 'auto'}}>
        <hr/>
        <table className={'table table-stripped table-bordered'}>
            <thead>
            <tr>
                <th></th>
                {(['All', 'tg', 'hh', 'email', 'custom'] || []).map((it, ind) => {
                    return (<td key={ind}>
                        {it}
                    </td>)
                })}
            </tr>

            </thead>
            <tbody>
            {(['applies', 'hr', 'tech', 'owner', 'offer'] || []).map((it, ind) => {
                return (<tr key={ind}>
                    <td>{it}</td>
                    {(['All', 'tg', 'hh', 'email', 'custom'] || []).map((it2, ind) => {
                        return (<td key={ind}>
                            {(stats[it] || {})[it2] || 0}
                        </td>)
                    })}
                </tr>)
            })}
            </tbody>
        </table>
    </div>
}

export function toODB(_this) {
    if (!_this) {
        return ''
    }

    _this = new Date(_this)

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }


    return _this.getUTCFullYear() +
        '-' + pad(_this.getUTCMonth() + 1) +
        '-' + pad(_this.getUTCDate())
}
export function toODBWithTime(_this) {
    if (!_this) {
        return ''
    }

    _this = new Date(_this)

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }


    return _this.getUTCFullYear() +
        '-' + pad(_this.getUTCMonth() + 1) +
        '-' + pad(_this.getUTCDate())
}

export default Layout2
