import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function Layout2(props) {
  console.log('*........ ## ROOT RENDER', props);
let {user} = props;
  return <div {...props}>
    <img src={user?.img} alt="" className={'profileImg'}/>
  </div>
}

export default Layout2
