import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from "../../libs/Smart";
import {CreateUpdateButton, setPingDate} from "./TechInterview";
import {getter} from "../Fetcher";


export const offerFields = [
    {
    name: 'InterviewsList', key: 'interviewList'
}, {
    name: 'Status', key: 'status', type: 'select', items: ['', 'waiting', 'accepted', 'workStarted', 'ourDecline', 'clientDecline', 'sbDecline']
}, {
    name: 'Name', key: 'name'
}, {
    name: 'offerDate', key: 'offerDate', type: 'date'
},  {
    name: 'startDate', key: 'startDate', type: 'date'
},   {
    name: 'sbDate', key: 'sbDate', type: 'date'
}, {
    name: 'dialog', key: 'dialog'
}, {
    name: 'tuser', key: 'tuser'
},  {
    name: 'amountRub', key: 'amountRub', type: 'Number'
},  {
    name: 'amountCurrency', key: 'amountCurrency', type: 'Number'
}, {
    name: 'currency', key: 'currency', type: 'select', items: ['', 'rub', 'usd', 'eur']
}, {
    name: 'sourceChannel', key: 'sourceChannel', type: 'select', items: ['', 'hh', 'tg', 'email', 'custom']
},{
    name: 'ping.date', key: 'ping', type: 'Date'
}, {
    key: 'interviews',
    each: [
        {name: 'Name', key: 'name'}
    ]
    }

]

let offerHttp = getter('/offer')

function OfferInterview(props) {
    console.log('*........ ## ROOT RENDER', props);
    let [itemObj, setItemObj] = useState({})
    let {item} = props;
    useEffect(() => {
        setItemObj(item)
    }, [JSON.stringify(item)])
    !itemObj?.ping?.date && itemObj?.date && setPingDate(itemObj.date, itemObj)

    return <div>
        <CreateUpdateButton item={itemObj} http={offerHttp} onChange={props.onChange}></CreateUpdateButton>
        Offer details
        <Smart
            items={offerFields}
            obj={itemObj}
            defSize={4}
            onChange={v => {
                console.log('vvvvvvvvvvv', v)
                setItemObj({...v})
            }}
        ></Smart>
    </div>
}

export default OfferInterview
