import React, {useState} from 'react';
import _ from 'underscore';
import Smart from './../libs/Smart';


function getter(opts, cb) {
    global.http.get('/profile/my')
        .then((r) => {
            cb && cb(r)
        })
        .catch(e => {
            console.log("qqqqqeeeeee",);
        })
}


function Profile(props) {
    const [obj, setObj] = useState(null);
    const [count, setCount] = useState(0);

    if (!obj) {
        setObj(global.user.get_info())
        getter({}, (v) => {
            setObj(v)
        })
    }
    return <div>
        Profile Page
        <Smart
            onChange={(obj) => {
                console.log('*........ ## change item', obj);
                setObj(obj)
                setCount(+count + 1)
            }}
            obj={obj}
            defSize={6}
            defClass={'row-np'}
            items={[
                // {
                //   size: 7,
                //   Component: (props) => {
                //     console.log('*........ ## prosp', props);
                //     return <pre>{JSON.stringify(props.item, null, 2)}</pre>
                //   }
                // },
                {
                    type: 'input',
                    name: 'Username',
                    key: 'username'
                }, {
                    type: 'input',
                    name: 'Password',
                    key: 'plain_password'
                },
                {HR: true, size: 12},
                {
                    type: 'btn',
                    name: "Save",
                    onClick: (v) => {
                        console.log("qqqqqclick", v);
                        global.user.on_update(v)
                        // global.http.put("/profile/my", {item: v})
                        //     .then(() => {
                        //
                        //     })
                        //     .catch(() => {
                        //
                        //     })

                    }
                }
            ]}
        ></Smart>
    </div>
}

export default Profile
