import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import React, {lazy, useRef, useEffect, useState, Suspense} from 'react';
import _ from 'underscore';
import env from './admin_env';
import Smart from './libs/Smart'
import Table from './libs/Table'
import Skeleton from './libs/Skeleton'
import Input from './libs/Input'
import Login from './libs/Login/Login'
import DefOne from 'libs/DefOne'
import DefList from 'libs/DefList'
import Jobs from './comps/Jobs'
import CompVars from './comps/CompVars'
import Fetcher from './comps/methods/Fetcher'


import {
    createBrowserRouter,
    RouterProvider,
    redirect,
    useLocation,
    useNavigate,
    useLoaderData,
    useParams,
    // useHistory,
    useActionData,
    Link, Outlet
} from "react-router-dom";
import Chat from "./comps/Chat/Chat";
import Stats from "./comps/Stats";
import GetHHProfiles from "./comps/HH/GetHHProfiles";
import JobApply from "./comps/JobApply";
import WS from "./comps/WS";
import Button from "./libs/Button";
import {StickyTest} from "./comps/Chat/Apply";
import {TemplateProfile} from "./comps/Chat/TemplateProfile";
import JobsByUsers from "./comps/JobsByUsers";
import {interviewFields} from "./comps/Chat/TechInterview";
import {offerFields} from "./comps/Chat/OfferInterview";
import PreviewChatDialogManual from "./comps/PreviewChatDialogManual";

let files = require.context('./comps', true, /\.(js|jsx)$/).keys();
global.Fetcher = Fetcher;

function getter(opts) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({_id: 1003, name: '124xzcv'})
        }, 5000)
    })
}

function pub_menu(arr) {
    return arr.map(it => {
        return it.name ? it : {name: it, url: '/' + it.toLowerCase()}
    })
}
let defUrl = '/posts#{"sort":"-postDate"}';


function to_url_arr(obj) {
    let arr = [];
    _.each(obj, (item, ind) => {
        let url = item.admin_url || ind;

        arr.push({
            path: url,
            element: <DefList props={item}>
            </DefList>

        })

        arr.push({
            path: url + "/:id",
            element: <DefOne props={item}>
            </DefOne>

        })
    })
    return arr;
}

let comp_statuses = [
    {name: '-', value: ''},
    {
        name: 'Активная', value: 'active'
    }, {
        name: 'Не активная', value: 'non_active'
    }]
let proj_statuses = [{
    name: 'К выполнению', value: 'for_to_do'
}, {
    name: 'Бэклог', value: 'backlog'
}, {
    name: 'Для теста', value: 'for_test'
}, {
    name: 'В работе', value: 'in_progress'
}, {
    name: 'Готово', value: 'done'
}]
let comp_types = [
    {
        name: 'Чекбокс', value: 'checkbox'
    }, {
        name: 'Кол-во', value: 'amount'
    }, {
        name: 'Мультиселект', value: 'multiselect'
    }]

let view_statuses = [
    {
        name: 'Десктоп', value: 'desctop'
    }, {
        name: 'Мобайл', value: 'mobile'
    }, {
        name: 'Фича', value: 'feature'
    }]

global.CONFIG = {
    menu: pub_menu(['HR', 'Logout']),
    //
    header: pub_menu(['Pages', 'Stack', 'TUsers', 'Interview', 'Offer', {name: 'Posts', url: defUrl}, {name: 'Posts ByU', url: '/posts-by-users'}, 'Chat', 'Stats', 'Clients', 'Dialog', 'Files', 'Msg', 'Parse',]),

    urls: {
// {name: 'name', type: 'String'},
// {name: 'img', type: 'String'},
// {name: 'links', type: 'Mixed', isArr: true},
// {name: 'cv', type: 'Mixed', isArr: true},
// {name: 'creds', type: 'Mixed'},
// {name: 'isActive', type: 'Boolean'},
        files: {
            url: '/file',
            // admin_url: '/users',
            edit: [
                // {name: 'name', key: 'name'},
                {
                    size: 12, Component: ({item}) => {
                        return <pre>{JSON.stringify(item, null, 4)}</pre>
                    }
                }
                // {name: 'roles', key: 'roles'},
            ],
            tabs: [
                {
                    key: 'filePath', Component: ({item}) => {
                        return <a href={`${item.uploadDomain}${item.filePath}`} target={"_blank"}>{item.filePath}</a>
                    }
                },
                {name: 'uploadDomain', key: 'uploadDomain'},
                {name: 'size', key: 'size'},
                {name: 'hostname', key: 'hostname'},
                {name: 'hash', key: 'hash'},
                {name: 'type', key: 'type'},
                {name: '_id', key: '_id'},
            ]
        }, clients: {
            url: '/client',
            // admin_url: '/users',
            top_filters: [
                {
                    key: 'status',
                    title: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'active',
                        name: 'Active'
                    }, {
                        name: 'Unactive',
                        value: 'unactive'
                    }
                    ]
                },
            ],
            defsize: 6,
            edit: [
                {name: 'status', key: 'status', type: 'select', items: ['', 'active', 'unactive']},
                {size: 12, type: 'text', value: ' '},

                {name: 'name', key: 'name', size: 6},
                {name: 'company', key: 'company', size: 6, type: 'textarea', minRows: 1},
                {name: 'stack', key: 'stackStr', size: 6},
                {name: 'desc', key: 'desc', size: 6, type: 'textarea', minRows: 1},
                {size: 12, type: 'text', value: ' '},
                {name: 'contact', key: 'contact', size: 6, type: 'textarea', minRows: 1 },
                {name: 'comment', key: 'comment', size: 6, type: 'textarea', minRows: 1},
                {name: 'history', key: 'historyStr', size: 6, type: 'textarea', minRows: 1},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'company', key: 'company'},
                {name: 'stack', key: 'stackStr'},
                {name: 'desc', key: 'desc'},
                {name: 'stack', key: 'stackStr'},
                {name: 'contact', key: 'contact'},
                {name: 'comment', key: 'comment'},
                {name: 'history', key: 'historyStr'},
            ]
        },
        dialog: {
            url: '/dialog',
            // admin_url: '/users',
            edit: [
                // {name: 'name', key: 'name'},
                {
                    size: 12, Component: ({item}) => {
                        return <pre>{JSON.stringify(item, null, 4)}</pre>
                    }
                }
                // {name: 'roles', key: 'roles'},
            ],
            tabs: [
                {name: 'from', key: 'from'},
                {name: 'to', key: 'to'},
                {name: 'tuser', key: 'tuser'},
                {name: 'channel', key: 'channel'},
                {name: 'title', key: 'title'},
                {name: 'lastMsgText', key: 'lastMsgText'},
                {name: 'isLastMy', key: 'isLastMy'},
                {name: 'lastMsgDate', key: 'lastMsgDate'},
                {name: 'lastDialogAPIParse', key: 'lastDialogAPIParse'},
                {name: 'lastMessagesAPIParse', key: 'lastMessagesAPIParse'},
                {name: 'lastAPIParse', key: 'lastAPIParse'},
                {name: '_id', key: '_id'},
            ]
        }, users: {
            url: '/user',
            // admin_url: '/users',
            edit: [
                // {name: 'name', key: 'name'},
                {name: 'username', key: 'username'},
                {name: 'plain_password', key: 'plain_password'},
                // {name: 'roles', key: 'roles'},
            ],
            tabs: [
                {name: 'username', key: 'username'},
                {name: 'roles', key: 'roles'},
                {name: '_id', key: '_id'},
            ]
        },
        apply: {
            woModal: true,
            url: '/job-apply',
            // admin_url: '/users',
            edit: [
                {Component: JobApply, size: 12}
            ],
            tabs: [
                {name: 'username', key: 'tgUsername'},
                {name: 'name', key: 'name'},
                {name: 'status', key: 'status'},
                {name: 'roles', key: 'roles'},
                {name: '_id', key: '_id'},
            ]
        },
        'templates': {
            woModal: true,
            url: '/chat-templates',
            // admin_url: '/users',
            edit: [


                {name: 'ru_m', key: 'info.ru_m', type: 'textarea', size: 6},
                {name: 'ru_w', key: 'info.ru_w', type: 'textarea', size: 6},
                {name: 'en_m', key: 'info.en_m', type: 'textarea', size: 6},
                {name: 'en_w', key: 'info.en_w', type: 'textarea', size: 6},
                {type: 'HR', size: 12},
                {name: 'name', key: 'name', type: 'input'},
                {name: 'type', key: 'type', type: 'select', items: ['', 'greeting']},
                {name: 'status', key: 'status', type: 'select', items: ['', 'active', 'unactive']},

                // {name: 'language', key: 'language', type: 'select', items: ['', 'ru', 'en']},
                // // {name: 'wOrWoName', key: 'wOrWoName'},
                // {name: 'gender', key: 'gender', type: 'select', items: ['', 'w', 'm']},
                {name: 'stack', path: 'StackSelector', size: 3},
                {name: 'platforms', path: 'PlatformSelector', size: 3},

            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'RU_M', key: 'info.ru_m'},
                {name: 'type', key: 'type'},
                {name: 'status', key: 'status'},
                {name: 'stack', key: 'stack'},
                {name: 'language', key: 'language'},
                // {name: 'wOrWoName', key: 'wOrWoName'},
                {name: 'gender', key: 'gender'},
            ]
        },
        'msg': {
            // woModal: true,
            url: '/message',
            // admin_url: '/users',
            top_filters: [
                {
                    key: 'msgType',
                    title: 'msgType',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'msg',
                        name: 'msg'
                    }, {
                        name: 'system',
                        value: 'system'
                    }
                    ]
                }, {
                    key: 'status',
                    title: 'msgType',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'error',
                        name: 'error'
                    }, {
                        name: 'wait',
                        value: 'wait'
                    }, {
                        name: 'sent',
                        value: 'sent'
                    }
                    ]
                }, {
                    key: 'msgSubType',
                    title: 'msgSubType',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'apply',
                        name: 'apply'
                    }, {
                        name: 'task',
                        value: 'task'
                    }
                    ]
                }, {
                    key: 'channel',
                    title: 'channel',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'hh',
                        name: 'hh'
                    }, {
                        value: 'tg',
                        name: 'tg'
                    }, {
                        value: 'email',
                        name: 'email'
                    }, {
                        value: 'custom',
                        name: 'custom'
                    },
                    ]
                },
            ],
            edit: [
                {name: 'user', key: 'user', type: 'input', size: 12},
                {name: 'to', key: 'to', type: 'input'},
                {name: 'msg', key: 'msg', type: 'textarea'},
                {name: 'msgType', key: 'msgType', type: 'input'},
                {name: 'status', key: 'status', type: 'input'},
                {name: 'channel', key: 'channel', type: 'input'},
                {
                    size: 12,
                    Component(v) {
                        console.log("qqqqq vvvvvvvvvvv", v);
                        let {item, onChange} = v;

                        function resend(cb, ecb) {
                            global.http.get('/resend/' + item._id).then(r => {
                                console.log("qqqqq rrrrrrrrrrrrrrr", r);
                                onChange && onChange(r)
                                cb && cb()
                            }).catch(e => {
                                ecb && ecb()
                            })
                        }

                        return <Button onClick={resend}>
                            Resend Delivered: {item.sentCount || 0} Attempts: {item.sentCountAttempts || 0}</Button>
                    }
                },
                {
                    size: 12, Component: ({item}) => {
                        return <pre>{JSON.stringify(item, null, 4)}</pre>
                    }
                },
            ],
            tabs: [
                {name: '_id', key: '_id'},
                {name: 'status', key: 'status'},
                {name: 'user', key: 'user'},
                {name: 'to', key: 'to'},
                {name: 'msgType', key: 'msgType'},
                {name: 'msgSubType', key: 'msgSubType'},
                {name: 'channel', key: 'channel'},
                {name: 'msg', key: 'msg'},
                {name: 'sendDate', key: 'sendDate'},
                {name: 'realSentDate', key: 'realSentDate'},
                {name: 'realSentInfo', key: 'realSentInfo'},

            ]
        },
        'applies': {
            // woModal: true,
            url: '/apply',
            // admin_url: '/users',
            edit: [
                {name: 'user', key: 'user', type: 'input', size: 12},
                {name: 'to', key: 'to', type: 'input'},
                {name: 'msg', key: 'msg', type: 'textarea'},
                {name: 'isSent', key: 'isSent', type: 'input'},
                {name: 'channel', key: 'channel', type: 'input'},
            ],
            tabs: [
                {name: '_id', key: '_id'},
                {name: 'jobApply', key: 'user'},
                {name: 'channel', key: 'to'},
                {name: 'msg', key: 'msg'},
                {name: 'name', key: 'name'},
                {name: 'status', key: 'status'},
                {name: 'username1', key: 'username1'},
                {name: 'username2', key: 'username2'},
                {name: 'desc', key: 'desc'},
                {name: 'url', key: 'url'},
                {name: 'msg', key: 'msg'},
                {name: 'msgId', key: 'msgId'},
                {name: 'desc', key: 'desc'},

            ]
        },
        tusers: {
            url: '/t-user',
            size: 'full',
            modalSize: 'full',
            autoSave: true,
            // admin_url: '/users',

            edit: [
                {name: 'name', key: 'name'},
                {name: 'engName', key: 'engName'},
                {name: 'gender', key: 'gender', type: 'select', items: ['', 'm', 'w']},

                // {name: 'socialUserName', key: 'socialName'},
                // {name: 'tgFirstName', key: 'tgFirstName'},

                // {name: 'tgUsername', key: 'tgUsername'},
                // {name: 'tgId', key: 'tgId'},
                // {name: 'img', key: 'img', type: 'uploader'},
                // {name: 'details', key: 'details', type: 'textarea'},
                // {component: StackPicker},
                {name: 'RU', key: 'lng.ru', type: 'checkbox', size: 1},
                {name: 'EN', key: 'lng.en', type: 'checkbox', size: 1},
                {name: 'status', key: 'status', type: 'select', items: comp_statuses},
                {path: 'StackSelector', size: 12},
                {path: 'ProfileImgUpload', key: 'img', size: 12},

                // {name: 'isActive', key: 'isActive', type: 'checkbox'},
                {type: 'HR', size: 12},
                {
                    path: 'CredsComp',
                    channel: 'hh',
                    size: 4
                }, {
                    path: 'CredsComp',
                    channel: 'tg',
                    size: 4,
                }, {
                    path: 'CredsComp',
                    channel: 'email',
                    size: 4
                },
                {type: 'HR', size: 12},

                {path: 'RU_CV', key: 'ru_cv', size: 6, title: 'RU CV'},
                {path: 'RU_CV', key: 'en_cv', size: 6, title: 'EN CV'},
                {type: 'HR', size: 12},
                TemplateProfile('applies.tg', 'TG'),
                TemplateProfile('applies.hh', 'HH'),
                TemplateProfile('applies.email', 'Email'),
                {type: 'HR', size: 12},


            ],
            tabs: [
                {name: 'name', key: 'name'},
                {
                    name: 'status', key: 'status'
                },  {
                    name: 'stack', key: 'stack'
                },
                // {name: 'info_tg', key: 'info_tg', type: 'pre'},
                // {name: 'info_hh', key: 'info_hh', type: 'pre'},
                // {name: 'info_email', key: 'info_email', type: 'pre'},
            ]
        },
        pages: {
            woModal: true,
            url: '/page',
            // admin_url: '/users',
            edit: [
                // {name: 'name', key: 'name'},
                {name: 'name', key: 'name'},
                {name: 'url', key: 'url'},
                {name: 'status', key: 'status', type: 'select', items: ['', 'active', 'unactive']},
                {name: 'channel', key: 'type', type: 'select', items: ['', 'tg', 'hh', 'fb']},
                {name: 'lastParse', key: 'lastParse'},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'channel', key: 'channel'},
                {name: 'err', key: 'err'},
                {name: 'url', key: 'url'},
                {name: 'status', key: 'status'},
                {name: 'type', key: 'type'},
                {name: 'lastParse', key: 'lastParse'},
                {name: '_id', key: '_id'},
            ]
        },
        stack: {
            // woModal: true,
            url: '/stack',
            // admin_url: '/users',
            defsize: 6,
            edit: [
                {name: 'isGeneral', key: 'isGeneral', type: 'checkbox', size: 1},
                {name: 'isTitle', key: 'isTitle', type: 'checkbox', size: 1},

                {type: 'HR', size: 12},
                {name: 'name', key: 'name', size: 6},
                {name: 'status', key: 'status', type: 'select', items: comp_statuses, size: 6},
                {type: 'HR', size: 12},
                {name: 'good', key: 'good', type: 'textarea', size: 6},
                {name: 'bad', key: 'bad', type: 'textarea', size: 6},
                {name: 'norm', key: 'norm', type: 'textarea', size: 6},
                {type: 'HR', size: 12},
            ],
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'isGeneral', key: 'isGeneral'},
                {name: 'status', key: 'status'},
                {name: 'good', key: 'good'},
                {name: 'bad', key: 'bad'},
                {name: 'norm', key: 'norm'},
            ]
        },  interview: {
            // woModal: true,
            url: '/interview',
            // admin_url: '/users',
            defsize: 6,
            edit: interviewFields,
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'type', key: 'type'},
                {name: 'tuser', key: 'tuser'},
                {name: 'dialog', key: 'dialog'},
                {name: 'time', key: 'time'},
                {name: 'date', key: 'date'},
                {name: 'cd', key: 'date'},
            ]
        },offer: {
            // woModal: true,
            url: '/offer',
            // admin_url: '/users',
            defsize: 6,
            edit: offerFields,
            tabs: [
                {name: 'name', key: 'name'},
                {name: 'tuser', key: 'tuser'},
                {name: 'dialog', key: 'dialog'},
                {name: 'date', key: 'date'},
            ]
        },
        posts2: {
            url: '/post',
            woModal: true,
            modalSize: 'full',
            autoSave: true,

            edit: [
                // {path: 'portfolio/PortfolioDetails', size: 12}
                {
                    size: 12,
                    name: 'href', key: 'href', Component: ({item}) => {
                        return <div onClick={(e) => {
                        }}>
                            {item.type} #{item._id}
                            <pre>{item.desc}</pre>
                        </div>
                    }
                },
            ],
            tabs: [
                {
                    name: 'desc', key: 'desc', component: ({item}) => {
                        console.log("qqqqq item", item);
                        return <div onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation();
                            return null;
                        }}>
                            {item.type} #{item._id}
                            <pre>{item.desc}</pre>
                        </div>
                    }
                },

            ]
        },
        parse: {
            url: '/parse-short',
            // woModal: true,
            modalSize: 'full',
            autoSave: true,
            per_page: 5,
            top_filters: [
                {
                    key: 'status',
                    title: 'status',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'error',
                        name: 'error'
                    }, {
                        name: 'ok',
                        value: 'ok'
                    }
                    ]
                }, {
                    key: 'channel',
                    title: 'channel',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: 'tg',
                        name: 'tg'
                    }, {
                        name: 'hh',
                        value: 'hh'
                    }
                    ]
                }, {
                    key: 'newItems',
                    title: 'newItems',
                    def_name: 'Все',
                    def_value: '',
                    arr: [{
                        value: {$gte: 1},
                        name: 'new'
                    }
                    ]
                }
            ],
            edit: [
                // {path: 'portfolio/PortfolioDetails', size: 12}
                {name: 'sourceUrl', key: 'sourceUrl', size: 8},
                {name: 'sourceUrl', key: 'sourceUrl', size: 6},
                {
                    Component({item}) {
                        return <pre>{JSON.stringify({...item, serverRes: '<Object Below>'}, null, 4)}</pre>
                    }, size: 12
                },
                {
                    path: 'ParseDetails',
                    // Component: Comp,
                size: 12
                }
            ],
            tabs: [
                {name: 'channel', key: 'channel'},
                {name: 'items', key: 'items'},
                {name: 'newItems', key: 'newItems'},
                {name: 'status', key: 'status'},
                {name: 'sourceUrl', key: 'sourceUrl'},
                {name: 'cd', key: 'cd'},
                {name: '_id', key: '_id'},
            ]
        },
    }
}


let admin_urls = to_url_arr(global.CONFIG.urls);
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                path: "profile",
                element: Loader('Profile')()
            },
            {
                path: 'posts',
                element: <Jobs/>
            }, {
                path: 'posts-by-users',
                element: <JobsByUsers/>
            }, {
                path: 'chat',
                element: <Chat/>
            }, {
                path: 'stats',
                element: <Stats/>
            },
            // {

            //     path: 'portfolio',
            //     element: Loader('portfolio/Portfolio')(),
            //     children: [{
            //         path: 'main',
            //         element: Loader('portfolio/PortfolioMain')()
            //     }]
            // }
        ].concat(admin_urls),
    }, {
        path: '404',
        element: <div>404</div>
    }, {
        path: 'login',
        element: <Login/>
    }, {
        path: 'dialog-hash/:_id',
        element: <PreviewChatDialogManual/>
    }
]);


function Loader(path) {

    function def() {
        return function (props) {
            return <Skeleton label={path}></Skeleton>
        }
    }

    try {
        let _path = './' + path.replace(".js", '').replace('./', '').replace(/^\//gi, '') + '.js';

        if (files.indexOf(_path) > -1) {
            let Comp = require('./comps/' + path).default
            return function (props) {
                return <Comp props={props}></Comp>
            }
        } else {
            console.log('*........ ## AA FALSE', files);
            return def()

        }

    } catch (e) {
        console.log('*........ ## root eee', e);
        return def()
    }
}

global.Loader = Loader;

function Root() {

    let location = useLocation();
    const navigate = useNavigate();

    global.navigate = navigate;
    global.redirect = redirect;

    React.useEffect(() => {
        // console.log('*........ ## location changed');
    }, [location]);

    let path = /team/gi.test(window.location.pathname)
        ? 'Layouts/Layout2'
        : 'Layouts/Layout1';
    let Item = Loader(path);

    if (window.location.pathname == '/') {
        setTimeout(() => {
            navigate(defUrl)
        }, 100)
    }

    return <div>
        <WS></WS>
        <Item></Item>
    </div>
}


createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);

reportWebVitals();


function Team(props) {
    return <div>Commented</div>
}
