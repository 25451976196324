import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";
import Textarea from "../../libs/Textarea";
import Button from "../../libs/Button";


function Layout2(props) {
  let [templates, setTemplates] = useState([]);

  useEffect(() =>{
    global.http.get('/chat-templates', {per_page: 100, sort: '_id'}).then(r =>{
      setTemplates(r.items)
    })
  }, [])
  let onChange = props.onChange
  console.log('*........ ## ROOT RENDER', props);

  return <div>
    Suggest messages
    <div></div>
    {(templates || []).map((it, ind) => {
        return (<div key={ind} onClick={() =>{
          onChange(it.desc)
        }}>
          <Textarea value={it.desc}
              minRows={1}
              maxRows={5}
              onChange={(v) => {
            let _it = {...it, desc: v}
            templates[ind] = _it;
            setTemplates([...templates])
            global.http.put('/chat-templates', _it).then(r =>{
              console.log("qqqqq saveddddd!!!!!", );
            })
            onChange(v)
          }}></Textarea>
        </div>)
    })}
    <div>
      <Button onClick={(scb) => {
        global.http.post('/chat-templates', {}).then(r =>{
          setTemplates([...templates, r])
          scb && scb()
        })
      }}>Add</Button>
    </div>
    
    
  </div>
}

export default Layout2
