import React, {useEffect, useState} from 'react';
import Input from "../../libs/Input";
import Button from "../../libs/Button";
import {getActiveClass} from "./Apply";

function HHTGInfo(props) {

    let [v, setV] = useState(props.value || '')
    let [vName, setVName] = useState(props.name || '')
    let {
        channel, value, name, customData, rerenderName,
        onChangeJob, job0,
        setChannel, activeChannel, resetItemsHistory, getActiveClass
    } = props;

    let linksObj = job0?.linksObj;

    useEffect(() => {
        setV(value)
    }, [value])
    useEffect(() => {
        setVName(name)
    }, [name])

    let isChanged = v != props.value || vName != props.name;

    return <div className={"col-xs-3" + getActiveClass(activeChannel, channel)} onClick={() => {
        setChannel(channel)
    }}>
        <Input type="text" value={v}
               disabled={props.disabledNameEdit}
               placeholder={channel + ' id'}
               onChange={(v) => {
                   setV(v)
               }}
        />
        <Input type="text" value={vName}
               placeholder={`${channel} имя`}
               onChange={(v) => {
                   setVName(v)
               }}
        />
        {isChanged && <Button size={'xs'} color={4} onClick={(scb) => {
            scb && scb();
            props.onChange && props.onChange({value: v, name: vName});
        }}>Изм. инфо</Button>}
    </div>

}


export default HHTGInfo;