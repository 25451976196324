import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import Input from "../../libs/Input";


let client_id = 'P1A4M15UK0RQNEEA0F83HQ7O68OP820CQJ6GRPKS2DO9EC7PSNEN3730O04O5H0T';
let client_secret = 'ME9E7FBD9HU2S0F2K7JC394D4393QN43C121LU0RUO2PJ9O40MFI22FMSS73J8H5';
let redirect_uri = 'https://itrum.ru/contacts';

function Layout2(props) {
    let [redirectLink, setRedirectLink] = useState('')
    let [isRefresh, setIsRefresh] = useState(true)
    // let [items, setItems] = useState([])
    let [items, setItems] = useState([])
    const [info, setInfo] = useState({});

    console.log('*........ ## ROOT RENDERGetHHProfiles', props);
    let {item} = props;

    useEffect(() => {
        item?._id && getCV()
    }, [item?._id])

    function getCV(scb) {
        global.http.get("/c-v", {filter: {TUser: item._id}}).then(r => {
            setItems(r.items)
        })
    }

    function syncCV(scb, ecb) {
        global.http.get('/sync-cvs', {user: item._id}).then(r => {
            console.log("qqqqq rrrrrr", r);
            scb && scb()
            setItems(r.items)
        }).catch(ecb)
    }

    function refreshToken(cb) {
        cb && cb();
        setIsRefresh(true)
    }

    function getInfo (cb) {
        global.http.get('/proxy/hh/me', {user: props?.item?._id}).then(r => {
            setInfo(r)
            cb && cb();
        })
    }

    let link = `https://hh.ru/oauth/authorize?state=test_state&response_type=code&client_id=P1A4M15UK0RQNEEA0F83HQ7O68OP820CQJ6GRPKS2DO9EC7PSNEN3730O04O5H0T&redirect_uri=https://itrum.ru/contacts`

    return <div>

        <div>
            <Button onClick={(cb) => {
                refreshToken(cb)
            }}>Обновить токен</Button>
        </div>
        {isRefresh && <>
            <div>1. Перейдите по ссылке в нужном браузере (где залогинен ХХ агента)<div></div><a target="_blank" className={'ib'} style={{wordBreak: 'break-all'}} href={link}>{link}</a></div>
            <div>2. Скопируйте ссылку (страница контактов итрум)</div>
            <div>
                <Input value={redirectLink} onChange={v => setRedirectLink(v)}/>
                <Button onClick={(cb) => {
                    let url = `/confirm-hh/${item?._id}?` + redirectLink.split('?').at(-1);
                    global.http.get(url).then(r => {
                        console.log("qqqqq rrrr", r);
                    })

                    console.log("qqqqq 444");
                    cb && cb()

                }}>Подтвердить Ссылку</Button>
            </div>
            <div><hr/></div>
        </>}
        <div></div>
        <Button onClick={syncCV}>Load Resumes</Button>
        {(items || []).map((item, ind) => {
            let it = item?.data;
            return (<div key={ind} title={item?.hhId}>
                <strong style={{fontSize: '16px', padding: '8px 0 0 0', display: 'block'}}>{it?.title || it?.name}</strong>
                <div>
                    {(it?.skill_set || []).map((it, ind) => {
                        return (<small key={ind} style={{padding: '5px', marginRight: '1px', marginTop: '1px'}}
                                       className={'label label-info ib'}>
                            {it?.name || it}
                        </small>)
                    })}
                </div>
            </div>)
        })}
        <div>
            <hr/>
        </div>
        <Button onClick={(scb) => {
            getInfo(scb)
        }}>Получить Инфо ХХ</Button>
        <pre>{JSON.stringify(info, null, 4)}</pre>

    </div>
}

export default Layout2
