import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function JobsByUsers(props) {
  console.log('*........ ## ROOT RENDER', props);

  return <div>
    <div className="row">
      <div className="col-xs-12">
        Users Selector
      </div>
      <div className="col-xs-3">
        Jobs list - Applied - waiting
      </div>
      <div className="col-xs-9">

      </div>
    </div>
  </div>
}

export default JobsByUsers
