import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Stringify from "../Stringify";
import {isActiveFn} from "./Apply";


function UserJobDetails(props) {
    console.log('*........ ## ROOT RENDER', props);

    let {it, job0, itemsHistoryObj} = props;
    if (!it) {
        return <div>Загрузка юзера ...</div>
    }
    it = it || {}
    let customData = job0?.customData

    let hhHist = (itemsHistoryObj?.hh || {})[it._id];
    let tgHist = (itemsHistoryObj?.tg || {})[it._id];
    let emailHist = (itemsHistoryObj?.email || {})[it._id];


    let badKeys = it?.sortJobObj?.BAD_KEYS || {}
    return <div>


        <hr/>
        {it?.name}
        <div>
            #{it?._id}
        </div>
        <hr/>
        <Problematics badKeys={badKeys}></Problematics>
        <hr/>
        <div>
            HH: {hhHist ? hhHist.items?.length || 0 : 'loading'}
            <div></div>
            TG: {tgHist ? tgHist.items?.length || 0 : 'loading'}
            <div></div>
            EMAIL: {emailHist ? emailHist.items?.length || 0 : 'loading'}
        </div>
        <hr/>
        <div>

            <div>
                JobLng: {customData?.lng}
            </div>
            <div>
                UserLng: {_.keys(it?.lng).filter(key => it?.lng[key]).join(', ')}
            </div>

            <div>
                <hr/>
            </div>
            <div>
                JobStack: {customData?.stack?.join(', ')}
            </div>
            <div>
                UserStack: {(it?.stack || []).join(', ')}
            </div>
            <hr/>
            <Stringify item={it?.sortJobObj}></Stringify>
        </div>
    </div>
}

export function Problematics(props) {
    let {badKeys, hideEmpty, limit} = props
    let _limit = 0;
    if (hideEmpty && !Object.keys(badKeys).length) {
        return <></>
    }
    let isLimit = () => {
        return _limit++ < (limit || 1000);
    }
    let clName = props?.className || ''
    return  <div >
        {!limit && <>Проблематики:</>}
        <div>
        {badKeys.userLngStatus && isLimit() && <div className={clName}>Язык</div>}
        {badKeys.userStackCount && isLimit() && <div className={clName}>Стэк</div>}
        {badKeys.forceStatus && isLimit() && <div className={clName}>Форс статус аплая</div>}
        {!props.woAlready && badKeys.messagesCount && isLimit() && <div className={clName}>Уже есть сообщения</div>}
        {badKeys.userChannelCredsStatus && isLimit() && <div className={clName}>Юзер Креды</div>}
        {badKeys.userStatus && isLimit() && <div className={clName}>Юзер Статус</div>}
        </div>
    </div>
}

export default UserJobDetails
