import React, {useState, useRef, useEffect} from 'react';
import './apply.css';
import _ from 'underscore';
import moment from 'moment';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";
import Templates from "./Templates";
import GroupButton from "../../libs/GroupButton/GroupButton";
import StackSelector from "../StackSelector";
import Input from "../../libs/Input";
import Msgs from "./Msgs";
import Button from "../../libs/Button";
import Textarea from "../../libs/Textarea";
import ChatDetails from "./ChatDetails";
import Select from "../../libs/Select";
import Stringify from "../Stringify";
import UserJobDetails, {Problematics} from "./UserJobDetails";
import ProfileImg from "../ProfileImg";
import SelUserMsgs from "./SelUserMsgs";
import HHTGInfo from "./HHTGInfo";

let itemsHistoryObj = {}
window.itemsHistoryObj = itemsHistoryObj;

export async function getActiveTUsers(cb) {

    let {activeTUsersCache} = window
    let cd = new Date().getTime();
    if (cd - (activeTUsersCache?.cd || 0) < 1000 * 60 * 30) {
        return cb && cb(activeTUsersCache.r, activeTUsersCache.r1);
    }
    let [r, r1] = await Promise.all([
        global.http.get('/t-user', {per_page: 10000, filter: {status: 'active'}},),
        global.http.get('/t-creds', {per_page: 10000, filter: {}},)
    ])

    window.activeTUsersCache = {
        r: r.items,
        r1: r1.items,
        cd,
    }
    cb && cb(r.items, r1.items)
}

export function getActiveClass(v1, v2) {
    return v1 == v2 ? ' selected ' : ' '
}

export let isActiveFn = (hhTgHist) => /active/gi.test(hhTgHist?.status)
export let getMessagesCount = (userId, channel) => {
    return ((itemsHistoryObj[channel] || {})[userId] || {})?.items?.length
}
export let isBadFn = (user, manualStatus) => (user?.sortJobObj?.anyStatus === 'bad' && (manualStatus !== 'active_manual')) || (manualStatus == 'passive_manual')
let getManualStatus = (job, userId, channel) => {
    return ((((job?.history || {}).byUsers || {})[userId] || {})[channel] || {}).status
}

function Layout2(props) {
    let filter = props?.chatDetailsProps?.filter || {};
    let job0 = props?.job0 || {data: {}}

    let [cd, setCd] = useState(0)
    let [applyDelta, setApplyDelta] = useState(30)
    let [applyRnd, setApplyRnd] = useState(10)
    let [credObj, setCredObj] = useState({})
    let [users, setUsers] = useState([])
    let [selUserDetails, setSelUserDetails] = useState(false)
    let [activeChannel, setChannel] = useState(filter?.channel || job0?.channel || job0?.type || 'hh')
    let [selectedSkills, setSelectedSkills] = useState({})
    let [isTemplateModal, setIsTemplateModal] = useState(false)
    let [selUserId, setSelUserId] = useState()
    let [rerenderMsgs, setRerenderMsgs] = useState(new Date().getTime())
    let [selectedUserInd, setSelectedUserInd] = useState(0)
    // let selUser = selUserId;// || users[selectedUserInd];

    let saleId = global?.user?.get_id();

    // let {channel} = job0 || {};
    let job0data = job0?.data || {}

    let isMenuOpen = filter?.isMenuOpen;
    let customData = job0?.customData || {};

    job0.history = job0.history || {}
    job0.history.byUsers = job0.history.byUsers || {}
    let {history} = job0;
    let channel = activeChannel;

    let byUsers = history.byUsers;

    let vacancyId = job0data.id;
    let key_skills = job0data.key_skills;
    job0.activeTg = job0.activeTg || (job0?.linksObj?.tg || [])[0] || (job0?.linksObj?.tgAuto || [])[0] //? 'SergeyTitoov' : ''

    let {activeHhId, activeTg, activeEmail, linksObj} = job0 || {};

    let links = useRef({})
    window.links = links;
    let jobId = job0?._id;

    if (!job0?.customData?.stack?.length && filter?.stack?.length) {
        job0.customData = job0.customData || {}
        job0.customData.stack = filter.stack;
    }
    console.log('job0 stackkkkkkkkkkkkkkkkk', job0.stack, filter.stack)


    useEffect(() => {
        setTimeout(() => {
            setCd(new Date().getTime())
        }, 100)
    }, [channel])
    // useEffect(() => {
    //     if (job0?.channel == 'hh' && vacancyId && !activeHhId) {
    //         onChangeJob({activeHhId: vacancyId})
    //     }
    // }, [vacancyId])

    useEffect(() => {
        let skills = {}
        _.each(key_skills, (item, ind) => {
            skills[item.name] = {status: 'ok'};
        })
        setSelectedSkills(skills)

    }, [(key_skills || []).map(it => it.name).join('__')])


    useEffect(() => {
        getActiveTUsers((items, credItems) => {
            let credObj = credItems.reduce((acc, it) => {
                acc[it.tuser] = acc[it.tuser] || {};
                acc[it.tuser][it.channel] = it;
                return acc;
            }, {});

            setCredObj(credObj)

            try {
                users = _.shuffle(filterSort(items, null, credObj));
            } catch(e) {
                console.log("qqqqq get active TUSERS eeeeeeeeeeeeeeeeeeeeeeeeeee", e);
            }


            // setUsers(sortUsers(items, credObj))



            setUsers(users)
        })

        if (!jobId) {
            return;
        }
        console.log("qqqqq EFFECT           jobId ::: START", jobId);
        global.http.get('/set-reserved', {
            _id: jobId,
            userId: saleId,
            name: global?.user?.get_info().username
        }).then(r => {
            console.log("qqqqq EFFECT           jobId ::: RRRRRRRRRRRRRRRRRRR", r);

        })

        return () => {
            console.log("qqqqq EFFECT           jobId ::: FINISH", jobId);
            onChangeJob({reservedData: {}})
            global.http.get('/set-unreserved', {
                _id: jobId,
                userId: saleId
            }).then(r => {
            })
        }

    }, [])


    function sortUsers(_users, _credObj = credObj) {
        users = filterSort(_users || users, null, _credObj);
        return users;
    }

    // console.log("qqqqq history hcange history", history, activeChannel);

    useEffect(() => {
        itemsHistoryObj = {}
    }, [job0?._id])
    useEffect(() => {
        setUsers((users) => sortUsers(users))
    }, [JSON.stringify(history), JSON.stringify(itemsHistoryObj), activeChannel])

    function onChangeJob(v, type) {
        console.log("qqqqq onChange job", v, type);
        props?.chatDetailsProps?.onChange && props?.chatDetailsProps?.onChange({...v})
    }


    let setHistory = (v) => {
        console.log("qqqqq  set History",);
        onChangeJob({history: {...history, ...v || {}}})
    }
    let setHistoryByUsers = (v, userId) => {
        // console.log("qqqqq vvvvvvvvv", v, userId);
        byUsers[userId] = {...byUsers[userId] || {}, ...v};
        setHistory({byUsers})
        // onChangeJob({history: {...history || {}, byUsers}})
    }

    function resetItemsHistory(v) {
        // console.log("qqqqq RESET ITEMS HISTORY COMMENT", );
        itemsHistoryObj[activeChannel] = {}

    }

    function filterSort(_users, _customData, _credObj) {
        _users = _users || users
        _customData = _customData || job0?.customData;
        _credObj = _credObj || credObj;
        let jobLng = _customData?.lng || (job0?.lngs || [])[0]
        let jobStackObj = (_customData?.stack || []).reduce((acc, it) => {
            return {...acc, [it]: true}
        }, {});

        // console.log("qqqqq filterSortfilterSortfilterSort", jobLng, jobStackObj, users);
        let stats = {}
        _.each(_users, (user, ind) => {
            let userLngsObj = user.lng || {};
            let userStack = user.stack;
            let stackCount = userStack.reduce((acc, it) => {
                return acc + (jobStackObj[it] ? 1 : 0)
            }, 0)
            let lngStatus = userLngsObj[jobLng]
            let anyStatus = lngStatus && stackCount ? 'good' : 'bad';
            // console.log("qqqqq filterSortfilterSortfilterSort user", {lngStatus, anyStatus}, user?._id, count);

            let isNewConversation = !getMessagesCount(user?._id, channel)
            let userCreds = _credObj[user?._id] || {};
            let toNotUnActive = (st, details) => {
                return {
                    value: st || '',
                    details,
                    bool: st != 'unactive'
                }
            }
            let toActive = (st, details) => {
                return {
                    value: st,
                    details,
                    bool: st == 'active'
                }
            }
            let toBool = (v1, details) => {
                return {
                    value: v1,
                    details,
                    bool: Boolean(v1)
                }
            }
            let toCountNumEmpty = (v1, details) => {
                let v = +v1 || 0
                return {
                    value: v1,
                    details,
                    isEmpty: !v1 && v1 != 0,
                    bool: v < 1
                }
            }
            let toNum = (v1, details) => {
                return {
                    value: v1,
                    details,
                    bool: +v1 >= 1
                }
            }

            let manualStatus = getManualStatus(job0, user?._id, channel);

            let v = {
                FORCE_MANUAL_STATUS: {forceValue: manualStatus},

                userStatus: toNotUnActive(user.status),
                // userCredsChannel: userCreds[channel],
                userChannelCredsStatus: toActive((userCreds[channel] || {}).status),
                userLngStatus: toBool(userLngsObj[jobLng], Object.keys(userLngsObj).filter(it => userLngsObj[it]).join(', ')),
                userStackCount: toNum(stackCount),
                messagesCount: toCountNumEmpty(getMessagesCount(user?._id, channel)),
            }

            let forceStatus;
            let isOk = true
            let badKeys = {}
            _.each(v, (item, key) => {
                if (!/[a-z]/g.test(key)) {
                    forceStatus = /active|unactive/gi.test(item.forceValue) ? item.forceValue : forceStatus;
                } else {
                    if (!item.bool) {
                        badKeys[key] = true
                    }
                    isOk = isOk && item?.bool;
                }


            })

            // v.isApplyGoodFinal = Boolean(v.isApplyArr.reduce((acc, it) => acc + (it ? 1 : 0), 0))
            // stats.anyStatus = (stats?.anyStatus || 0) + (v.anyStatus == 'good' ? 1 : 0)
            // stats.isApplyGoodFinal = (stats?.isApplyGoodFinal || 0) + (v.isApplyGoodFinal ? 1 : 0)
            // stats.newConversations = (stats?.newConversations || 0) + (v.isNewConversation ? 1 : 0)

            let normalStatus = isOk ? "active" : "unactive"
            let finalStatus = forceStatus || normalStatus;
            if (forceStatus === 'unactive') {
                badKeys.forceStatus = true;
            }
            _users[ind].sortJobObj = {
                ...v, BAD_KEYS: badKeys,
                // manualStatus,

                forceStatus: forceStatus || 'auto', normalStatus,
                finalStatus
            };


            console.log("qqqqq statssssssssssssss", _users[ind], stats);
        })

        // stats.customData = customData;
        // onUpdateStats(stats)


        return _users
    }

    function onUpdateStats(stats) {
        if (!job0) {
            return;
        }
        if (JSON.stringify(job0.stats) == JSON.stringify(stats)) {
            return;
        }
        job0?._id && global.http.get('/set-stats', {_id: job0?._id, stats}).then()
    }


    // useEffect(() => {
    //
    // }, [])
    let fullAll = (cb, arr) => {
        let ind = -1;

        function iter() {
            let it = arr[++ind];
            let _cb = () => {
                setTimeout(() => {
                    iter()
                }, 100)
            }
            if (it) {
                if (it == 'time0') {
                    setTime0(_cb)
                } else if (it === 'time') {
                    setTime1(_cb)
                } else if (it == 'tags' || it == 'tag') {
                    tagsAll(_cb)
                } else if (it === 'apply') {
                    applyAll(_cb)
                } else {
                    _cb()
                }
            } else {
                cb && cb()
            }
        }

        iter();

    }
    let applyAll = (cb) => {
        cb && cb();
        window.$('[data-apply="active"]').click()
        setTimeout(() => {
            props.onClose && props.onClose();
        }, 1000)
    }
    let tagsAll = (cb) => {
        cb && cb();
        window.$('[data-set-tags="active"]').click()
    }
    let textAll = (cb) => {
        cb && cb();
        window.$('[data-set-text="active"]').click()
    }
    let reloadAll = (cb) => {
        cb && cb();
        window.$('[data-reload="active"]').click()
    }
    let setTime1 = (cb) => {
        cb && cb();
        let historyByUser = {...(job0?.history?.byUsers || {})}
        // let delays =
        let delay = 0;
        let maxTime = new Date()
        maxTime.setHours(18)
        maxTime.setMinutes(55)
        maxTime = maxTime.getTime();

        let nextDayTime = new Date();
        nextDayTime.setHours(9)
        nextDayTime.setMinutes(44)
        nextDayTime = nextDayTime.getTime() + 24 * 3600 * 1000;

        let dayOffDelta;
        let maxDelta = Math.round((maxTime - new Date().getTime()) / 1000);

        let getOneDeltaTime = () => {
            let rnd = Math.random() * (+applyRnd || 0);
            console.log("qqqqq Math.random() * applyRndMath.random() * applyRnd", rnd, applyDelta);
            return Math.round((rnd + (+applyDelta || 0)) * 60);
        }
        let curDelta = 0;
        _.each((userGroups.active), (item, ind) => {
            let delta = getOneDeltaTime();

            curDelta = curDelta + (ind > 0 ? delta : 0);
            historyByUser[item._id] ??= {}
            historyByUser[item._id][activeChannel] ??= {}
            if (curDelta > maxDelta) {
                dayOffDelta = dayOffDelta || Math.round((nextDayTime - new Date().getTime()) / 1000) + delta - curDelta;
                historyByUser[item._id][activeChannel].delay = dayOffDelta + curDelta;
            } else {
                historyByUser[item._id][activeChannel].delay = curDelta
            }
        })

        // setHistoryByUsers(historyByUser)
        setHistory({byUsers: {...historyByUser}})
        // let historyByUserChannel = historyByUser[activeChannel] || {}

        console.log("qqqqq set TIME PALITRA", userGroups);
    }
    let setTime0 = (cb) => {
        cb && cb();
        let historyByUser = {...(job0?.history?.byUsers || {})}
        _.each((userGroups.active), (item, ind) => {

            historyByUser[item._id] ??= {}
            historyByUser[item._id][activeChannel] ??= {}
            historyByUser[item._id][activeChannel].delay = 0

        })
        setHistory({byUsers: {...historyByUser}})
        console.log("qqqqq set TIME PALITRA", userGroups);
    }

    let rerenderName = () => setRerenderMsgs(v => ++v)

    useEffect(() => {
        setUsers(sortUsers())
    }, [JSON.stringify(job0?.customData)])

    window.job0 = job0;
    let userGroups = {
        active: [],
        applied: [],
        unactive: []
    };
    _.each(users, (it, ind) => {
        let count = it?.sortJobObj?.messagesCount?.value;
        let sortJobObj = it?.sortJobObj || {}

        history = history || {}
        let activeHist = ((itemsHistoryObj || {})[channel] || {})[it._id];
        let historyByUser = (job0?.history?.byUsers || {})[it._id] || {};
        let historyByUserChannel = historyByUser[activeChannel] || {}
        let isBad = it?.sortJobObj?.finalStatus == 'unactive'
        let badKeys = it?.sortJobObj?.BAD_KEYS || {}
        let problems = Object.keys(badKeys).length;
        let isFinalActive = it?.sortJobObj?.finalStatus == 'active'


        let key = count ? 'applied' : isFinalActive ? sortJobObj?.messagesCount?.isEmpty ? 'pending' : 'active' : 'unactive';


        console.log("qqqqq aaaaaaaaaaaaaaaaaaaaaxxxxxxxxxxxxxxxxxxxxxx", it.name,
            JSON.stringify(it?.sortJobObj || {}, null, 2));
        userGroups[key] ??= []
        userGroups[key].push(it)
    })
    let selId = selUserId || (userGroups?.active || [])[0]?._id// || (users[0] || {})._id;
    let selUser = users.find(it => it._id == selId) || (userGroups?.active || {})[0];
    let selUserIdBased = users.find(it => it._id == selUserId)
    let HHTGProps = {
        getActiveClass,
        setChannel,
        job0,
        onChangeJob,
        rerenderName,
        customData,
        activeChannel,
        resetItemsHistory
    }

    // userGroups.active = _.shuffle(userGroups.active)

    useEffect(() => {
        console.log("qqqqq UPDATE PARENT ITEMS HISTORY OBJ", itemsHistoryObj.hh);
    }, [JSON.stringify(itemsHistoryObj)])

    console.log("qqqqq itemsHistoryObjitemsHistoryObjitemsHistoryObj", userGroups, users);
    return <div className={'row'}>

        <WrongApply job={job0}></WrongApply>
        <MyModal
            isOpen={isTemplateModal}
            onClose={() => setIsTemplateModal(false)}
        >
            <Templates onClick={(v) => {
                setIsTemplateModal(false)
                // console.log("qqqqq select template", v);
            }}></Templates>
        </MyModal>

        {/*<Button onClick={(cb) => {*/}
        {/*    filterSort()*/}
        {/*    cb && cb()*/}
        {/*}}>FilterSort</Button>*/}
        <div onClick={() => {
            // console.log("qqqqq on clickckccccc", props);
            props?.chatDetailsProps?.onChangeFilter && props?.chatDetailsProps?.onChangeFilter({isMenuOpen: !isMenuOpen})
        }}>
            <div className="fa fa-bars" style={{marginLeft: '15px'}}></div>
        </div>
        {isMenuOpen && <div className="col-xs-5">
            {/*<SklilsWrap*/}
            {/*    onChange={(key, value) => {*/}
            {/*        // console.log("qqqqq key value", key, value);*/}
            {/*        setSelectedSkills({...selectedSkills, [key]: {status: value}})*/}
            {/*    }}*/}
            {/*    key_skills={key_skills} selectedSkills={selectedSkills}></SklilsWrap>*/}
            <ChatDetails {...props.chatDetailsProps || {}}
                onClose={props.onClose}
            ></ChatDetails>
        </div>}
        <div className={"usersWrap modalStickyWrap rel " + (isMenuOpen ? 'col-xs-7' : 'col-xs-12')}>
            <div className="stickyItemsModal2">
                <div>

                    {/*<Input*/}
                    {/*    autoFocus={true}*/}
                    {/*    value={history?.sortK} onChange={sortK => {*/}
                    {/*    setHistory({sortK})*/}

                    {/*}} placeholder={'SortK for users'}></Input>*/}
                </div>

                <MyModal
                    isOpen={selUserDetails}
                    onClose={setSelUserDetails}
                >
                    <UserJobDetails
                        job0={job0}
                        itemsHistoryObj={itemsHistoryObj}
                        it={selUserDetails}></UserJobDetails>
                </MyModal>
                {(['active', 'unactive', 'applied', 'pending'] || []).map((key, ind) => {
                    let arr = userGroups[key]
                    // if (!arr || !arr.length) {
                    //     return null;
                    // }
                    return (<div key={ind} className={'col-xs-3'}>
                        <div>
                            <strong>
                                {key}
                            </strong>
                        </div>

                        {(arr || []).map((it, ind) => {
                            history = history || {}
                            let activeHist = ((itemsHistoryObj || {})[channel] || {})[it._id];
                            let historyByUser = (job0?.history?.byUsers || {})[it._id] || {};
                            let historyByUserChannel = historyByUser[activeChannel] || {}
                            let isBad = it?.sortJobObj?.finalStatus == 'unactive'
                            let badKeys = it?.sortJobObj?.BAD_KEYS || {}
                            let problems = Object.keys(badKeys).length;
                            let isFinalActive = it?.sortJobObj?.finalStatus == 'active'

                            return (<div key={'byUser' + it._id}
                                         data-user-group-key={key}
                                // className={'ib2 user-apply2  ' + getActiveClass(selId, it._id) + (!isFinalActive ? ' o5' : '')}
                                         className={'rel ib2 user-apply2  '}
                                // style={{maxWidth: '75px'}}
                                         title={it.name || '---'}
                                         onClick={() => {
                                             setSelUserId(it._id)
                                         }}
                            >

                                <div className={'rel'}>

                                    <div className={'ellipse'}>
                                        {/*{it.img && <ProfileImg user={it}></ProfileImg>}*/}
                                        {/*{!it.img &&*/}
                                        {/*    <>*/}
                                        {/*        <div style={{width: '50px'}} className={'ellipses'}>{it.name}</div>*/}
                                        {/*    </>}*/}
                                        <div className={'ellipses'}>
                                            {key == 'applied' && <div className={'ib'}>
                                                <a className={'user-apply-count2'} style={{float: 'none'}}
                                                   onClick={(e) => {
                                                       setSelUserDetails(it)
                                                       e.preventDefault()
                                                       return e.stopPropagation();
                                                   }}>x{activeHist ? activeHist?.items?.length || 0 : 'loading'}</a>
                                            </div>}
                                            {it.name}</div>
                                    </div>
                                    {key == 'unactive' && !!problems && <div className={'rel'}>
                                        <Problematics badKeys={badKeys} limit={1}
                                                      className={'problematItem ellipse'}
                                                      woAlready={true}></Problematics>
                                    </div>}
                                </div>
                            </div>)
                        })}
                    </div>)
                })}

            </div>

            <div className="col-xs-12">
                <hr/>
            </div>
            <MyModal
                isOpen={selUserId && selUserIdBased}
                onClose={() => setSelUserId(null)}
            >
                {selUserIdBased && <div className={'selectedUserWrap2'}><SelUserMsgs
                    itemsHistoryObj={itemsHistoryObj}
                    channel={activeChannel}
                    credObj={credObj[selUserIdBased?._id]}
                    it={selUserIdBased}
                    rerenderMsgs={rerenderMsgs}
                    selId={selUserIdBased?._id}
                    ind={-1}
                    setHistory={setHistory}
                    setHistoryByUsers={setHistoryByUsers}
                    job0={job0}
                    onChangeJob={onChangeJob}
                    history={history}
                    selectedSkills={selectedSkills}
                    links={links}
                ></SelUserMsgs>
                </div>}
            </MyModal>

            {/*<Stringify item={job0?.linksObj}></Stringify>*/}


            <div className="row input-ch-wrap">
                <WrongApply job={job0}></WrongApply>

                <div className="col-xs-12">
                    <div>
                        <Button onClick={(cb) => {
                            applyAll(cb)
                        }}>Аплай всех</Button>

                        <Button onClick={(cb) => {
                            tagsAll(cb)
                        }}>Тэги всем</Button>
                        <Button onClick={(cb) => {
                            textAll(cb)
                        }}>Текста всем</Button>
                        <Button onClick={(cb) => {
                            reloadAll(cb)
                        }}>Перезагрузка всех</Button>
                    </div>

                    <div className="ib">
                        <div className="ib" style={{marginRight: '5px', marginTop: '22px'}}>
                            <Button onClick={(cb) => {
                                fullAll(cb, ['time', 'tag', 'apply'])
                            }}>Time + Tag + Apply</Button>
                        </div>
                        <div className="ib" style={{marginRight: '5px', marginTop: '22px'}}>
                            <Button onClick={(cb) => {
                                fullAll(cb, ['time0', 'tag', 'apply'])
                            }}>Time0 + Tag + Apply</Button>
                        </div>
                        <div className="ib" style={{width: '60px'}}>
                            <Input value={applyDelta} onChange={setApplyDelta} placeholder={'Дельта'} type={'number'}/>
                        </div>
                        <div className="ib" style={{width: '60px'}}>
                            <Input value={applyRnd} onChange={setApplyRnd} placeholder={'Ранд'} type={'number'}/>
                        </div>
                        <div className="ib" style={{marginTop: '22px'}}>
                            <Button onClick={(cb) => {
                                setTime1(cb)
                            }}>Set</Button>
                            <Button onClick={(cb) => {
                                setTime0(cb)
                            }}>Zero</Button>
                        </div>
                    </div>
                </div>


                <HHTGInfo
                    {...HHTGProps}
                    channel={'tg'}
                    value={activeTg}
                    name={customData?.tgName}
                    onChange={({value, name}) => {
                        customData.tgName = name;
                        rerenderName()
                        resetItemsHistory('tg')
                        onChangeJob({activeTg: value, customData}, 'tgId')
                    }}
                ></HHTGInfo>

                <HHTGInfo
                    {...HHTGProps}
                    channel={'hh'}
                    value={activeHhId}
                    name={customData?.hhName}
                    disabledNameEdit={true}
                    onChange={({value, name}) => {
                        customData.hhName = name;
                        rerenderName()
                        resetItemsHistory('hh')
                        onChangeJob({activeHhId: value, customData}, 'hhId')
                    }}
                ></HHTGInfo>

                <HHTGInfo
                    {...HHTGProps}
                    channel={'email'}
                    value={activeEmail}
                    name={customData?.emailName}
                    onChange={({value, name}) => {
                        customData.emailName = name;
                        rerenderName()
                        resetItemsHistory('email')
                        onChangeJob({activeEmail: value, customData}, 'email')
                    }}
                ></HHTGInfo>
                <HHTGInfo
                    {...HHTGProps}
                    channel={'custom'}
                    value={job0?.activeCustomLink}
                    name={customData?.customName}
                    onChange={({value, name}) => {
                        customData.customName = name;
                        rerenderName()
                        resetItemsHistory('custom')
                        onChangeJob({activeCustomLink: value, customData}, 'custom')
                    }}
                ></HHTGInfo>
                <div className="col-xs-6">
                    <Textarea
                        placeholder={'Comment'}
                        // woLabel={true}
                        minRows={1}
                        value={job0.comment} onChange={(comment) => {
                        onChangeJob({comment})
                    }}></Textarea>
                </div>
                <div className="col-xs-6">
                    <Textarea
                        placeholder={'Comment2'}
                        // woLabel={true}
                        minRows={1}
                        value={job0.comment2} onChange={(comment2) => {
                        onChangeJob({comment2})
                    }}></Textarea>
                </div>
            </div>


            {/*{(users || []).map((it, ind) => {*/}
            {!userGroups?.active?.length && <div className={'titleUnActive'}>Нет активных юзеров</div>}
            {([...userGroups?.active || []]).map((it, ind) => {
                console.log("qqqqq job it ititititititi", it);
                let selId = it._id;
                return (<SelUserMsgs
                    itemsHistoryObj={itemsHistoryObj}
                    channel={activeChannel}
                    credObj={credObj[it?._id]}
                    it={it}
                    selId={selId}
                    rerenderMsgs={rerenderMsgs}
                    ind={ind}
                    setHistory={setHistory}
                    setHistoryByUsers={setHistoryByUsers}
                    job0={job0}
                    onChangeJob={onChangeJob}
                    history={history}
                    selectedSkills={selectedSkills}
                    links={links}
                ></SelUserMsgs>)
            })}
            {([...userGroups?.pending || []]).map((it, ind) => {
                console.log("qqqqq job it ititititititi", it);
                let selId = it._id;
                return (<SelUserMsgs
                    isPending={true}
                    itemsHistoryObj={itemsHistoryObj}
                    channel={activeChannel}
                    credObj={credObj[it?._id]}
                    it={it}
                    selId={selId}
                    rerenderMsgs={rerenderMsgs}
                    ind={ind}
                    setHistory={setHistory}
                    setHistoryByUsers={setHistoryByUsers}
                    job0={job0}
                    onChangeJob={onChangeJob}
                    history={history}
                    selectedSkills={selectedSkills}
                    links={links}
                ></SelUserMsgs>)
            })}


        </div>
    </div>
}


export function WrongApply(props) {
    let job0 = props.job;
    let isShort = props.isShort;
    let saleId = global?.user?.get_id();
    let data = job0?.reservedData || {}
    let reserveId = job0?.reservedData?.userId;
    let isWrongReserved = reserveId && (reserveId != saleId);
    if (!isWrongReserved) {
        return null;
    }
    let time = moment(data.cd).fromNow();
    return <div className="col-xs-12 wrongApply">
        <div>Аплай в процессе отправки
            {isShort && <> {time}</>}
            {!isShort && <Stringify item={{
                user: data.name || data.userId,
                time
            }}></Stringify>}
        </div>
    </div>
}

function SklilsWrap(props) {
    let {key_skills, selectedSkills, onChange} = props;
    selectedSkills ??= {};

    return <>
        {/*<pre>{JSON.stringify(selectedSkills, null, 4)}</pre>*/}
        {(key_skills || []).map((it, ind) => {
            let curStatus = selectedSkills[it.name]?.status;
            return (<div key={ind} className={'ib skill-item ' + getActiveClass('ok', curStatus)}
                         onClick={() => {
                             onChange(it.name, curStatus == 'ok' ? 'not' : 'ok')
                         }}>
                {it.name}
            </div>)
        })}
    </>

}


export default Layout2
