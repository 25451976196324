import React, {useState, useEffect} from 'react';
import './apply.css';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";


function Layout2(props) {
    let [templates, setTemplates] = useState([])
    useEffect(() => {
        setTemplates([{
            name: 'templ1'
        }, {
            name: 'templ2'
        }])
    }, [])
    console.log('*........ ## ROOT RENDER', props);

    return <div className={'row'}>

        {(templates || []).map((it, ind) => {
            return (<div key={ind} className={'col-xs-12'} onClick={() => {props?.onClick(it)}}>
                {it.name}
            </div>)
        })}
    </div>
}

export default Layout2
