global.is_local = /localhost/.test(window.location.host) ? 1 : 0;

let domain = global.is_local ? 'http://localhost:6173' : 'https://saleapi.itrum.ru';
// domain = global.is_local ? 'https://saleapi.itrum.ru' : 'https://saleapi.itrum.ru';
window.env = {
    // domain     : ,
    domain,
    FILE_DOMAIN: domain,
    title: 'Lead Gen Portal',
    login_title: 'Lead Gen Portal',
    wo_token: false,
    redirect_after_login: '/admin/users'

}

document.title = window.env.title;
