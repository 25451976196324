import React, {useState} from 'react';
import _ from 'underscore';
import Smart from '../../libs/Smart';
import Table from '../../libs/Table';
import Input from '../../libs/Input';

import {
  Link, Outlet
} from "react-router-dom";


function Users(props) {
  const [obj, setObj] = useState({name: '22'});
  const [count, setCount] = useState(0);

  return <div>
    Users


    <div>

      <Table
        // items={obj.items}
        onChange={(items, item) => {
          console.log('*........ ## items, item', items, item);
        }}

        opts={{
          url     : '/user',
          per_page: 5,
          search  : '',
          edit    : [
            {name: 'name', key: 'name'},
            {name: 'username', key: 'username'},
          ],
          tabs    : [
            {name: 'asdf', key: '_id'},
            {name: 'value', key: 'value'},
            {name: 'username', key: 'username'},
            {name: 'name', key: 'name'},

          ]
        }}
        TableFilter3={({search, onSearch, onAdd, onChangeFilter}) => {
          return <div>
            <Input value={search} onChange={onSearch}></Input>
            <button onClick={(e) => {
              onAdd()
            }}>Add
            </button>
            <button onClick={(e) => {
              onChangeFilter('4444', 'value')
            }}>Filter
            </button>
          </div>
        }}
        Component3={({item, onAdd}) => {
          return <div onClick={(e) => {
            console.log('*........ ## click');
            onAdd && onAdd(item)
          }}>{item._id} {item.value}</div>
        }}

      ></Table>
      {/*<pre>{JSON.stringify(obj, null, 2)}</pre>*/}
      {/*<SmartTable></SmartTable>*/}
    </div>
  </div>
}

export default Users
