import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import Moment from 'react-moment';
import moment from 'moment';

import {
    Link, Outlet
} from "react-router-dom";
import Input from "../libs/Input";
import Select from "../libs/Select";
import Textarea from "../libs/Textarea";
import Button from "../libs/Button";

// import './JobApply.css'

function loadUsers(cb) {
    global.http.get('/t-user', {},)
        .then(({items}) => {
            cb && cb(items)
        })

}


function Layout2(props) {
    let [users, setUsers] = useState([])
    useEffect(() => {
        loadUsers(setUsers)
    }, [])
    let {item, autoSaveFn} = props;
    console.log('*........ ## ROOT RENDER11111', props);
    // let users = [{}, {}, {status: 'grey'}]

    let types = ['M', 'W', 'Continue', 'Thanks']
    return <div className={'row'}>
        <div className="col-sm-4">
            Job Hash Tags & Status
            <div className="row">
                <div className="col-sm-6">
                    <div>
                        Source Type
                        <Select value={item.sourceType} onChange={(v) => {
                            item.sourceType = v;
                            autoSaveFn(item)
                        }} items={['', 'hh', 'tg']}></Select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div>
                        Status
                        <Select value={item.status} onChange={(v) => {
                            item.status = v;
                            autoSaveFn(item)
                        }} items={['', 'waitApply', 'applied']}></Select>
                    </div>
                </div>
            </div>

            <div>
                <Input
                    placeholder={'HH id'}
                    value={item.postId}
                    onChange={v => {
                        console.log("qqqqq vvvv", v);
                        item.postId = v;
                        autoSaveFn(item)
                    }}

                />
                <Input
                    placeholder={'Tg UserName'}
                    value={item.tgUsername}
                    onChange={v => {
                        console.log("qqqqq vvvv", v);
                        item.tgUsername = v;
                        autoSaveFn(item)
                    }}

                />

            </div>
            <div>
                <Input
                    placeholder={'Client Name'}
                    value={item.clientName}
                    onChange={v => {
                        console.log("qqqqq vvvv", v);
                        item.clientName = v;
                        autoSaveFn(item)
                    }}

                />

            </div>

            <div>
                Other Jobs with the user
            </div>


            <hr/>
            <div>Lng Selector</div>
            <div>Stack Selector</div>
            <Input
                value={item.name}
                onChange={v => {
                    console.log("qqqqq vvvv", v);
                    item.name = v;
                    autoSaveFn(item)
                }}

            /><Textarea
            value={item.desc}
            onChange={v => {
                console.log("qqqqq vvvv", v);
                item.desc = v;
                autoSaveFn(item)
            }}

        />
        </div>
        <div className="col-sm-8">
            <TgApply users={users} to={item.tgUsername} jobApply={item}></TgApply>
            <hr/>
            {/*<div className="row">*/}
            {/*    <div className="col-sm-12">*/}
            {/*        Status*/}
            {/*    </div>*/}
            {/*    <div className="col-sm-6">*/}
            {/*        Messages*/}
            {/*        <hr/>*/}
            {/*        OR*/}
            {/*        <div>Place apply Link </div>*/}
            {/*        <div>Place apply Text </div>*/}
            {/*        <div>Place apply Status </div>*/}
            {/*    </div>*/}
            {/*    <div className="col-sm-6">*/}
            {/*        {(types || []).map((it, ind) => {*/}
            {/*            return (<div key={ind} className={'users-apply-types'}>*/}
            {/*                {it}*/}
            {/*            </div>)*/}
            {/*        })}*/}


            {/*        <hr/>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    </div>
}


function TgApply(props) {
    let {users = [], to, jobApply} = props;
    let [details, setDetails] = useState({})
    let [templates, setTemplates] = useState([])

    let onSend = (user, scb, ecb) => {

        let info = details[user._id] || {}
        console.log("qqqqq on Send", user, to, jobApply);
        global.http.get('/tg/send', {
            to,
            from: user.tgUsername,
            msg: info.msg,
            delay: info.delay || 0,
            jobApply: jobApply._id
        }).then(r => {
            console.log("qqqqq rrrrr", r);
            scb && scb()
            setDetails({...details, [user._id]: {}})
        })

    }
    useEffect(() => {
        // global.http.get('/conversations', {to}).then(r => {
        //     console.log("qqqqq rrrrr tg conversation load", r);
        // });
        // global.http.get('/tg/messages', {user: user.tgUsername, to}, {
        //     wo_prefix: true,
        //     domain: 'http://localhost:5000'
        // }).then(r => {
        //     console.log("qqqqq rrrrr tg conversation load", r);
        // });
        syncTemplates()
    }, [to]);
    function syncTemplates () {
        global.http.get('/chat-templates', {}).then(r => {
                console.log("qqqqq rrrrr tg conversation load TEMPLATEs", r);
                setTemplates(r.items)
            });
    }



    return <div>{(users || []).map((user, ind) => {
        let info = details[user._id] || {}
        console.log("qqqqq msgggg info", info);
        let setInfo = (data) => {
            details[user._id] = data;
            setDetails({...details})
        }
        let pubTemplate = (str) => {
            return (str || '')
                .replace(/\<\%client\%\>/gi, jobApply.clientName || '<%client%>')
                .replace(/\<\%name\%\>/gi, user.firstName || '')
        }
        let onClickTemplate = (template) => {
            console.log("qqqqq on clickc tempalte", template, user);
            setInfo({...info, msg: pubTemplate(template.desc)})
        }
        return (<div key={ind} className={'users-apply ' + user.status}>
            <div className={'row'}>
                <div className="col-sm-3">
                    {user.name}
                    <div>
                        {user.tgUsername} - {to}
                    </div>
                    <small>x5</small>
                    <div></div>
                    <small>status</small>
                </div>
                <div className="col-sm-5">
                    <Textarea
                        value={info.msg}
                        onChange={(msg) => {
                            console.log("qqqqq msgggg", msg);
                            setInfo({...info, msg})
                        }}
                    ></Textarea>
                    <Button onClick={(scb) => {
                        onSend(user, scb)
                    }}>Send</Button>
                    <Button onClick={(scb) => {
                        onSend(user, scb)
                    }}>Send HH</Button>
                    <Button onClick={(scb) => {
                        onSend(user, scb)
                    }}>Send HH Tags</Button>
                    <div className="ib" style={{width: '100px'}}>
                        <Input
                            type={'number'}
                            value={info.delay}
                            onChange={(delay) =>
                                setInfo({...info, delay})
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-4">

                    <Templates
                        filter={{
                            channel: jobApply.sourceType,
                            gender: user.gender,
                            stack: jobApply.stack,

                        }}
                        jobApply={jobApply}
                        onClickTemplate={onClickTemplate}
                        templates={templates}
                        to={to} channel={jobApply.sourceType} from={user.tgUsername}></Templates>
                </div>
                <div className="col-sm-12">
                    <hr/>
                </div>
            </div>
        </div>)
    })}</div>
    // return
}

function pubDate(date) {
    const timestamp = moment(date); // Replace with your timestamp
    return <Moment fromNow>{timestamp}</Moment>
}

function Templates(props) {
    let {to, from, channel, templates} = props;
    let [info, setInfo] = useState({});
    let [filter, setFilter] = useState(props.filter);
    let filt = props.filter || {};

    useEffect(() => {
        if (to && from) {
            global.http.get('/tg/messages', {username2: to, username1: from}).then(r => {
                console.log("qqqqq rrrrrrr", r);
                setInfo(r)
            })
        }
    }, [to, from])
    useEffect(() => {
        setFilter(filt)
    }, [filt, filt?.channel, filt?.gender, filt?.stack])

    // let isEmpty = !(info.applies || []).length && !(info._messages || []).length;
    let isEmpty = !(info._messages || []).length;
    filter = filter || {}
    console.log("qqqqq filterfilterfilterfilter", filt, filter,props.jobApply);

    return <div className={'messages'}>
        {!isEmpty && <div className={(isEmpty ? 'empty' : 'notEmpty')}>
        <small>
            {from} ->
        </small>
        <small>
            -> {to}
        </small>
        {(info._messages || []).map((it, ind) => {
            return (<div key={ind} className={'message-item'}>
                <b>{it.isMy ? '' : 'Клиент: '}</b> <small>{pubDate(it.date)}</small>
                <div>{it.msg}</div>
            </div>)
        })}
        </div>}
        {isEmpty && <>
            <div>
                {([{
                    key: 'channel',
                    items: ['', 'tg', 'hh', 'email']
                }, {
                    key: 'gender',
                    items: ['', 'm', 'w']
                }, {
                    key: 'stack',
                    items: ['', 'react', 'angular', 'qa']
                }]).map((it, ind) => {
                    return (<Select
                        className={'ib'}
                        value={filter[it.key]} onChange={(v) =>{
                        setFilter({...filter, [it.key]: v})
                    }}
                        items={it.items}
                        key={ind}>
                    </Select>)
                })}


            </div>
            {(templates || []).map((it, ind) => {
                return (<div key={ind} className={'ellipseTemplate'}
                             onClick={() => {props.onClickTemplate(it)}}
                >
                    <div>
                        <small className={'label label-success mr-5'}>{it.gender || 'gender'}</small>
                        <small className={'label label-success'}>{it.channel || 'channel'}</small>
                        <small className={'label label-success'}>{it.language || 'lng'}</small>
                        <small className={'label label-success'}>{it.stack || 'stack'}</small>
                    </div>
                    {it.name} {it.desc}
                </div>)
            })}


        </>}
    </div>
}

export default Layout2
