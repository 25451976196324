import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Textarea from "../../libs/Textarea";
import Button from "../../libs/Button";


function Layout2(props) {
    let {jobId} = props;
    let [value, setValue] = useState('')
    let [file, setFile] = useState('')
    let getOpts = () => {
        return {
            jobId,
            msg: value,
            dialog: props.dialog,
            file: file,
            tuser: props.tuser
        }
    }

    function onSend(scb, ecb) {
        global.http.post('/send-message', getOpts()).then(r => {
            console.log("qqqqq rrrrrr", r);
            props.onChange && props.onChange(() => {
                setValue('')
                setFile('')
                scb && scb();
            })
        }).catch(e => {
            ecb && ecb();
        })

    }

    function sendDelay(delay, scb, ecb) {
        global.http.post('/send-delay', {data: getOpts(), delay}).then(r => {
            console.log("qqqqq delay is sent",);
            setValue('')
            setFile('')
            scb && scb()
            props.onChange && props.onChange(() => {
            })
        }).catch(e => {
            ecb && ecb()
        })

    }


    useEffect(() => {
            setValue((props._value || ''))
    }, [props._value])


    console.log('*........ ## ROOT RENDER', props);

    return <div>
        <Textarea value={value} onChange={v => setValue(v)}>
        </Textarea>
        <Button onClick={(scb, ecb) => {
            onSend(scb, ecb)
        }}>Send
            <small> #{jobId}</small>
        </Button>
        {([{
            name: '1с',
            duration: 1
        }, {
            name: '30c',
            duration: 30
        }, {
            name: '1',
            duration: 60
        }, {
            name: '3',
            duration: 60 * 3
        }, , {
            name: '5',
            duration: 60 * 5
        }, {
            name: '10',
            duration: 60 * 10
        }, {
            name: '20',
            duration: 60 * 20
        }, {
            name: '30',
            duration: 60 * 30
        }, {
            name: '1ч',
            duration: 60 * 60 * 1
        }, {
            name: '2ч',
            duration: 60 * 60 * 2
        }, {
            name: '3ч',
            duration: 60 * 60 * 3
        }, {
            name: '1д',
            duration: 60 * 60 * 24
        }, {
            name: '1д1ч',
            duration: 60 * 60 * 25
        }, {
            name: '1д2ч',
            duration: 60 * 60 * 26
        },] || []).map((it, ind) => {
            return (<Button key={ind} onClick={(scb, ecb) => {
                sendDelay(it, scb, ecb)
            }}>
                {it.name}
            </Button>)
        })}

        <span>
      {(['👍', '🙏', '❤️', '😊', '☺️'] || []).map((it, ind) => {
          return (<span
              style={{padding: '5px', cursor: 'pointer', fontSize: '24px'}}
              key={ind} onClick={() => {
              setValue(value + it)
          }}>
            {it}
          </span>)
      })}
      
      
    </span>
        <Textarea value={file} onChange={v => setFile(v)}>
        </Textarea>
    </div>
}

export default Layout2
