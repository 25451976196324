import React, {useState, useEffect, useRef} from 'react';
import Perc from "./Suggest/Perc";
import Input from "../libs/Input";
import Textarea from "../libs/Textarea";
import MyModal from "../libs/MyModal";
import Smart from "../libs/Smart";
import Stringify from "./Stringify";
import ProfileImg from "./ProfileImg";

function Layout2(props) {
    //console.log('*........ ## ROOT RENDER', props);

    let [open, setOpen] = useState(false)
    let [openFile, setOpenFile] = useState(false)
    let [comment, setComment] = useState('')
    let [progress, setProgress] = useState(0)
    let [err, setErr] = useState('')
    let [info, setInfo] = useState({})
    let [file, setFile] = useState(null)
    let [video, setVideo] = useState(null)

    let CV_KEY = props?.props?.field?.key || 'img';
    let {localItem, onGlobalChange, field} = props?.props || {}

    console.log('*........ ## propssssssssssssssss', props, localItem);
    useEffect(() => {
        updateVideo({comment})
    }, [comment])

    useEffect(() => {
        err && window.notify.error(err)
    }, [err])

    let updateVideo = async (data) => {
        video && video._id && await global.http.put('/my-upload-video', {_id: video._id, ...data || {}})
    }
    let user = global.user;
    let env = global.env;

    let wrapEl = useRef(null)


    let onChange = async (file) => {
        //console.log("qqqqq file", file);
        const url = global.env.FILE_DOMAIN + '/file-upload'; // Your Node.js server URL
        const chunkSize = 1024 * 1024 * 5; // 5MB chunk size
        let start = 0;
        let fileSize = file.size;
        let originalFileName = file.name;
        let hash = new Date().getTime();

        const extension = file.name.split('.').pop();
        let fileName = `${hash}.${extension}`


        let info = {
            name: originalFileName,
            fileName,
            chunkSize,
            fileSize,
        }
        console.log("qqqqq infoooooooooooooooooooo", info);


        setFile(file)
        setComment('')
        setInfo(info)
        setProgress(0)
        setVideo({})
        setErr('')


        window.setFile = setFile


        let onErr = (err) => {
            setFile(null)
            setErr(err)
            setOpen(true)
            wrapEl.current.val('')
            // $('#fileWrapElTest').val('')
        }

        if (fileSize > 350 * 1000) {
            return onErr(`Слишком большой размер файла. Разрешимый объем до 300Кб. Текущий ${(fileSize / 1000).toFixed(2)}`)
        }


        let video = await global.http.post('/file', {
            type: 'img',
            hash,
            fileName,
            info,
            size: toMb(info.fileSize, 2),
            hostname: window.location.hostname,
            uploadDomain: env.FILE_DOMAIN,
            filePath: getPath(info),
            user: user.get_id(),
            tuser: localItem._id,
        })
        setVideo(video)

        console.log("qqqqq FILE CREATEF", file);

        //console.log("qqqqq video", video);
        while (start < fileSize) {
            try {

                const end = start + chunkSize;
                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append('user', video.tuser || video.user);
                formData.append('chunk', chunk, fileName);

                let t = await fetch(url, {
                    method: 'POST',
                    body: formData
                });
                setProgress(Math.round(100 * start / fileSize))

                console.log("qqqqq start end", {start, end});
                start = end;
            } catch (e) {
                // await updateVideo({_id: video._id, status: 'error'})//global.http.put('/my-upload-video', {_id: video._id, status: 'error', info})
                //
                return setErr(e.toString())
            }

        }

        setProgress(100)
        fileUploaded(video)
        console.log("qqqqq FILE IS UPLOADEDDDDD",);
    }

    function fileUploaded(file) {
        localItem.img = file.uploadDomain + file.filePath;
        localItem.imgInfo = file;
        onGlobalChange && onGlobalChange(localItem)
        console.log("qqqqq file name", file, localItem);
        setOpenFile(false)
    }


    let toMb = (size, round = 1) => {
        return +((size / (1000 * 1000)) || 0).toFixed(round)
    }

    let getPath = (_info = info) => {
        return `/file/${localItem._id}/${_info?.fileName}`
    }
    let getLink = () => {
        return `${env.FILE_DOMAIN}${getPath()}`
    }
    // let v = useActionData();

    let {hideDetails} = props;
    let link = getLink();

    useEffect(() => {
        progress === 100 && info.fileName && link && props.onChangeLink && props.onChangeLink(link)
    }, [link, progress, info?.fileName])

    window.wrapEl = wrapEl;
    return <div>
        <ProfileImg user={localItem} onClick={() => {
            setOpenFile(true)
        }}></ProfileImg>

        {!localItem.img && <a onClick={(e) => {
            setOpenFile(true)
        }}>Загрузить фото</a>}
        <MyModal
            isOpen={openFile}
            onClose={() => setOpenFile(false)}
        >
            <MyModal
                isOpen={open}
                onClose={() => setOpen(false)}
            >
                {err && <div className="alert alert-danger">
                    {err}
                </div>}
                <div></div>
                Интсрукция по компрессии видео перед загрузкой в ближайшее время появится здесь!
                <div>
                    На данный момент обратитесь пожалуйста к Сергею Титову за консультацией
                </div>
                <div></div>
            </MyModal>
            Загрузите файла.

            <div>
                Ссылка: {!info?.fileName && <b>Выберите в начале файл</b>}
                {!!info?.fileName &&
                    <><a href={link} target={"_blank"}>{link}</a>
                        <div className="fa fa-copy" style={{marginRight: '10px', fontSize: '20px'}} onClick={() => {
                            copyText(link)
                        }}></div>
                    </>}
                {file && <Textarea
                    placeholder={'Комментарий-аннотация к файлу'}
                    value={comment} onChange={(v) => {
                    setComment(v)
                }
                }/>}
            </div>
            <hr/>


            {file && <>
                {!hideDetails && <>
                    <div className="row">
                        <div className="col-sm-12">
                            Размер файла: {toMb(info.fileSize)} Мб
                        </div>
                    </div>
                    <hr/>
                </>}

                <>
                    Прогресс: {progress}%
                    <Perc value={progress} height={3}></Perc>
                </>

            </>}
            <div style={{marginTop: '20px'}}></div>
            <div className={'fileWrap'}
                 onClick={() => {
                     window.wrapEl = wrapEl;
                     console.log('*........ ## onCLIckckckckckkckckck', wrapEl.current);
                     // $('#fileWrapElTest').click()
                     wrapEl.current.click()
                 }}>{info.name || 'Выберите файл'}</div>
            <input style={{opacity: 0}} ref={wrapEl} id="fileWrapElTest" type="file" file={file} onChange={(e) => {
                console.log('*........ ## on change file', e);
                let file = e.target.files[0];
                //console.log("qqqqq fileeee", file);
                onChange(file)
            }}/>

        </MyModal>

    </div>
}

export function copyText(text) {
    const textarea = document.createElement('textarea');

    // Set its value to the text you want to copy
    textarea.value = text;

    // Make sure it's not visible on the screen
    textarea.setAttribute('readonly', ''); // Prevents keyboard from appearing on mobile devices
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append it to the body
    document.body.appendChild(textarea);

    // Select the text
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    try {
        // Copy the text inside the textarea
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        //console.log('Copying text command was ' + msg);
    } catch (err) {
        console.error('Oops, unable to copy', err);
    }

    // Remove the textarea from the body
    document.body.removeChild(textarea);
    window.notify?.success('Ссылка скопирована!')
}


export default Layout2
