import React, {useEffect, useRef, useState} from 'react';
import Select from 'libs/Select';
import moment from 'moment';
import _ from 'underscore';
import './jobs.css'
import ReactPaginate from 'react-paginate';

import {
    Link, Outlet
} from "react-router-dom";
import Textarea from "../libs/Textarea";
import ChatDetails from "./Chat/ChatDetails";
import ChatInsideJobs from "./Chat/ChatInsideJobs";
import StackSelector from "./StackSelector";
import Input from "../libs/Input";
import Apply, {getActiveClass, WrongApply} from "./Chat/Apply";
import Button from "../libs/Button";
import MyModal from "../libs/MyModal";
import Smart from "../libs/Smart";
import Stringify from "./Stringify";
import {debounce} from "./Chat/methods";
window.activeTUsersCache = {}

export const domains = {
    tg: 'https://t.me',
    hh: 'https://hh.ru'
}
// export const STATUSES = ['new', 'bad', 'bad_stack', 'bad_resume', 'bad_email', 'bad_senior', 'good', 'duplicated', 'not_sure_how_apply'];
export const STATUSES = ['new', 'applied', 'spam', 'apply_later', 'duplicate', 'cannot_apply', 'later'];
export const SORTING_STATUSES = ['-postDate', 'postDate', '-_id', '_id', '-applyDate', 'applyDate', '-lastUserAction'];

let postTimeoutId = -1;


function Jobs(props) {
    const [creatingJob, setCreatingJob] = useState(null)
    const [total, setTotal] = useState([])
    const [arrs, setArrs] = useState({})
    const [items, setItems] = useState([])
    const [reloadLoading, setReloadLoading] = useState(false)
    const [filter, _setFilter] = useState(getHash())
    let [subscribeId] = useState(window.WS.getID())
    let itemsRef = useRef([])
    itemsRef.current = items;

    useEffect(() => {
        window.activeTUsersCache = {}
    }, [])
    useEffect(() => {
        window.WS.subscribe('post', subscribeId, (data) => {

            let isOk = false
            let items = itemsRef.current;
            console.log("qqqq qqqqq selectdHJob Ind WSSSSSSSSSSSSSSSS ", data?.activeHhId);
            _.each(items, (item, ind) => {

                if (item._id == data._id) {
                    isOk = true
                    items[ind] = data;
                }
            })
            // console.log("qqqqq post is updated REAL TIME", data);

            isOk && setItems(pubItems([...items]))

        })

        return () => {
            window.WS.unsubscribe('dialog', subscribeId)
        }
    }, [])

    function setIsApplyOpen(isApplyOpen) {
        setFilter({isApplyOpen})
    }

    // console.log('*........ ## ROOT RENDER', props);
    const setFilter = (it) => {
        let _filter = {...filter, ...it};
        _setFilter(_filter)
    }

    useEffect(() => {
        setHashObj(filter)
    }, [JSON.stringify(filter)])
    // useEffect(() => {
    //     let str = JSON.stringify(filter)
    //     window.history.replaceState(undefined, undefined, window.location.pathname + ('#' + str))
    // }, [JSON.stringify(job0.hhId)])

    function pubItems(items) {
        items = items.map(pubItem)//_.each(items, pubItem)
        return items;
    }

    function pubItem(item) {
        item.customData ??= {};
        item.customData.lng = item.customData.lng || (item?.lngs || [])[0]

        if (item.channel === 'hh') {
            item.activeHhId = item.activeHhId || item?.data?.id;
        }

        return item;
    }


    useEffect(() => {
       reloadJobs()
    }, [JSON.stringify({...filter, jobId: 'fakeData', isApplyOpen: 'fakeData'})])

    function reloadJobs(cb) {
        setReloadLoading(true)
        global.http.get('/my-post', filter).then(r => {
            // console.log('aaaaaaa', r)
            setArrs({good: r.good, bad: r.bad, mid: r.mid, search: r.search})
            setItems(pubItems(r.items))
            setTotal(r.total)
            setReloadLoading(false)
        })
    }

    function onApprove(post, status, url) {
        global.http.post("/post-job", {_id: post._id, status: status || post.status})
        post.status = status;
        setItems(pubItems([...items.filter(it => it._id != post._id)]))

        function openInNewTab(url) {
            window.open(url, '_blank').focus();
        }

        // console.log("qqqqq on approve!!!!!!", post);
        if (status == 'good' && url) {
            openInNewTab(url)
        }
    }

    let selectedJobInd = 0;
    let job0 = items.filter((it, ind) => {
        if (it._id == filter.jobId) {
            selectedJobInd = ind;
            return true;
        }
    })[0]


    let goodReg = regifyArr(arrs.good)
    let midReg = regifyArr(arrs.mid)
    let badReg = regifyArr(arrs.bad)
    let searchReg = regifyArr(arrs.search, true)

    // console.log("qqqqq arrrs", arrs, searchReg);


    // const job0 = items[0];

    function getLink(desc, entities) {
        let items = (entities || []).filter(it => it.className === 'MessageEntityMention');
        let links = (items || []).map(v => {
            return (desc || '').substring(v.offset, v.offset + v.length)
        });

        (desc || '').replace(/\:\/\/t\.me\/(.+?)(\s|$|\n)/gi, (...args) => {
            // console.log("qqqqq argss", args);
            links.unshift(args[1])
        })
        let emails = extractEmails(desc);

        // console.log("qqqqq emails", emails);

        function extractEmails(text) {
            return ((text || '').match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || []).map(it => {
                return it
            })
        }

        return _.uniq([...links || [], ...emails || []])
    }

    function selectJob(it, ind) {
        setFilter({jobId: (items[ind] || {})._id, isApplyOpen: true, isMenuOpen: true})
        // setIsApplyOpen(true)

        // setSelectedJobInd(ind)
    }


    console.log("qqqqq JOBS RENDERS",);

    function onCreateJob(scb, ecb) {
        setCreatingJob({channel: 'custom'})
        scb && scb();
    }

    function createHTTPJob(scb, ecb) {

        // console.log("qqqqq creatingJob", creatingJob);
        if (creatingJob?._id) {
            global.http.put("/post", creatingJob, {plainRes: true}).then(r => {
                let job = r;
                // console.log("qqqqq job is createddddddddddddd UPDATEDEDDDDDDDDDDDDDDDDDDD", job, creatingJob);
                if (job && job._id) {
                    let _items = items.map(it => {
                        if (it._id == job._id) {
                            return pubItem(job);
                        } else {
                            return it;
                        }
                    })
                    setFilter({jobId: r._id})
                    setItems(_items)
                    setCreatingJob(null);
                }
            })
        } else {
            creatingJob.postDate = new Date().getTime();
            global.http.post("/post", creatingJob).then(r => {
                let job = r;
                // console.log("qqqqq job is createddddddddddddd", job, creatingJob);
                if (job && job._id) {
                    setFilter({jobId: r._id})
                    setItems([pubItem(job), ...items])
                    setCreatingJob(null);
                }
            })
        }

        scb && scb();
    }

    function applyNow(cb) {
        cb && cb()
        setIsApplyOpen(true)

    }

    function applyLater(time, cb) {
        _onChange({status: 'later', applyReservedUntil: new Date().getTime() + time})
        cb && cb()
    }

    let links0 = job0 ? getLink(job0.desc, (job0.data || {}).entities)[0] : null
    links0 = (links0 || {}).isEmail ? null : links0;
    let {isApplyOpen} = filter || {}

    function onChange(v) {
        console.log("qqqqq on Change job",);
    }

    function _onChange(v, params) {

        job0 = {...job0 || {}, ...v || {}};
        global.http.put('/post', {_id: job0?._id, ...v || {}}).then(r => {
            // console.log("qqqqq rrrrrrrr888888888888888", r, job0);

        })
        console.log('paramssssssssssssssssss', params, props)
        if (params.toClose) {
            setIsApplyOpen(false)
        }
        setItems((items) => {
            items[selectedJobInd] = {...items[selectedJobInd] || {}, ...v || {}};
            return pubItems([...items])
        })

        console.log("qqqqq ON CHANGE4444", job0);
    }


    let postTimeoutRef = useRef(null);

    let chatDetailsProps = {
        // username: link0,
        job0,
        arrs,
        onEditJob: (v) => {
            setCreatingJob(v)
        },
        onChangeFilter: (v) => {
            setFilter(v)
        },
        filter,
        onChange: (v, params) => {
            let prepare = (v) => {
                let _v = {...v}
                delete _v.reservedData;
                return _v
            }

            console.log("qqqqq on change vvvvv JOB CHANGED", v, prepare(v));


            debounce(postTimeoutRef, 500, () => {
                _onChange(prepare(itemsRef.current[selectedJobInd]), params)
            })


            setItems((items) => {
                console.log("qqqqq selectdHJob Ind", selectedJobInd, v?.activeHhId, (items[selectedJobInd] || {}).activeHhId);
                items[selectedJobInd] = {...items[selectedJobInd] || {}, ...v};
                return pubItems([...items])
            })

        },
        jobId: filter?.jobId || job0?._id,
        messenger: job0?.channel
    }

    let syncJob = (scb) => {
        let {postUrl, channel, data, source} = creatingJob || {};
        let {id} = data || {};
        let sourceUrl = creatingJob?.source?.url || '';
        let vv = {postUrl, id, sourceUrl, channel};
        console.log("qqqqq sync job!!!", vv);
        scb && scb();
        let fields = {
            hh: 'activeHhId',
            // tg: 'activeTg',
            // email: 'activeEmail',
        }
        global.http.get('/sync-job', vv).then(data => {
            let newItem = data.newItem;
            let d = {...creatingJob || {}, ...newItem || {}};
            if (fields[channel]) {
                d[fields[channel]] = id;
            }
            d.title = newItem?.data?.name || d.title;
            console.log("qqqqq sync job!!! callback", data);

            setCreatingJob(d)
        })
    }
    let isCreate = !creatingJob?._id;
    console.log("qqqqq qqqqq selectdHJob Ind 0000 RENDER --- ", job0?.activeHhId);
    // console.log("qqqqq regslinks0", job0?.customData);
    return <div className={'row'}>

        <div className="col-xs-2">
            <Input type="text" className={'form-control'} woLabel={true} placeholder={'Поиск'} value={filter.search}
                   onChange={(search) => setFilter({...filter, search})}/>
        </div>
        <div className="col-sm-2">
            <Select items={[...SORTING_STATUSES]} value={filter.sort} onChange={(v) => {
                setFilter({sort: v})
            }}></Select>
        </div>
        <div className="col-sm-2">
            <Select items={['all', ...STATUSES]} value={filter.status} onChange={(v) => {
                setFilter({status: v})
            }}></Select>
        </div>
        <div className="col-sm-2">
            <Select items={['all', 'hh', 'tg', 'custom']} value={filter.channel} onChange={(v) => {
                setFilter({channel: v})
            }}></Select>
        </div>
        <div className="col-sm-2">
            <Select items={['all', 'ru', 'en']} value={filter.postLang} onChange={(v) => {
                setFilter({postLang: v})
            }}></Select>
        </div>
        <div className="col-sm-2">
            <Input value={filter.currency} woLabel={true} title={'Currency'} onChange={(v) => {
                setFilter({currency: v})
            }}></Input>
        </div>

        <div className="col-sm-6">
            <StackSelector value={filter.stack || []} onChange={v => {
                setFilter({stack: v})
            }}></StackSelector>
        </div>
        <div className="col-sm-2">
            <Input placeholder="Price" woLabel={true} value={filter.price} onChange={(price) => {
                setFilter({price})
            }}></Input>
        </div>
        <div className="col-sm-2">
            {/*<Input title={"IsApply?"} type={"checkbox"} value={filter.isApplied} onChange={(isApplied) => {*/}
            {/*    setFilter({isApplied})*/}
            {/*}}></Input>*/}
            <Select items={['all', 'apply', 'ignore', 'duplicate', 'wait', 'later']} value={filter.status2}
                    onChange={status2 => setFilter({status2})}></Select>
        </div>
        <div className="col-sm-2">
            {/*<Input title={"IsApply?"} type={"checkbox"} value={filter.isApplied} onChange={(isApplied) => {*/}
            {/*    setFilter({isApplied})*/}
            {/*}}></Input>*/}
            <Select items={['all', 'goodScore', 'badScore', 'ignoreGood']} value={filter.statusScore}
                    onChange={statusScore => setFilter({statusScore})}></Select>
        </div>
        {/*<div className="col-xs-12">*/}
        {/*    <hr/>*/}
        {/*</div>*/}

        <div className={'col-sm-12'}>
            <ReactPaginate previousLabel={"<"}
                           nextLabel={">"}
                           breakLabel={<span>...</span>}
                           breakClassName={"break-me"}
                           pageCount={filter.page_count || 10}
                           forcePage={(filter.page || 1) - 1}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={5}
                           onPageChange={({selected}) => {
                               // console.log("qqqqq pageeeeeeeeeeeeeee", page);
                               setFilter({page: selected + 1})
                           }}
                           containerClassName={"pagination"}
                           subContainerClassName={"pages pagination"}
                           activeClassName={"active"}
            />
            <div>
                <Button onClick={onCreateJob}>Create Job</Button>
                <Button disabled={reloadLoading} onClick={reloadJobs}>Reload</Button>
            </div>
            <div className="jobs-wrap2">
                {(items || []).map((it, ind) => {
                    it.data = it.data || {}
                    let data = it.data || {};
                    let ks = (data.key_skills || []).map(it => `<small class="tag-item">#${it.name}</small>`).join('');
                    let links = getLink(it.fullDesc, it.data.entities)
                    let hTags = getHashTags([data.name || '', ks, it.fullDesc].join(' '));

                    let vv1 = regifyDesc({desc: it.fullDesc, goodReg, midReg, badReg, searchReg, isLogs: 1})
                    let vv2 = regifyDesc({desc: it?.title, goodReg, midReg, badReg, searchReg})
                    let vv3 = regifyDesc({desc: ks, goodReg, midReg, badReg, searchReg})
                    let vv4 = regifyDesc({desc: hTags.join('&nbsp;&nbsp;'), goodReg, midReg, badReg, searchReg})

                    let tags = combineTags([vv1, vv2, vv3, vv4])

                    // console.log("qqqqq vv2vv2", vv1.tags, {goodReg, midReg, badReg, searchReg}, it.desc);

                    function getTitle() {
                        let arr = (vv1.desc || '').split(/\.|\n/gi)
                        return [arr[0],  arr[1], arr[2]].join('. ').substring(0, 300);
                    }


                    let isLong = (date) => {
                        return (new Date().getTime() - new Date(date).getTime()) > 24 * 3600 * 1000 * 1.5
                    }
                    // console.log("qqqqq datajob0", job0);
                    return (
                        <div key={ind} className={'row item-job ' + getActiveClass(it._id, job0?._id)} onClick={() => {
                            selectJob(it, ind)
                        }}>
                            <div className="col-sm-12">
                                <WrongApply job={it} isShort={true}></WrongApply>
                                <div>
                                    <strong>
                                        <small style={{
                                            marginLeft: '0px',
                                            marginRight: '10px',
                                            color: isLong(it.postDate) ? 'red' : ''
                                        }} title={it.postDate}>{moment(it.postDate).fromNow()}</small>
                                    </strong>
                                    <small>{(it.source || {}).url || it.channel}</small>
                                    {/*<small className={'ml5 label ' + clName}> {status}</small>*/}

                                    <div className="pull-right">
                                        <a href={(domains[it.channel] || '') + it.postUrl}
                                           target={'_blank'}>{it.postUrl}</a>
                                    </div>
                                </div>
                                {(links || []).map((it, ind) => {
                                    let link = 'https://web.telegram.org/k/#' + it;
                                    return (<div key={ind} onClick={() => {
                                        onApprove(it, 'good', link)
                                    }}>
                                        <a href={link} target={'_blank'}>{it && it.isEmail ? it.value : it}</a>
                                    </div>)
                                })}
                                <TagsRender tags={tags}></TagsRender>

                                {data?.employer && <div>
                                    <small className={'label label-info'}>
                                        <strong>{data?.employer?.name}</strong>
                                    </small>
                                </div>}


                                {vv2.desc &&
                                    <>{vv2.desc}
                                    <div dangerouslySetInnerHTML={{__html: vv2.desc}} style={{marginTop: '5px'}}></div>
                                    </>}
                                {!vv2.desc && <div className={'autoTitle'} dangerouslySetInnerHTML={{__html: getTitle()}}></div>}

                                <SalaryComp job={it}></SalaryComp>
                                <LngComp job={it}></LngComp>
                                <PubStack customTags={it?.customData}></PubStack>
                                <AppliesCount job={it}></AppliesCount>
                            </div>


                        </div>)
                })}
            </div>
        </div>
        <MyModal
            isOpen={isApplyOpen && job0}
            size={'full'}
            defClass={'vFull'}
            onClose={() => {
                console.log("qqqqq close is apply open", window?.job0);
                setIsApplyOpen(false)
                onChange(window?.job0)
            }}
        >
            <Apply job0={job0}
                   onClose={() => setIsApplyOpen(false)}
                   chatDetailsProps={chatDetailsProps}
                   // onChangeJob={onChange}
            ></Apply>
        </MyModal>
        {/*{job0 && <div className="col-sm-6">*/}


        <MyModal
            isOpen={creatingJob}
            onClose={() => {
                setCreatingJob(null)
            }}
        >
            {isCreate ? 'Создание вакснии' : 'Обновление вакансии'}
            <Button onClick={syncJob}>{'Синхронизация'}</Button>

            <Smart
                obj={creatingJob}
                defSize={12}
                items={[

                    {
                        isVisible: () => /hh|tg/gi.test(creatingJob?.channel),
                        name: 'data.id',
                        key: 'data.id',
                        size: 6
                    },
                    {
                        isVisible: () => /tg/gi.test(creatingJob?.channel),
                        name: 'source.url',
                        key: 'source.url',
                        size: 6
                    },
                    {
                        name: 'channel',
                        key: 'channel',
                        type: 'select',
                        items: ['', 'hh', 'tg', 'email', 'custom']
                    },
                    {
                        name: 'title',
                        key: 'title'
                    }, {
                    isVisible: () => {
                        return creatingJob?.channel != 'hh'
                    },
                        name: 'postUrl',
                        key: 'postUrl'
                    }, {
                        name: 'desc',
                        key: 'desc',
                        type: 'md'
                    },

                ]}
                onChange={v => {
                    // console.log("qqqqq vvvvvvvvvvvvv", v);
                    setCreatingJob({...v})
                }}
            ></Smart>
            <Button onClick={createHTTPJob}>{isCreate ? 'Создать' : 'Обновить'}</Button>
            <hr/>
            <Stringify item={creatingJob}></Stringify>
        </MyModal>
        {/*    <div className="col-xs-12">*/}
        {/*        <Button size={'xs'} onClick={applyNow}>Apply now</Button>*/}
        {/*        <Button size={'xs'} onClick={(scb) => applyLater(60 * 60 * 1000, scb)}>Apply later</Button>*/}
        {/*    </div>*/}


        {/*    <div className={'ib'} onClick={() => {*/}
        {/*        setFilter({jobId: ''})*/}
        {/*    }}>Close*/}
        {/*    </div>*/}
        {/*    <div className="ib">#{job0?._id}</div>*/}
        {/*    <ChatDetails {...chatDetailsProps}></ChatDetails>*/}
        {/*    /!*<ChatInsideJobs username={links0} jobId={job0._id}></ChatInsideJobs>*!/*/}
        {/*</div>}*/}

    </div>
}

export function SalaryComp(props) {
    let it = props?.job;
    let data = it?.data;
    if (!data.salary) {
        return null;
    }
    return <div>
        <small>
            ₽: {pubSalary((it.ruFrom))} - {pubSalary((it.ruTo))} {it.currency !== 'RUR' && <span
            title={`${data?.salary?.from} - ${data?.salary?.to}`}>( {formatLargeNumbers(Math.max(data?.salary?.from || 0, data?.salary?.to))} {it.currency} )</span>}
        </small>
    </div>
}

export function PubStack({customTags, tags}) {
    // console.log("qqqqq customTags", customTags, tags);
    return <>

        {/*PubStack*/}
        {/*<pre>{JSON.stringify(customTags, null, 4)}</pre>*/}
        {/*{(customTags || []).map((it, ind) => {*/}
        {/*    return (<div key={ind}>*/}
        {/*        {it}*/}
        {/*    </div>)*/}
        {/*})}*/}
    </>
}

export function LngComp(props) {
    let {lngs} = props?.job;
    if (!lngs || !lngs.length) {
        return '-'
    }
    return <div title={JSON.stringify(props?.job?.lngData, null, 4)}>
        {lngs.join(' ')}
    </div>
}

export function TagsRender(props) {
    let {tags} = props;
    let goodKeys = Object.keys(tags.good || []);
    let midKeys = Object.keys(tags.mid || []);
    let badKeys = Object.keys(tags.bad || []);
    let searchKeys = Object.keys(tags.search || []);

    function isOk(arr) {
        return Boolean(arr && arr.length);
    }


    return <>
        {isOk(searchKeys) && <div>
            <div data-args-custom-search={true} data-args-custom="true">
                {searchKeys.map((it, key) => {
                    return (<div key={key} className={'ib'}>
                        {it}&nbsp;
                    </div>)
                })}
            </div>
        </div>}
        {isOk(goodKeys) && <div>
            <div data-args-custom-good={true} data-args-custom="true">
                {goodKeys.map((it, key) => {
                    return (<div key={key} className={'ib'}>
                        #{it}&nbsp;
                    </div>)
                })}
            </div>
        </div>}
        {isOk(midKeys) && <div>

            <div data-args-custom-mid={true} data-args-custom="true">
                {midKeys.map((it, key) => {
                    return (<div key={key} className={'ib'}>
                        #{it}&nbsp;
                    </div>)
                })}
            </div>

        </div>}
        {isOk(badKeys) && <div>
            <div data-args-custom-bad={true} data-args-custom="true">
                {badKeys.map((it, key) => {
                    return (<div key={key} className={'ib'}>
                        {it}&nbsp;
                    </div>)
                })}
            </div>
        </div>}

    </>

}


export function setHashObj(filter) {
    try {
        let str = JSON.stringify(filter)
        window.history.replaceState(undefined, undefined, window.location.pathname + ('#' + str))
    } catch (e) {
        return {}
    }
}

export function getHash() {
    try {
        let str = decodeURI((window.location.hash || '').replace(/^\#/gi, ''));
        let v = JSON.parse(str)
        // console.log("qqqqq vvvvvvvvvvvvvv filter", v, str);
        return v
    } catch (e) {
        return {}
    }
}

export function combineTags(arr) {
    let tags = {}
    _.each(['good', 'mid', 'bad', 'search'], (key, ind) => {
        tags[key] = tags[key] || {}

        _.each(arr, (it, ind) => {
            // key === 'mid' && console.log("qqqqq ittttttttttttttttt", it, key, it[key]);
            _.each(it.tags[key], (num, tagKey) => {
                tags[key][tagKey] = (tags[key][tagKey] || 0) + (num || 0)
            })

        })

    })

    return tags;
}

export function pubSalary(v) {
    return formatLargeNumbers(v);//v ? Number(v) / 1000 : ''
}

function formatLargeNumbers(text) {
    if (!text) {
        return ''
    }
    return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}


export function getHashTags(desc) {
    let words = [];
    (desc || '').replace(/\#(.+?)(\s|\n|\$)/gi, (...args) => {
        if (args[0].length > 3) {
            words.push(args[0])
        }
    })
    return words;
}

export function regifyDesc({desc, goodReg, midReg, badReg, searchReg, isLogs}) {
    function repl(desc, reg, className) {
        let tags = {};
        if (!reg || !desc) {
            return {desc, tags}
        }
        let str = (desc || '').replace(reg, (...args) => {
            let _key = (args[3] || args[0]).toLowerCase().trim('');
            tags[_key] = (tags[_key] || 0) + 1;
            return `<strong class="${className}" data-args-custom-${className}="true">${args[0]}</strong>`
        })
        return {desc: str, tags}
    }

    let v1 = repl(desc, goodReg, 'good')
    let v2 = repl(v1.desc, midReg, 'mid')
    let v3 = repl(v2.desc, badReg, 'error')
    let v0 = repl(v2.desc, searchReg, 'search')


    // isLogs && console.log("qqqqq v0, v1, v2, v3", v0.tags, v1.tags, v2.tags, v3.tags, {desc});
    return {
        tags: {
            search: v0.tags,
            good: v1.tags,
            mid: v2.tags,
            bad: v3.tags
        },
        desc: v3.desc
    }

}

export function AppliesCount(props) {
    let {job} = props
    let {history} = job || {}
    let count = 0;
    _.each(history?.byUsers, (item, channel) => {
        _.each(item, (it, userId) => {
            if (it.applyCd) {
                count++;
            }
        })
    })

    return <small>
        Applies Count: <strong>{count}</strong>
    </small>
}

export function regifyArr(arr, isSearch) {
    return arr && arr.length ? new RegExp(arr.map(item => {
        return isSearch ? item : `\\b${item}\\b`
    }).join('|'), 'gi') : null
}

export default Jobs
