import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import {getter} from "../Fetcher";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stringify from "../Stringify";
import Smart from "../../libs/Smart";
import Button from "../../libs/Button";
import {toObjFromItems} from "./Chat";


let tusersHttp = getter('/t-user')
let tcredsHttp = getter('/t-creds')
let dialogHttp = getter('/dialog')
let messageHttp = getter('/message')

let timer = null;

function NewDialogModal(props) {
    let [itemsObj, setItemsObj] = useState({});
    let [tusers, setTusers] = useState([])
    let [tusersObj, setTusersObj] = useState({})
    let [tcreds, setTCreds] = useState([])
    let [loading, setLoading] = useState(false)
    let {tuser} = itemsObj || {};
    let {channel} = itemsObj || {};

    useEffect(() => {
        tusersHttp.get({}, items => {
            setTusers(items)
            setTusersObj(toObjFromItems(items))
        })
        tcredsHttp.get({}, setTCreds)
    }, [])

    useEffect(() => {
        if (channel == 'tg') {
            clearTimeout(timer)
            timer = setTimeout(() => {
                setLoading(true)
                global.http.get(`/employer-details?tg=${itemsObj.to}`).then(r => {
                    setLoading(false);
                    setItemsObj(itemsObj => {
                        return {...itemsObj || {}, toNum: r?.item?.id || '-'}
                    })

                })
            }, 500)

        }
    }, [channel, itemsObj.to])

    useEffect(() => {
        props.item && setItemsObj({...props.item || {}})
    }, [props.item])


    useEffect(() => {
        if (!itemsObj.tuser) {
            return;
        }
        let channel = itemsObj?.channel;
        let creds = tcreds.filter(it => {
            return (it.tuser == tuser) && (it.channel == itemsObj?.channel)
        })
        let goodCreds = creds.filter(it => {
            return it.status === 'active'
        })
        let gcreds = goodCreds[0] || creds[0];
        let info = gcreds?.info || {};
        if (!gcreds) {
            return;
        }

        setTimeout(() => {
            setItemsObj(itemsObj => {

                itemsObj = itemsObj || {}
                itemsObj.fromNum = info?.id;
                if (channel === 'tg') {
                    itemsObj.from = info?.username;
                } else if (channel === 'email') {
                    itemsObj.from = info.email;
                } else {
                    itemsObj.from = ''
                }
                console.log('infoooooooooooooooooo', info, itemsObj)

                return {...itemsObj}
            })
        }, 1)

    }, [props.item, tcreds, itemsObj?.tuser, itemsObj?.channel])

    let createNewDialog = (cb) => {
        dialogHttp.get({filter: itemsObj}, (items) => {
            let r = items[0];
            console.log('aaaaaaaaaaaaaaaaaa migrate', r, items)
            if (r) {
                // onCreateMsg(r)
                moveSystemMessages(r)
                console.log('chat already INited')
            } else {
                dialogHttp.create(itemsObj, (r) => {
                    // onCreateMsg(r)
                    moveSystemMessages(r)
                })
            }
            console.log('dialog is created xxxxxxxxxxxxxxx 1', r)

        })

        function moveSystemMessages(newDialog) {
            let activeDialog = props?.activeDialog;
            if (activeDialog) {
                global.http.get('/migrate-messages-from-old-to-new-dialog', {
                    activeDialog: activeDialog?._id,
                    newDialog: newDialog?._id
                }).then(r => {
                    console.log("qqqqq messages migrated", r);
                    cb && cb();
                })
            }

        }


        // function onCreateMsg(r) {
        //     messageHttp.create({
        //         msg: 'chat inited',
        //         tuser: r.tuser,
        //         msgDate: new Date(),
        //         msgType: 'system',
        //         isMy: true,
        //         msgSubType: props.msgSubType || 'initMsg',
        //         dialog: r._id
        //     }, (r2) => {
        //         r.lastMsgText = r2.msg
        //         r.lastMsgDate = r2.msgDate
        //         r.lastMsgId = r2._id
        //         dialogHttp.update(r)
        //         props.onChange && props.onChange()
        //         console.log('xxxxxxxxxxxxxxx 2', r, r2)
        //         cb && cb();
        //     })
        // }


    }
    console.log('*........ ## ROOT RENDERtcreds', tcreds, tuser, channel);


    return <div className={loading ? 'o5' : ''}>
        {props.title || 'New Dialog Modal'}
        {/*<Stringify item={creds}></Stringify>*/}
        <div>
            _id: {props?.activeDialog?._id}
        </div>
        <Button onClick={createNewDialog}>Create New Dialog</Button>
        <div style={{marginTop: '10px'}}>
            <Autocomplete
                value={tusersObj[itemsObj.tuser] || {}}
                onChange={(event, newValue) => {
                    // setValue(newValue);
                    console.log("qqqqq new value", newValue);
                    setItemsObj({...itemsObj, tuser: newValue?._id || null})
                }}
                options={tusers}
                // isOptionEqualToValue={(option, value) => option._id === value._id}
                isOptionEqualToValue={(option, value) => option._id == value}
                getOptionLabel={(option) => option?.name || (tusers[option] || {})?.name} // Directly use the name property from option
                renderInput={(params) => <TextField {...params} label="Выберите юзера" variant="outlined"/>}
            />
            {(tusers || []).map((it, ind) => {
                return (<div key={ind}>
                    {it._id}
                </div>)
            })}

            <Smart
                obj={itemsObj}
                items={[
                    {name: 'Channel', key: 'channel', type: 'select', items: ['', 'tg', 'hh', 'email', 'custom']},
                    {name: 'parentDialog', key: 'parentDialog'},
                    {name: 'from', key: 'from'},
                    {name: 'fromNum', key: 'fromNum'},
                    {name: 'to', key: 'to'},
                    {name: 'toNum', key: 'toNum'},
                    {name: 'tuser', key: 'tuser'},
                ]}
                onChange={(v) => {

                    setItemsObj({...v})
                }}
            >

            </Smart>
        </div>
        {/*<div>*/}
        {/*    App Parsed Messages:*/}
        {/*    {(props?.systemMessages || []).map((it, ind) => {*/}
        {/*        return (<div key={ind}>*/}
        {/*            {it._id || '--'}*/}
        {/*        </div>)*/}
        {/*    })}*/}


        {/*</div>*/}
        <hr/>

    </div>
}

export default NewDialogModal
