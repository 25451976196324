import React, {useEffect, useState, useRef} from 'react';
import Stringify from "./Stringify";
import {debounce} from "./Chat/methods";


export function EmployerInfo(props) {
    let [_infoTg, setInfoTg] = useState({})
    let [_infoHh, setInfoHh] = useState({})
    let {job, onChangeJob} = props;
    job = job || {};
    let {activeTg, activeHhId, activeEmail, activeCustomLink} = job;
    let infoHh = job?.customData?.hhInfo
    let infoTg = job?.customData?.tgInfo
    let postTimeoutRef = useRef(null);

    console.log("qqqqq job?.customData", job?.customData);
    // console.log("qqqqq jobbbbbbbbbbbbbbbbbbbbbbbb", job);

    useEffect(() => {
        activeTg = activeTg //|| (job?.linksObj?.tg || [])[0] || (job?.linksObj?.tgAuto || [])[0] || null
        if (!activeTg) {
            setInfoTg({status: 'empty'})
            return;
        }
        setInfoTg({status: 'loading'})
        global.http.get('/employer-details', {tg: activeTg}).then(r => {
            // setInfoTg(r)

            job.customData = job.customData || {}
            job.customData.tgInfo = r;
            onChangeJob && onChangeJob({customData: job.customData})
        })
    }, [activeTg, job._id])

    useEffect(() => {
        // // console.log("qqqqq activeHhIdactiveHhIdactiveHhId",activeHhId );
        if (!activeHhId) {
            return;
        }
        debounce(postTimeoutRef, 500, () => {
            global.http.get('/employer-details', {hhId: activeHhId, employer: job?.data?.employer?.id || ''}).then(r => {
                job.customData = job.customData || {}
                job.customData.hhInfo = r;
                onChangeJob && onChangeJob({customData: job.customData})
            })
        })

    }, [activeHhId, job._id])


    let logo_urls = infoHh?.item?.logo_urls
    let logo = '';
    try {
        logo = logo_urls[Object.keys(logo_urls)[0]]
    } catch (e) {

    }


    // console.log("qqqqq job employer info", job, {activeTg, activeHhId, activeEmail, activeCustomLink});

    function get(res, fields) {
        return fields.reduce((acc, path) => {
            let value = res;
            const keys = path.split('.');
            // console.log("qqqqq valueeeeeeeeee keys", value, keys);

            for (const key of keys) {
                if (value && Object.hasOwnProperty.call(value, key)) {
                    value = value[key];
                } else {
                    value = null;
                    break;
                }
            }

            if (!value && value != '0') {
                return acc;
            }
            return {...acc, [path]: value};
        }, {});
    }


    return <div className={'row'}>
        <div className={'col-xs-6'}>
            <div style={{height: '25px'}}>
                HH: {activeHhId || '-'}
                <img src={logo} alt="" height={25}/>
            </div>
            <Stringify item={get(infoHh?.item, ['name', 'open_vacancies', 'site_url',])}></Stringify>
        </div>
        <div className={'col-xs-6'}>
            <div style={{height: '25px'}}>TG: {activeTg}</div>
            <Stringify item={get(infoTg?.item, ['firstName', 'lastName', 'username', 'phone'])}></Stringify>
        </div>
    </div>
}


export default EmployerInfo;