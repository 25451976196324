import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";
import Button from "../libs/Button";
import Input from "../libs/Input";
import Stringify from "./Stringify";


function Layout2(props) {
  props = props?.props || props;
  let [redirectLink, setRedirectLink] = useState('')
  let [isRefresh, setIsRefresh] = useState(true)
  // let [items, setItems] = useState([])
  let [items, setItems] = useState([])
  const [info, setInfo] = useState({});

  let {item} = props;

  console.log('*........ ## ROOT RENDER', props);

  function refreshToken(cb) {
    cb && cb();

    setIsRefresh(true)
  }


  function getInfo (cb) {
    global.http.get('/proxy/email/me', {user: props?.item?._id}).then(r => {
      setInfo(r)
      cb && cb();
    })
  }

  let link = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//mail.google.com/&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http%3A//localhost&client_id=547514586297-lu0f39l8bf20js8kd8bbh96ve2tsfot4.apps.googleusercontent.com`


  return <div>
    Email Login Form
    <div>
      <Button onClick={(cb) => {
        refreshToken(cb)
      }}>Обновить токен</Button>
    </div>
    {isRefresh && <>
      <div>1. Перейдите по ссылке в нужном браузере (где залогинен email пользователь)<div></div><a target="_blank" className={'ib'} style={{wordBreak: 'break-all'}} href={link}>{link}</a></div>
      <div>2. Скопируйте ссылку (страница контактов итрум)</div>
      <div>
        <Input value={redirectLink} onChange={v => setRedirectLink(v)}/>
        <Button onClick={(cb) => {
          let url = `/confirm-email/${item?._id}?` + redirectLink.split('?').at(-1);
          global.http.get(url).then(r => {
            console.log("qqqqq rrrr", r);
          })

          cb && cb()
        }}>Подтвердить Ссылку</Button>
      </div>
      <div>
        <Button onClick={getInfo}>Получить Инфо ЕМ</Button>
      </div>
      <Stringify item={info}></Stringify>
      <div><hr/></div>
    </>}
    <div></div>
  </div>
}

export default Layout2
