import React, {useEffect, useState} from 'react';
import _, {last} from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import RenderDocument from "./RenderDocument";
import RenderPhoto from "./RenderPhoto";
import Smart from "../../libs/Smart";
import PostMessage from "./PostMessage";
import SuggestMessages from "./SuggestMessages";
import Textarea from "../../libs/Textarea";
import Button from "../../libs/Button";
import {combineTags, domains, getHashTags, regifyArr, regifyDesc, SalaryComp, STATUSES, TagsRender} from "../Jobs";
import StackSelector from "../StackSelector";
import GroupButton from "../../libs/GroupButton/GroupButton";
import MyModal from "../../libs/MyModal";
import Apply, {WrongApply} from "./Apply";
import Stringify from "../Stringify";
import EmployerInfo from "../EmployerInfo";

export const pubDate = (date) => {
    const d = new Date(date);

    const zeroPad = (num) => String(num).padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const DD = zeroPad(d.getDate());
    const MM = monthNames[d.getMonth()];

    const YYYY = d.getFullYear();
    const HH = zeroPad(d.getHours());
    const min = zeroPad(d.getMinutes());

    return `${DD} ${MM} at ${HH}:${min}`;
}

function Layout2(props) {
    let {tuser, username, jobId, job0, filter} = props;
    // let [isApplyOpen, setIsApplyOpen] = useState(filter?.isApplyOpen)
    let [details, setDetails] = useState({})
    let [cd, setCd] = useState(0)
    let [sheduleRerender, setRerender] = useState(0);
    let [value, setValue] = useState('')
    let [_username, setUsername] = useState('')
    username = _username || username

    let dialog = props.dialog || details?.dialog?._id;
    useEffect(() => {
        loadDetails();
    }, [dialog, username, _username])

    useEffect(() => {
        console.log("qqqqq job id is cahnged", jobId);
        setDetails({})
        setRerender(new Date().getTime())
        setUsername('')
    }, [jobId])

    let loadDetails = (scb) => {

        console.log("qqqqq load details", tuser, dialog, username, _username);
        setRerender(new Date().getTime())

        if (username && !tuser && jobId) {
            return global.http.get('/dialog-details-by-username', {
                messenger: props.messenger,
                username, tuser: TUser, dialog
            }).then(info => {
                console.log("qqqqq infoooo", info);
                setDetails(info)
                scb && scb()
            })
        }
        if (username && tuser) {
            return global.http.get('/dialog-details-by-username', {username, tuser, dialog}).then(info => {
                console.log("qqqqq infoooo", info);
                setDetails(info)

                scb && scb()
            })
        }
        if (!dialog) {
            console.log("qqqqq SELECT USER AND DIALOG",);
            scb && scb()
            return;
            return global.notify.error('Select User And Dialog')
        }
        global.http.get('/dialog-details', {dialog}).then(info => {
            console.log("qqqqq infoooo", info);
            setDetails(info)
            scb && scb()
        })
    }

    function changeUser(it) {

        console.log("qqqqq changeUser", it, details);
        return global.http.get('/dialog-details-by-username', {
            username: DIALOG.username2,
            vacancyId: DIALOG.vacancyId,
            chatId: DIALOG.chatId,
            messenger: DIALOG.messenger,
            tuser: it._id,
            dialog: it?.dialog?._id
        }).then(info => {
            if (info.error) {
                return window.notify.error(info.error)
            }
            console.log("qqqqq infoooo", info);
            // window.location.hash = 'dialog=' + info?.dialog?._id
            setDetails(info)
        })

    }


    //

    let DIALOG = details?.dialog || {};

    function getFirstUser() {

        return ((window.users || [])[0] || {})._id
    }

    let status = job0?.status;

    let TUser = DIALOG.TUser || getFirstUser();
    let msg0 = last(details.tgMessages || [])
    let isHH = (DIALOG.messenger === 'hh') || (job0 || {}).type === 'hh';

    let job0data = job0?.data || {};
    let salary = job0data?.salary;
    let employer = job0data?.employer;


    job0 = job0 || {}
    let it = job0;
    let data = job0?.data || {};

    let {arrs} = props;

    let ks = (data.key_skills || []).map(it => `<small class="tag-item">#${it.name}</small>`).join('');
    // // let hTags = getHashTags([data.name || '', ks, it.desc].join(' '));
    // // let domains = {tg: 'https://t.me', hh: ''}
    //
    //
    let goodReg = regifyArr(arrs.good)
    let midReg = regifyArr(arrs.mid)
    let badReg = regifyArr(arrs.bad)
    let searchReg = regifyArr(arrs.search)
    let hTags = getHashTags([data.name || '', ks].join(' '));

    let {obj, text} = linkifyObj(it.fullDesc)
    // let desc = it.desc;
    let vv1 = regifyDesc({desc: text, goodReg, midReg, badReg, searchReg})
    let vv2 = regifyDesc({desc: it.title, goodReg, midReg, badReg, searchReg})
    let vv3 = regifyDesc({desc: ks, goodReg, midReg, badReg, searchReg})
    let vv4 = regifyDesc({desc: hTags.join('&nbsp;&nbsp;'), goodReg, midReg, badReg, searchReg})
    let tags = combineTags([vv1, vv2, vv3, vv4])

    console.log('vvv111111111111111111', vv1, obj, text)
    // useEffect(() => {
    //     props.onChangeFilter({isApplyOpen})
    // }, [isApplyOpen])

    // let v = useActionData();
    vv1.desc = vv1.desc.replace(/\@\@(.+?)\@\@/gi, (...args) => {
        let link = obj[args[1]];
        return `<a href="${link}" target="_blank">${link}</a>`
    })

    function onChange(opts, params) {
        job0 = {...job0 || {}, ...opts || {}}
        props.onChange && props.onChange(job0, params)
    }

    // function applyNow(cb) {
    //     cb && cb()
    //     setIsApplyOpen(true)
    //
    // }
    //
    // function applyLater(time, cb) {
    //     onChange({status: 'needApply', applyReservedUntil: new Date().getTime() + time})
    //     cb && cb()
    // }

    let customData = job0?.customData || {}
    // let data = job0?.data || {}

    function linkify(text) {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const wwwPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        const emailPattern = /(([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+))/gim;

        return (text || '')
            .replace(urlPattern, '<a target="_blank" href="$1" target="_blank">$1</a>')
            .replace(wwwPattern, '$1<a target="_blank" href="http://$2" target="_blank">$2</a>')
            .replace(emailPattern, '<a target="_blank" href="mailto:$1">$1</a>');
    }


    function linkifyObj(text) {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const wwwPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        const emailPattern = /(([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+))/gim;

        let obj = {};
        let ind = 0;

        let replacerLink = (...args) => {
            let _ind = ++ind;
            obj[_ind] = args[0];
            return `@@${_ind}@@`
        }
        let linksObj = {}
        let linkInd = 0;
        return {
            text: (text || '')
                .replace(/<a(.*?)<\/a>/gi, (...args) => {
                    let _link_ind = ++linkInd;
                    linksObj[_link_ind] = args[0]
                    return `@@@${_link_ind}@@@`
                })
                .replace(urlPattern, replacerLink)
                .replace(wwwPattern, replacerLink)
                .replace(emailPattern, '<a target="_blank" href="mailto:$1">$1</a>')
                .replace(/\@\@\@(.+?)\@\@\@/gi, (...args) => {
                    return linksObj[1]
                })
            , obj
        }

    }


    return <div className={'row'}>
        <div className="col-xs-12">
            <WrongApply job={it}></WrongApply>
            {/*<Stringify item={it.reservedData} title={'Reserved'}></Stringify>*/}
            {/*<Stringify item={it.stats} title={'Stats'}></Stringify>*/}
        </div>
        <div className={'col-xs-12'}>
            <div className="pull-right">
                <Button color={4} size={'xs'} onClick={(scb) => {
                    scb && scb();
                    props.onEditJob(job0)

                }}>Edit Job</Button>
            </div>
            {/*<ClientNote job={job0}></ClientNote>*/}
            {(STATUSES).map((_status, ind) => {
                let clName = (_status === 'bad_stack' || _status === 'bad' || _status === 'duplicated') ? 'btn-danger' : _status === 'good' ? 'btn-success' : 'btn-default'
                return (<button onClick={() => {
                    onChange({status: _status}, {toClose: true})
                }}
                                className={'btn btn-xs btn-default ' + (status == _status ? clName : '') + ' ' + (status == _status ? ' active' : '')}
                                key={ind}>{_status}</button>)
            })}
            <hr/>
            {/*    {job0?.data?.contacts && <pre>*/}
            {/*    {JSON.stringify(job0?.data?.contacts, null, 4)}*/}
            {/*</pre>}*/}
        </div>
        <div className="col-xs-12">
            <a style={{marginLeft: '10px'}}
               href={(job0?.channel != 'hh' ? domains[job0?.channel] || '' : '') + job0?.postUrl} target="_blank"
               className="pull-right">{job0?.postUrl}</a>
            {job0.activeHhId &&
                <a style={{marginLeft: '10px'}} href={`${(domains['hh'] || '')}/vacancy/${job0?.activeHhId}`}
                   target="_blank" className="pull-right">#{job0?.activeHhId}</a>}

            Язык
            <GroupButton items={['ru', 'en']} value={customData?.lng} onChange={(v) => {
                customData.lng = v;
                onChange({customData})
            }}></GroupButton> Channel: {job0?.channel}
            <div>
                <StackSelector
                    value={customData?.stack || []}
                    onChange={v => {
                        console.log("qqqqq vvvvv stack selector", v);
                        customData.stack = v;
                        onChange({customData})
                    }}
                ></StackSelector>
            </div>
        </div>

        <div className="col-xs-12">
            <div>
                <TagsRender tags={tags}></TagsRender>
            </div>
            {/*{employer && <div>Заказчик: {employer?.name}</div>}*/}
            <EmployerInfo job={it} onChangeJob={onChange}></EmployerInfo>
            {(job0data?.key_skills || []).map((it, ind) => {
                return (<small key={ind} className={'ib tag-item'}>
                    {it.name}
                </small>)
            })}
            <Stringify item={it?.linksObj}></Stringify>
            {(it?.links || []).map((it, ind) => {
                return (<div key={ind}>
                    {it.href} 22
                </div>)
            })}


            <div>
                <strong dangerouslySetInnerHTML={{__html: vv2.desc}}></strong>
                <SalaryComp job={job0}></SalaryComp>
                {/*{salary && <div>Salary: {salary?.from} - {salary?.to}</div>}*/}
            </div>
            {/*<Stringify item={job0data}></Stringify>*/}
            {(job0data?.links || []).map((it, ind) => {
                return (<a href={it.url} key={ind} target={"_blank"} style={{marginRight: '10px'}}>
                    {it.text || it.url || '---'}
                </a>)
            })}


            <div dangerouslySetInnerHTML={{__html: vv1.desc}} className={'descJob'}></div>
            <StringifyDetails item={job0}></StringifyDetails>

        </div>
        <div className="col-sm-12">
            <ListOfUsers
                username={details?.client?.username}
                user={TUser}
                onLoadUsers={() => {
                    setCd(new Date().getTime())
                    console.log("qqqqq load users", window.users);
                }}
                onChange={(it) => {
                    changeUser(it)
                }}
            ></ListOfUsers>
        </div>
    </div>
}

export function StringifyDetails(props) {
    let [isOpen, setIsOpen] = useState(false)
    return <>
        <a onClick={() => {
            setIsOpen(!isOpen)
        }}>Детали</a>
        <div></div>
        {isOpen && <Stringify item={props.item}></Stringify>}
    </>
}

export function PostHHVacancy(props) {
    let [postMsg, setPostMsg] = useState(props._value || '')
    let [user, setUser] = useState({})
    let [resumeId, setResumeId] = useState('')
    let [items, setItems] = useState([])
    let {job0, TUser} = props;
    useEffect(() => {
        setPostMsg(props._value.replace(/\<\%name\%\>/gi, user.firstName || '')
            // replace(/undefined/gi, user.firstName)
        )
    }, [props._value])

    useEffect(() => {
        setResumeId('')

        TUser && global.http.get('/get-user-info', {_id: TUser}).then(r => {
            let _items = calcItems(r.cv)
            setUser(r.user)
            setItems(_items)

            setResumeId(((_items || [])[0] || {}).data?.id)
        })
    }, [TUser, (job0 || {})._id])

    function postVacancy(scb, ecb) {
        console.log("qqqqq post vacancy",);
        global.http.post('/post-hh-vacancy', {
            vacancyId: +((job0 || {}).data || {}).id,
            resumeId: resumeId,
            tuser: TUser,
            msg: postMsg,
        }).then(r => {
            scb && scb()
        }).catch(e => {
            ecb && ecb()
        })
    }

    function postVacancyAndSkills(scb, ecb) {
        console.log("qqqqq post vacancy",);
        global.http.post('/post-hh-vacancy', {
            vacancyId: +((job0 || {}).data || {}).id,
            resumeId: resumeId,
            tuser: TUser,
            msg: postMsg,
            applySkills: true
        }).then(r => {
            scb && scb()
        }).catch(e => {
            ecb && ecb()
        })
    }

    function getMatchCount(skills) {
        let count = 0;

        _.each(job0?.data?.key_skills, (item, ind) => {
            console.log("qqqqq iteeee222", item);
            if (skills.find(it => it.name == item.name)) {
                count++;
            }
        })
        return count;

    }

    let _items = calcItems(items)

    function calcItems(items) {
        _.each(items, (item, ind) => {
            let it = item.data;

            let skills = _.sortBy(_.uniq([...item.mySkills || [], ...(it.skill_set || []).map(it => {
                return {name: it, cd: -1, count: -1}
            })], it => it.name), it => -1000 * new Date().getTime() * it.count - it.cd);
            let match_count = getMatchCount(skills)
            let total_count = (job0?.data?.key_skills.length || 0)
            let new_count = total_count - match_count
            let perc = Math.round(100 * match_count / (total_count || 1))
            let other_skills = job0?.data?.key_skills.filter(it => {
                return !skills.find(it2 => it2.name == it.name)
            })

            item.skills = skills;
            item.match_count = match_count;
            item.total_count = total_count;
            item.perc = perc;
            item.other_skills = other_skills;
            item.new_count = new_count;


        })
        return _.sortBy((items || []), it => -it.perc)
    }

    function adjustSkills(resumeId, skills) {
        global.http.get('/adjust-hh-skills', {resumeId, skills}).then(r => {
            console.log("qqqqq rrrrrr", r);
        })

    }


    console.log("qqqqq total countttttt", job0.data);
    return <div>
        {job0?.data?.contacts && <pre>
            {JSON.stringify(job0?.data?.contacts, null, 4)}
        </pre>}
        {job0?.data?.contacts?.name || ''}
        <Textarea
            maxRows={5}
            value={postMsg}
            onChange={v => setPostMsg(v)}
        ></Textarea>
        <Button onClick={postVacancy}>Post</Button>
        <Button onClick={postVacancyAndSkills}>Post +Skills</Button>
        <Button onClick={postVacancy} disabled={true}>Safe Adjust Post</Button>
        {(_items || []).map((item, ind) => {
            let it = item.data;
            let {other_skills, skills, total_count, match_count, new_count, perc} = item;
            console.log("qqqqq it4444", item);
            return (<div key={ind} onClick={() => setResumeId(it.id)} className={it.id == resumeId ? 'sactive' : ''}>
                <small className={'label label-success ib'}>
                    {item.total_count && <>{perc}%</>}
                    {!item.total_count && <>--- AUTO 100% ----</>}
                </small> {(other_skills || []).map((it, ind) => {
                return (<small key={ind} className={'label label-danger ib'}
                               onClick={() => adjustSkills(item.hhId, [it.name])}>
                    {it.name} {it.count && <>x{it.count}</>}
                </small>)
            })} {skills.length} m{match_count} (x{new_count})
                <div>
                    <a href={it?.alternate_url} target={'_blank'}>
                        {it.title}
                    </a>
                </div>


                <div>
                    {(skills || []).map((it, ind) => {
                        return (<small key={ind} className={'small-it'} onClick={() => {
                            it.isRem = true;
                        }}>
                            {it.name}
                        </small>)
                    })}
                </div>
            </div>)
        })}
        <hr/>
    </div>
}

export function SheduledMessages({dialog, rerender}) {
    let [items, setItems] = useState([])
    console.log("qqqqq sheduled messges!!!", rerender);
    useEffect(() => {
        dialog && global.http.get('/shedule-messages', {dialog}).then(r => {
            console.log("qqqqq sheulde messages", r);
            setItems(r)
        })
    }, [dialog, rerender])
    return items.length ? <div className={'first_message'} style={{padding: '10px', marginTop: '10px'}}>
        {(items || []).map((it, ind) => {
            return (<div key={ind}>
                <small>{pubDate(it.cd)} {pubDate(it.sendDate)}</small>
                <pre>
                    {it.data?.msg || '-'}
                </pre>
            </div>)
        })}
    </div> : <></>
}

export const loadTUsers = (username, cb) => {
    global.http.get('/t-user-full', {username, per_page: 1000}).then(r => {
        console.log("qqqqq rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", r);
        cb(r.users)
    })
}

function ListOfUsers(props) {
    let [users, setUsers] = useState([])
    useEffect(() => {
        // global.http.get('/users')
        loadTUsers(props.username, (users) => {
            setUsers(users)
            window.users = users;
            props.onLoadUsers && props.onLoadUsers()
        })
    }, [])
    return <div>
        {(users || []).map((it, ind) => {
            return (<span
                title={it.name}
                onClick={() => {
                    props.onChange && props.onChange(it)
                }}
                key={ind} className={props.user == it._id ? 'sactive' : ''} style={{padding: '3px 3px'}}>
                #{it._id}
            </span>)
        })}
    </div>
}


function JobDetails({it}) {
    return <>
        <small>{pubDate(it.date)} #{it.id}</small>
        <pre>
                    {it.actionType && <><strong>{it.actionType}</strong>
                        <div>{(it.callDuration / 60).toFixed(1)} мин</div>
                    </>}
            {it.document && <RenderDocument item={it.document}></RenderDocument>}
            {it.photo && <RenderPhoto item={it.document}></RenderPhoto>}

            {it.msg}
                </pre>
    </>
}


export default Layout2
