import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function RenderDocument(props) {
  let {item} = props;
  console.log('*........ ## ROOT ', item);
  let attr0 = (item?.attributes || {})[0]

  return <div>
    [<small>Render Document</small>]
    <div>
    {attr0?.fileName}
    </div>
  </div>
}

export default RenderDocument
