import React, {useState} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart';
import {Link, Outlet} from "react-router-dom";


function PortfolioDetailed(props) {
    let {item, onGlobalChange, autoSaveFn} = (props || {}).props || props || {};
    item.info = item.info || {};
    let info = item.info;

    console.log("qqqqq porps", item, autoSaveFn);
    return <div>
        <Smart
            obj={info}
            onChange={(r) => {
                item.info = r;
                onGlobalChange(item)
            }}
            autoSaveFn={autoSaveFn}
            items={[
                {name: 'Title', key: 'title'},
                {label: 'MetaDesc', key: 'metaDesc', type: 'textarea'},
                {key: 'imgMain', type: 'imgUploader', name: 'ImgGrey', size: 6},

                {label: 'SmallDesc', key: 'smallDesc', type: 'textarea', size: 12},

                {size: 12, path: 'portfolio/BlogItems'},


                {label: 'H1', key: 'h1'},
                {label: 'Months', key: 'months'},
                {label: 'TeamSize', key: 'teamSize'},
                {label: 'AboutClient', key: 'aboutClient', type: 'textarea', size: 12},
                {label: 'ProjectIdea', key: 'projectIdea', type: 'textarea', size: 12},
                {label: 'ProjectTasks', key: 'projectTasks', type: 'textarea', size: 12},
                {label: 'ProjectResults', key: 'projectResults', type: 'textarea', size: 12},
                {type: 'text', value: 'Этапы разработки (рекомендуется 3)', defClass: 'h2', size: 12},
                {
                    key: 'stages',
                    addName: 'Добавить Этап',
                    notFoundText: 'Добавьте стейдж',
                    size: 12, each: [{
                        name: 'Name',
                        key: 'name',
                        type: 'input',
                        size: 4,
                    }, {
                        label: 'Details',
                        size: 8,
                        key: 'details',
                        type: 'textarea'
                    }]
                },
                {type: 'text', value: 'Изображения', defClass: 'h2', size: 12},

                {key: 'imgSliderGrey', type: 'imgUploader', name: 'ImgGrey', size: 6, },
                {key: 'imgSliderColor', type: 'fileUploader', name: 'ImgColor', size: 6},
                {type: 'text', value: 'Фичи', defClass: 'h2', size: 12},
                {
                    key: 'features',
                    addName: 'Добавить Фичу',
                    notFoundText: 'Добавьте Фичу',
                    size: 12, each: [{
                        name: 'Name',
                        key: 'name',
                        type: 'input',
                        size: 4,
                    }, {
                        label: 'Details',
                        size: 8,
                        key: 'details',
                        type: 'textarea'
                    }]
                },
                {
                    key: 'stack',
                    addName: 'Добавить Стек',
                    notFoundText: 'Добавьте стек',
                    size: 12, each: [{
                        name: 'Name',
                        key: 'name',
                        type: 'input',
                        size: 4,
                    }, {
                        label: 'Details',
                        size: 8,
                        key: 'details',
                        type: 'textarea'
                    }]
                },
                {
                    key: 'team',
                    addName: 'Добавить Разработчика',
                    notFoundText: 'Добавьте Разработчика',
                    size: 12, each: [{
                        name: 'Name',
                        key: 'name',
                        type: 'input',
                        size: 3,
                    }, {
                        label: 'Role',
                        size: 3,
                        key: 'role',
                    }, {
                        label: 'Info',
                        size: 6,
                        key: 'info',
                        type: 'textarea'
                    }]
                },
            ]}

        ></Smart>

        <outlet></outlet>
    </div>
}

export default PortfolioDetailed
