import React, {useState} from 'react';
import _ from 'underscore';
import Smart from '../../libs/Smart';
import Table from '../../libs/Table';
import Input from '../../libs/Input';

import {
    Link, Outlet
} from "react-router-dom";

function getter(opts, cb) {
    let url = window.location.pathname.split('/')
    let path = url[url.length - 2];
    let _id = url[url.length - 1]
    let __url = '/' + (opts.url || path) + '/' + _id;
    global.http.get(__url)
        .then((r) =>{
            cb && cb(r)
        })
        .catch(e => {
            console.log("qqqqqeeeeee",);
        })
}


function ProjectDetails(props) {
    const [obj, setObj] = useState(null);
    let url = '/project'

    const [count, setCount] = useState(0);
    if (!obj) {
        console.log("qqqqqobjjjjjjjjjj LAOD", );
        setObj({reactLoading: true})
        getter({url}, (v) => {
            console.log("qqqqqobjjjjjjjjjj LOAD", v);
            setObj(v)
        });
    }

    const pub_link = () => {
        let arr = window.location.pathname.split('/');
        arr.pop();
        return arr.join('/')
    }

    console.log("qqqqqobjjjjjjjjjj RENDER", obj);

    return <div>
        <Link to={pub_link()}>Back</Link>

        <Smart
            defSize={2}
            defClass={'row-np'}
            items={[
                {
                    key: 'name', type: 'input'
                },{
                    key: 'name1', type: 'input'
                },
                {HR: true, size: 12},
                {
                    btns: [{
                        name: 'Save', onClick: (e) => {
                            console.log('*........ ## bbb', e);
                            global.http.put(url, {item: e})
                                .then(r => {
                                    console.log("qqqqq saved");

                                })
                                .catch(e => {
                                    console.log("qqqqqasdfasdf", e);

                                })
                        }
                    }
                        // , {
                        //   name: 'Cancel', onClick: (a) => {
                        //     console.log('*........ ## aaa', a);
                        //   }
                        // }
                    ]
                }
            ]}
            obj={obj}
            onChange={(obj, field, value) => {
                console.log('*........ ## on Change@@!!!!~~~', obj, field, value);
                setObj(obj)
                setCount(+count + 1)
            }}></Smart>

        {/*<SmartTable></SmartTable>*/}
    </div>
}

export default ProjectDetails
