import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function Layout2({props}) {
    // let [value, setValue] = useState([]); // Adjusted for object-based values
    let [itemsObj, setItemsObj] = useState({});


    let items = ['hh', 'tg', 'email'];
    let key = props?.field?.key || 'platforms';
    let value = (props?.item || {})[key];
    let _value = (value || [])//.map(it => itemsObj[it]).filter(it => it)
    console.log("qqqqq prrrrrrrrrrrrrrrrrr", value, _value, items);

    return <div style={{marginTop: '15px'}}>
        <Autocomplete
            multiple
            value={_value}
            onChange={(event, newValue) => {
                // setValue(newValue);
                console.log("qqqqq new Value", newValue);
                props.onChange(newValue.map(it => it), key)
            }}
            options={items}
            // isOptionEqualToValue={(option, value) => option._id === value._id}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option} // Directly use the name property from option
            renderInput={(params) => <TextField {...params} label="Выберите платформу" variant="outlined"/>}
        />
    </div>;
}

export default Layout2;
