import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function Layout2(pp) {
    let {props} = pp;
    // let [value, setValue] = useState([]); // Adjusted for object-based values
    let [items, setItems] = useState(null);
    let [itemsObj, setItemsObj] = useState({});

    useEffect(() => {
        // global.http.get('/stack', {per_page: 10000, filter: {isGeneral: {$ne: true}}}).then(({items}) => {
        global.http.get('/stack', {per_page: 10000}).then(({items}) => {
            setItems(items || []);
            setItemsObj(items.reduce((acc, it) => ({...acc, [it._id]: it}), {}));
            // Initialize the value state with the item objects instead of just their _id
            // setValue(items.filter(it => value.includes(it._id)));
        });
    }, []);

    if (!items) {
        return <div>Loading ...</div>
    }
    let key = props?.field?.key || 'stack';
    let value = pp?.value || (props?.item || {})[key];
    let _value = (value || []).map(it => itemsObj[it]).filter(it => it)

    let onChange = props?.onChange || pp.onChange;

    return <div style={{marginTop: '10px', zIndex: 100000000000000}} >
        <Autocomplete
            multiple
            value={_value}
            onChange={(event, newValue) => {
                // setValue(newValue);
                console.log("qqqqq new Value", newValue);
                onChange(newValue.map(it => it._id), key)
            }}
            options={items}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.name} // Directly use the name property from option
            renderInput={(params) => <TextField {...params} label="Выберите стек" variant="outlined"/>}
        />
    </div>;
}

export default Layout2;