import React, {useState, useRef, useEffect} from 'react';

import MyModal from "../../libs/MyModal";
import Stringify from "../Stringify";
import Textarea from "../../libs/Textarea/Textarea";
import Select from "../../libs/Select";
import Msgs, {getMsg} from "./Msgs";
import {Problematics} from "./UserJobDetails";


function SelUserMsgs(props) {
    let [info, setInfo] = useState({})
    let [isTemplate, setIsTemplate] = useState(false)
    let {
        ind,
        it,
        channel,
        selId,
        setHistory,
        job0,
        credObj,
        history,
        itemsHistoryObj,
        setHistoryByUsers,
        selectedSkills,
        links
    } = props;
    let selTUser = it || {};
    let userId = selTUser._id;
    let historyByUser = (history?.byUsers || {})[userId] || {}
    let setHistoryByUser = (hist, _userId) => setHistoryByUsers(hist, _userId || userId)

    console.log("qqqqq historyByUser2222", historyByUser);
    // useEffect(() =>{
    //    // console.log("qqqqq SEL USERSSSSSSSSSSSSSSSSSSSSSSSSSSSS USRS", props);
    //     return () => {
    //         itemsHistoryObj = {}
    //     }
    // }, [])

    useEffect(() => {
        // console.log("qqqqq SEL USERSSSSSSSSSSSSSSSSSSSSSSSSSSSS CHANNEL REFRESH", props);
        // itemsHistoryObj = itemsHistoryObj || {}
        itemsHistoryObj[channel] ??= {}
    }, [channel])


    function onChangeInfo(v) {
        setInfo({...info, ...v})
    }

    let historyByUserChannel = historyByUser[channel] || {}
    let setHistoryByUserChannel = (v) => {
        console.log("qqqqq history by user channel", v, channel);
        historyByUser[channel] = {...historyByUser[channel] || {}, ...v || {}}
        setHistoryByUser(historyByUser)
    }

    let userCreds = credObj[channel];

    let PARAMS = {
        from: selId,
        user: selTUser,
        parentInfo: job0,
        parentType: 'apply',
        rerenderMsgs: props.rerenderMsgs,
        channel,
        info: job0,
        selectedSkills,
        historyByUserChannel,
        open_vacancies: job0?.customData?.hhInfo?.item?.open_vacancies,
        itemsHistory: (itemsHistoryObj[channel] || {})[selId],
        setHistoryByUserChannel,
        history: (history[selId] || {})[channel]?.items,
        userCreds,
        onChangeJob: props.onChangeJob,
        onChangeInput: (v) => {
            links.current[selId] ??= {}
            links.current[selId][channel] = v;
            // console.log("qqqqq onChange Input", v);
        },
        onChangeHistory: (r) => {
            console.log("qqqqq on change history222222222222", r);
            itemsHistoryObj[channel][selId] = r;

            setHistory((history) => {
                history[selId] ??= {}
                history[selId][channel] = r;
                return {...history}
            })
        }
    }
    console.log("qqqqq historyByUserChannel4444", PARAMS, job0);

    let setTempl = (v) => {
        console.log("qqqqq set templ", v);
        setIsTemplate(false)
        setHistoryByUserChannel({
            msg: getMsg(v.text_ru, {
                channel, job0, historyByUserChannel
            })
        })
    }
    console.log("qqqqq selTUser", selTUser);

    let isFinalActive = selTUser?.sortJobObj?.finalStatus == 'active'
    // if (ind != -1 && !isFinalActive) {
    //     return <>Юзер не подходит {selTUser?.name} #{selTUser?._id}
    //         <Problematics  badKeys={selTUser?.sortJobObj?.BAD_KEYS || {}} hideEmpty={true}></Problematics>
    //         <hr/>
    //     </>
    // }

    let fromNum, to;
    if (channel == 'tg') {
        fromNum = userCreds.id
        to = job0?.activeTg
    } else if (channel == 'hh') {
        fromNum = userCreds.id
        to = job0?.activeHhId
    } else if (channel === 'email') {
        fromNum = userCreds.username
        to = job0?.activeEmail
    } else if (channel === 'custom') {
        fromNum = selId
        to = job0?.activeCustomLink
    }
    let jobLng = job0?.customData?.lng || 'ru';
    return <div key={'byUserWrap_' + it._id} className={'userwrapChat ' + (props?.isPending ? 'pending' : '')}>
        <div className="row">
            <div className="col-xs-12 forPending">
                <div className="tc" style={{padding: '20px', fontWeight: 'bold'}}>
                    Подгружаем информацию
                </div>
            </div>
            {/*{ind != -1 && <div className="col-xs-12">*/}
            {/*    <hr/>*/}
            {/*</div>}*/}
            <MyModal
                isOpen={isTemplate}
                onClose={setIsTemplate}
            >
                {((it?.applies || {})[channel] || []).map((it, ind) => {
                    return (<div key={ind} onClick={() => {
                        setTempl(it)
                    }}>
                        <a>
                            {it['text_' + jobLng]}
                        </a>
                    </div>)
                })}
                {/*<Stringify item={it}></Stringify>*/}
            </MyModal>
            <div className="col-xs-3">

                <small className="ellipse">
                    {it.name}
                    {!it.name && <div className={'ib'}>
                        #{it._id}
                    </div>}
                </small>
                <Select type={"checkbox"}
                        items={['', 'active', 'auto', 'unactive']}
                        woTitle={true}
                        value={historyByUserChannel.status} onChange={v => {
                    setHistoryByUserChannel({status: v})
                }}/>

            </div>
            <div className="col-xs-3">
                <Textarea
                    minRows={1}
                    value={historyByUserChannel.name} placeholder={'Имя Клиента'} onChange={name => {
                    setHistoryByUserChannel({name})
                }}></Textarea>


                {/*<Stringify it></Stringify>*/}
                {/*<small onClick={() => {*/}
                {/* setHistoryByUserChannel({isActive: !historyByUserChannel.isActive})*/}
                {/*}}>IsActive?</small>*/}
            </div>
            <small className="col-xs-6">
                <div onClick={() => setIsTemplate(true)} className={'ib ml-5'}>
                    <a>Выбрать шаблон ({jobLng})</a>
                </div>
                <a href={`/tusers/${selId}`} target={"_blank"} className="ib pull-right">Edit User #{selId}</a>
                <Problematics badKeys={PARAMS?.user?.sortJobObj?.BAD_KEYS || {}} hideEmpty={true}></Problematics>
                <div>
                    {fromNum || 'no FromNum'} {to || 'no To'}</div>
                {channel === 'hh' && <>Открытых вакансий: {PARAMS?.open_vacancies}</>}

            </small>


            <div className="col-xs-12">
                {/*{job0?.activeHhId}*/}
                {true && <>
                    {channel === 'tg' && <div>

                        <Msgs
                            isFinalActive={isFinalActive}
                            // fromNum={}
                            fromNum={fromNum}
                            to={to}
                            {...PARAMS}
                        ></Msgs>
                    </div>}
                    {channel === 'hh' && <div>
                        <Msgs
                            isFinalActive={isFinalActive}

                            fromNum={fromNum}
                            to={to}
                            {...PARAMS}
                        ></Msgs>
                    </div>}
                    {channel === 'email' && <div>
                        <Msgs
                            isFinalActive={isFinalActive}
                            fromNum={fromNum}
                            to={to}
                            {...PARAMS}
                        ></Msgs>
                    </div>}
                    {channel === 'custom' && <div>
                        <Msgs
                            isFinalActive={isFinalActive}
                            fromNum={fromNum}
                            to={to}
                            {...PARAMS}
                        ></Msgs>
                    </div>}
                </>}
            </div>
        </div>
    </div>
}


export default SelUserMsgs;