import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";
import ChatDetails from "./ChatDetails";


function ChatInsideJobs(props) {
  let [allChats, setAllChats] = useState([]);
  let [activeDialog, setActiveDialog] = useState({})
  useEffect(() => {
    // global.http.get('/get-all-chats', {username: props.username}).then(r =>{
    // });

  }, [props.username])
  console.log('*........ ## ROOT RENDER', props);

  return <div className={'row'}>
    <div className="col-sm-12">
      {props.username} {props.jobId}
      <ChatDetails username={props.username} jobId={props.jobId}></ChatDetails>
    </div>
  </div>
}

export default ChatInsideJobs
