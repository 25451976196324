export function TemplateProfile(key, name) {
    return {
        size: 4, key, sortable: true,
        addName: `Добаить ${name} апплай`,
        each: [
            {
                size: 6,
                childs: [

                    {
                        isVisible: (it) => name == 'Email',
                        key: 'title_ru',
                        field: 'title_ru',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `${name} Тайтл`,
                        // woLabel: true
                    },  {
                        key: 'text_ru',
                        field: 'text_ru',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `${name} апплай`,
                        // woLabel: true
                    },
                    {
                        isVisible: (it) => name != 'HH',
                        key: 'file_ru',
                        field: 'file_ru',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `file_path_ru`,
                        woLabel: true
                    }, {
                        isVisible: (it) => name != 'HH',
                        key: 'file_name_ru',
                        field: 'file_name_ru',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `file_name_ru`,
                        woLabel: true
                    },
                ]
            }
            , {
                size: 6,
                childs: [
                    {
                        isVisible: (it) => name == 'Email',

                        key: 'title_en',
                        field: 'title_en',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `${name} Тайтл`,
                        // woLabel: true
                    },
                    {
                        key: 'text_en',
                        field: 'text_en',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `${name} апплай (EN)`,
                        // woLabel: true
                    },
                    {
                        isVisible: (it) => name != 'HH',
                        key: 'file_en',
                        field: 'file_en',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `file_path_en`,
                        woLabel: true
                    },
                    {
                        isVisible: (it) => name != 'HH',
                        key: 'file_name_en',
                        field: 'file_name_en',
                        size: 12,
                        minRows: 1,
                        maxRows: 7,
                        type: 'textarea',
                        placeholder: `file_name_en`,
                        woLabel: true
                    },
                ]
            }
        ]
    }
}