import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import Smart from 'libs/Smart';
import {Link, Outlet} from "react-router-dom";
import Fetcher from "../methods/Fetcher";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Input from 'libs/Input';
import Textarea from 'libs/Textarea';
import './blogitem.css'

function sync_rend_components(cb) {

    // alert('fetch')
    // Fetcher
    global.http
        .get('/all-rend-comps', {})
        .then(comps => {
            global.rendCompsObj = {};
            global.rendComps = comps;
            _.each(comps, (item, ind) => {
                let _id = item._id;
                global.rendCompsObj[_id] = item;
            })
            cb && cb()

            // global.compObj = obj;
            // global.parentObj = parentObj;
        })

}


function BlogItems(props) {
    let {item, onGlobalChange, autoSaveFn} = props.props || props;
    let [count, setCount] = useState(0);

    useEffect(() => {
        sync_rend_components(() => {
            setCount(count + 1)
        })
    }, [])

    let rendCompsObj = global.rendCompsObj || {}
    let rendComps = global.rendComps || []


    function autoSave () {
        autoSaveFn && autoSaveFn()
    }




    return <div>
        <Smart
            triggerSave={true}
            obj={item}
            onChange={(v) => {
                item = v;
                autoSave()
            }
            }
            items={[
                {name: 'Name', key: 'name'},
                {name: 'Url', key: 'url'},
                {name: 'H1', key: 'h1'},
                {name: 'MetaDesc', key: 'metaDesc', type: 'textarea'}
            ]}>

        </Smart>
        <Smart
            autoSave={true}
            obj={item}
            onChange={(r, a, b) => {
                console.log("qqqqq on change r, a, b", r, a, b);
                onGlobalChange(r)
            }
            }
            items={[
                {
                    addName: 'Добавить Блок',
                    size: 12, key: 'items', name: 'Name', each: [
                        {
                            size: 12, Component: (props) => {
                                let [count, setCount] = useState(0)
                                props = props.props || props;
                                let {localItem, field, item, onChange, ind, onGlobalChange} = props;
                                localItem = localItem || {};
                                let component = (rendCompsObj || {})[localItem.component] || {};
                                return <div className={'row'}>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            disablePortal
                                            blurOnSelect={true}
                                            value={component}
                                            // id="combo-box-demo"
                                            options={rendComps}
                                            sx={{width: 300}}
                                            onChange={(e, point) => {
                                                console.log("qqqqq point", point);
                                                item.items[ind].component = point._id;
                                                setCount(count + 1)
                                                autoSave()

                                                // onChangeItem(ind, 'component', point._id)
                                            }}
                                            getOptionLabel={(option) => (option.name || '')}
                                            renderInput={(params) => {
                                                params.value = params.value || '';
                                                return (<TextField
                                                    {...params}
                                                    inputRef={input => {
                                                    }}
                                                    label="Select component"
                                                    disableAnimation={true}
                                                    // InputLabelProps={{
                                                    //     shrink: false,
                                                    // }}
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />)
                                            }}
                                        />
                                        <a href={"/rend-comps/" + component._id}>
                                            <img src="/icons/edit.svg" alt="" className={'edit-blog-icons'}/>
                                        </a>
                                    </div>
                                    <div className="col-sm-9">
                                        <Smart
                                            defSize={12}
                                            obj={localItem.obj || {}}
                                            items={component.params}
                                            onChange={(v, field, value) => {

                                                item.items[ind].obj = v;
                                                setCount(count + 1)

                                                autoSave()

                                                // localItem.obj = v;
                                                // onChange('obj', localItem.obj)
                                                // onChangeItem(ind, 'obj', v)
                                            }}
                                        ></Smart>
                                        {/*<div className="col-sm-3">*/}
                                        {/*    <Input label={'Name'} value={localItem.name} onChange={(v) => {*/}
                                        {/*        onChange(v, 'name')*/}
                                        {/*    }*/}
                                        {/*    }></Input>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-sm-3">*/}
                                        {/*    <Textarea label={'Text'} value={localItem.text} onChange={(v) => {*/}
                                        {/*        onChange(v, 'text')*/}
                                        {/*    }*/}
                                        {/*    }></Textarea>*/}
                                        {/*</div>*/}
                                    </div>


                                </div>
                            }
                        },
                    ]
                }
            ]}
        >

        </Smart>
    </div>
}

export default BlogItems
