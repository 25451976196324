import React, {useState} from 'react';
import _ from 'underscore';
import Button from 'libs/Button';

import {
    Link, Outlet
} from "react-router-dom";


function Layout2(props) {
    let {item} = props.props || props;
    let list = (global.parentObj || {})[item._id] || [];
    let list2 = ((global.compObj || {})[item._id] || {}).childs || [];
    console.log('*........ ## PARENT ROO ROOT RENDER', item, list, list2);

    // let v = useActionData();
    return <div>
        {/*<Button onClick={(scb) => {*/}
        {/*    scb && scb();*/}

        {/*}}>Save</Button>*/}
        <hr/>
        <small>
            <div>
                <span>Parent: </span>
                {(list || []).map((_id, ind) => {
                    let point = global.compObj[_id]
                    return (<Link to={'/components/' + point._id} key={ind} className={'ib mr-10'}>
                        {point.name}
                    </Link>)
                })}
            </div>
            <div>
                <span>Childs: </span>
                {(list2 || []).map((_id, ind) => {
                    let point = global.compObj[_id]
                    return (<Link to={'/components/' + point._id} key={ind} className={'ib mr-10'}>
                        {point.name}
                    </Link>)
                })}
            </div>


        </small>
    </div>
}

export default Layout2
