import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";


function Layout2(props) {
  let [stats, setStats] = useState({});

  useEffect(() =>{
    global.http.get('/get-stats').then(r => {
      setStats(r)
    })
  }, [])
  console.log('*........ ## ROOT RENDER', props);

  return <div>
    Stats page
  </div>
}

export default Layout2
