import React, {useState} from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";
import GroupButton from "../../libs/GroupButton/GroupButton";
import Msgs from "./Msgs";
import Textarea from "../../libs/Textarea/Textarea";
import Datepicker from "../../libs/Datepicker/Datepicker";
import {OfferInterviewsWrap, toODB, UnreadDialogs} from "./Chat";
import NewDialogModal from "./NewDialogModal";


function Layout2(props) {
  let {activeDialog,
    filter,
    negotiationId,
      specialInfo,

    updateActiveDialog, link, from, to, applyDetails, activeJob, onChangeSpecialInfo, dialogs, setFilter, PARAMS} = props;
  console.log('*........ ## ROOT RENDER', props);

  return <>
    <hr/>

    <div className="col-xs-5">

      <div>
        <span>From: {from}</span>
        <span className={'ml5'}>To: {to}</span>
        <span className={'ml5'}>Hash: <a
            href={'/dialog-hash/' + activeDialog?.hash}>{(activeDialog?.hash || '').substring(0, 10) + '...'}</a></span>
        <a className={'ml5'} href={link} target={"_blank"}>{link}</a>
        <div>Dialog statuses
          <GroupButton items={['active', 'floodGroup', 'itGroup', 'bUser', 'bUserChat']}
                       value={activeDialog?.customStatus}
                       onChange={(customStatus) => {
                         updateActiveDialog({customStatus})

                       }}
          ></GroupButton>
        </div>
      </div>
      {activeDialog && <>
        {activeDialog.channel == 'hh' && <Msgs
            isFinalActive={true}
            isWoApply={true}
            negotiationId={negotiationId}
            // fromNum={}
            fromNum={from}
            to={to}
            {...PARAMS}
        ></Msgs>}

        {activeDialog.channel !== 'hh' && <Msgs
            isFinalActive={true}
            isWoApply={true}
            // fromNum={}
            fromNum={from}
            to={to}
            {...PARAMS}
        ></Msgs>}
      </>}
    </div>

    <div className="col-xs-3">
      <div style={{maxHeight: '200px', overflow: 'auto'}}>
        <div>
        {applyDetails?.msg}
        </div>
        <div>
          {toODB(applyDetails?.msgDate)}
        </div>
        _id: <a
          href={`/posts#{%22sort%22:%22-postDate%22,%22jobId%22:${activeJob?._id},%22isApplyOpen%22:true,%22isMenuOpen%22:true}`}>#{activeJob?._id}</a>
        <div></div>
        Stack: {activeJob?.customData?.stack}
        <div></div>
        LNG: {activeJob?.customData?.lng}
        <hr/>
        <div dangerouslySetInnerHTML={{__html: activeJob?.desc}}></div>
      </div>
      <OfferInterviewsWrap dialog={activeDialog} sourceChannel={activeJob?.channel}></OfferInterviewsWrap>
      <hr/>

      <div style={{maxHeight: '200px', overflow: 'auto'}}>
        <div>
          - Salary
          <Textarea value={specialInfo?.salary} onChange={(salary) => {
            onChangeSpecialInfo({salary})
          }}></Textarea>
        </div>
        <div>
          - Location
        </div>
        <div>
          - Экспериенс
        </div>
        <div>
          - HR Собес
        </div>
        <div>
          - Тех Собес
        </div>

        <Datepicker value={activeDialog?.pingDate} onChange={(pingDate) => {
          updateActiveDialog({pingDate})
        }}></Datepicker>
      </div>
      {dialogs && setFilter && <div style={{maxHeight: '200px', overflow: 'auto'}}>
        <UnreadDialogs items={dialogs} activeDialogId={activeDialog?._id} onClick={(v) => {
          // setActiveDialog(v)
          setFilter({...filter, activeDialogId: v._id})
          console.log("qqqqq qqqqq SFF 4",);
        }}></UnreadDialogs>
      </div>}
    </div>
  </>
}

export default Layout2
