import React, { useState, useEffect, memo } from 'react';

const Comp = ({props}) => {
    let { item } = props
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        global.http.get(`/parse/${item.parseId}`).then(r => {
            setLoading(false);
            setInfo(r);
        });
    }, [item.parseId]); // Add item.parseId as a dependency to ensure the effect runs when it changes

    return (
        <>
            ServerRes: {loading ? 'Loading ...' : 'OK'}
            <pre>{JSON.stringify(info, null, 4)}</pre>
        </>
    );
};

// Custom comparison function to check if item._id has changed
const areEqual = (prevProps, nextProps) => {
    return prevProps?.item?._id === nextProps?.item?._id;
};

export default memo(Comp, areEqual);